import React, { useReducer, useContext, useEffect } from 'react';
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { HEADERS } from "../config";
import { ERROR_MESSAGE, ERROR_TITLE } from "../constants.js";

// Material UI
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import { red, blue, green, blueGrey } from "@material-ui/core/colors";

// Reducer
import { homeReducer } from "../reducers/HomeReducer";

// Context
import { UserContext } from "../context/UserContext";
import { AlertContext } from "../context/AlertContext";


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    tripImage: {
        objectFit: "cover",
        height: "100%",
    },
    tripImageContainer: {
        height: "205px",
    },
    tripGallery: {
        width: "100%",
        paddingBottom: "35px",
    },
    link: {
        textDecoration: "none",
        marginRight: theme.spacing(1),
        '&:visited': {
            color: "inherit"
        },
    },
}));


function Home(props) {

    let { updateAlertTitle, updateIsAlertOpen, updateAlertMessage } = useContext(AlertContext)
    const { logOutUser } = useContext(UserContext);

    const classes = useStyles();
    const initialState = {
        firstName: localStorage.getItem("firstName"),
    };
    const [home, dispatchHome] = useReducer(homeReducer, initialState);

    let matches = useMediaQuery("(max-width:600px)");

    const openUrl = (url) => {
        window.open(url, "_blank")
    };

    let displayWelcomeMessage = () => {
        if (home.firstName == "") {
            return (
                <Typography variant="h4" component="h4" align="center">
                    Welcome
                </Typography>
            )
        } else {
            return (
                <Typography variant="h4" component="h4" align="center">
                    Welcome, {home.firstName}
                </Typography>
            )
        }
    };

    let displayPageContent = () => {
        return (
            <div className={classes.root}>
                <br></br><br></br>
                {displayWelcomeMessage()}
                <br></br>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography component="p" align="inherit">
                            Welcome to MyB&R! We want each trip you take with us
                            to be as personalized as possible,
                            so we've created this online portal to make it easy
                            for you to provide us with your most up-to-date information.
                        </Typography>
                    </Grid>
                </Grid>
                <br></br>
                <br></br>
                <br></br>


                {/*View Upcoming Trips button*/}
                <Grid container>
                    <Grid item xs={12}>
                        <Link to="/trips" className={classes.link}>
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth={true}
                                >View Upcoming Trips
                            </Button>
                        </Link>
                    </Grid>
                </Grid>

                <br></br>
            </div>
        )
    }

    const isTokenValid = () => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/v1/validateToken", HEADERS)
        .then((result) => {
            if (result.status == 222 && result.data.sessionTimeout) {
                return logOutUser(props)
            };
        })
        .catch(err => console.log(err));
    };

    useEffect(() => {
        isTokenValid();
    }, []);

    return (
        <>
            {displayPageContent()}
        </>
    )
}

export default Home;
