import React, { createContext } from 'react';
import { EMAIL, PHONE } from "../constants.js";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from '@material-ui/icons/Phone';

export const AlertContext = createContext();

class AlertProvider extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            success: false,
            isOpen: false,
            title: "Error",
            maxWidth: "sm",
            message: "",
            buttons: this.getButtons(),
            exitButtonCallback: null
        };

        this.updateIsAlertOpen = this.updateIsAlertOpen.bind(this);
        this.updateAlertButtons = this.updateAlertButtons.bind(this);
        this.updateAlertTitle = this.updateAlertTitle.bind(this);
        this.updateAlertMessage = this.updateAlertMessage.bind(this);
        this.updateAlertSuccess = this.updateAlertSuccess.bind(this);
        this.updateMaxWidth = this.updateMaxWidth.bind(this);
        this.updateOnExitButtonClick = this.updateOnExitButtonClick.bind(this);
        this.resetState = this.resetState.bind(this);
    };

    getButtons () {
        return [
            {
                id: 0,
                name: "Technical Support",
                color: "primary",
                variant: "contained",
                action: () => {
                    window.open(PHONE)
                    this.resetState()
                },
                icon: PhoneIcon
            },
            {
                id: 1,
                name: "Technical Support",
                color: "primary",
                variant: "contained",
                action: () => {
                    window.open(EMAIL)
                    this.resetState()
                },
                icon: MailOutlineIcon
            }
        ];
    };

    updateMaxWidth (maxWidth) {
        this.setState({maxWidth: maxWidth})
    };

    updateIsAlertOpen (isOpen) {
        this.setState({isOpen: isOpen}, () => {
            !this.state.isOpen && this.resetState()   // shorthand
        })
    };

    updateAlertButtons (buttons) {
        this.setState({buttons: buttons})
    };

    updateAlertTitle (title) {
        this.setState({title: title})
    };

    updateAlertMessage (message) {
        this.setState({message: message})
    };

    updateAlertSuccess (success) {
        this.setState({success: success})
    }

    updateOnExitButtonClick (func) {
        this.setState({exitButtonCallback: func})
    };

    resetState () {

        // list any data that needs to be resetted
        this.setState({isOpen: false})

        // fixing the racing condition with the state setter
        setTimeout(() => {
            this.setState({maxWidth: "sm"});
            this.setState({success: false})
            this.setState({title: "Error"})
            this.setState({message: ""})
            this.setState({buttons: this.getButtons()})
            this.setState({exitButtonCallback: null})
        }, 100) // min number milliseconds to wait
    };

    render () {
        return (
            <AlertContext.Provider
                value={{
                    ...this.state,
                    updateIsAlertOpen: this.updateIsAlertOpen,
                    updateAlertButtons: this.updateAlertButtons,
                    updateAlertTitle: this.updateAlertTitle,
                    updateAlertMessage: this.updateAlertMessage,
                    updateAlertSuccess: this.updateAlertSuccess,
                    updateMaxWidth: this.updateMaxWidth,
                    updateOnExitButtonClick: this.updateOnExitButtonClick,
                    resetState: this.resetState
                }}
            >
                {this.props.children}
            </AlertContext.Provider>
        );
    };
};

export default AlertProvider;
