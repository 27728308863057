import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    iFrame: {
        border: 0,
        height: "100%"
    }
}));


/*
WARNING:
    The function below must exists outside the IframeModal function otherwise
    once the user closes the modal the layer of the transition will still be sitting
    on top of the parent node and therefore not allow you to click on anything.
*/
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function IframeModal(props) {
    const classes = useStyles();
    const {source, heading, handleClick, isOpen, fullScreen} = props;

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={isOpen}
                onClose={() => handleClick(false)}
                TransitionComponent={Transition}
            >
                <AppBar position="fixed">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => handleClick(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {heading}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <br></br><br></br><br></br>
                {
                    source
                    ? <iframe src={source} className={classes.iFrame}></iframe>
                    : props.children
                }
            </Dialog>
        </div>
    );
}


IframeModal.propTypes = {
    fullScreen: PropTypes.any.isRequired,
    heading: PropTypes.any.isRequired,
    handleClick: PropTypes.any.isRequired,
    isOpen: PropTypes.any.isRequired,
};
