import React from 'react';
import { Switch, Route } from "react-router-dom";

//  COMPONENTS
import Popups from "./presentational/Popups.js";
import SuccessForgotPassword from "./presentational/SuccessForgotPassword";
import UserForm from "./containers/FormStepper";
import Home from "./containers/Home";
import Trips from "./containers/Trips";
import Navbar from "./containers/Navbar";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import ForgotPassword from "./containers/ForgotPassword";
import SuccessAccountCreation from "./containers/SuccessAccountCreation";
import ChangeLog from "./containers/Changelog";

// MATERIAL UI
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

//  CONTEXT PROVIDERS
import UserProvider from "./context/UserContext";
import LoadingWheelProvider from "./context/LoadingWheelContext";
import AlertProvider from "./context/AlertContext";

// PRIVATE ROUTE
import { PrivateRoute } from "./PrivateRoute";

// THEME PROVIDER
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import moment from "moment";
const stripe = loadStripe(process.env.REACT_APP_STRIPE_TOKEN);

const THEME = createMuiTheme({
    typography: {
        h1: {
            fontFamily: "Lora, serif",
            fontSize: "45px",
            color: "#494949",
            textTransform: "uppercase"
        },
        h2: {
            fontFamily: "Rock Salt, sans-serif",
            fontSize: "45px",
            color: "#194666"
        },
        h3: {
            fontFamily: "Lora, serif",
            fontSize: "35px",
            color: "#494949",
            textTransform: "uppercase"
        },
        h4: {
            fontFamily: "Lora, serif",
            fontSize: "30px",
            color: "#494949",
            textTransform: "uppercase"
        },
        h5: {
            fontFamily: "Rock Salt, sans-serif",
            fontSize: "20px",
            color: "#494949"
        },
        body1: {
            fontFamily: "Muli, sans-serif",
            color: "#494949",
            fontSize: "16px"
        }
    },
    palette: {
        primary: {
          main: '#3E739B'
        },
        secondary: {
          main: '#073B5C'
        },
    },
});


function App() {
    return (
        <div>
            <ThemeProvider theme={THEME}>
                <UserProvider>
                    <LoadingWheelProvider>
                        <AlertProvider>
                            <Elements stripe={stripe}>
                                <Navbar />
                                <Popups />
                                <br></br>
                                <br></br>

                                <CssBaseline>
                                    <Container maxWidth="md">
                                        <br></br><br></br>
                                        <Switch>
                                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                <PrivateRoute path="/home" component={Home} />
                                                <PrivateRoute path="/trips" component={Trips} />
                                                <PrivateRoute path="/successSignup" component={SuccessAccountCreation} />
                                                <PrivateRoute path="/successForgotPassword" component={SuccessForgotPassword} />
                                                <PrivateRoute path="/userForm" component={UserForm} />
                                                <PrivateRoute path="/changelog" component={ChangeLog} />

                                                <Route exact path="/" component={Login} />
                                                <Route path="/signup" component={Signup} />
                                                <Route path="/resetPassword" component={ForgotPassword} />
                                                <Route path="/security" component={ForgotPassword} />
                                                <Route path="/security/#/answer" component={ForgotPassword} />
                                            </MuiPickersUtilsProvider>
                                        </Switch>
                                    </Container>
                                </CssBaseline>
                            </Elements>
                        </AlertProvider>
                    </LoadingWheelProvider>
                </UserProvider>
            </ThemeProvider>
        </div>
    );
}

export default App;
