import React, { useState } from "react";
import { UserContext }  from "../context/UserContext";

// Higher order component
import { withRouter } from "react-router-dom";

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import CopyrightIcon from '@material-ui/icons/Copyright';

// Icons
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import { Link } from "react-router-dom";

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Tooltip from '@material-ui/core/Tooltip';

// Assets
import "../assets/css/Navbar.css";
import hero_logo from "../assets/bnr_hero_logo_color.png";
import myBnR_mini from "../assets/myBnR_mini.png";

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      maxWidth: "100%"
    },
    title: {
      flexGrow: 1,
    },
    fullList: {
        width: 'auto',
    },
    list: {
        width: "100%",
    },
    link: {
        textDecoration: "none"
    },
    drawer: {
        textDecoration: "none",
        color: "black"
    },
    image: {
        maxWidth: "50%",
        height: "auto",
        margin: "auto",
        marginTop: "10%",
        marginBottom: "10%",
    }
}))


function Navbar (props) {

    const [left, setLeft] = useState(false);
    const classes = useStyles();

    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setLeft(open);
    };

    const sideList = (side) => {

        return (
            <>
                <img id="imageLogo" src={hero_logo} className={classes.image}/>

                <Grid
                    container
                    direction="column"
                    justify="space-between"
                    alignItems="center"
                    style={{height: "100%"}}
                >
                    <div
                        className={classes.list}
                        role="presentation"
                        onClick={toggleDrawer(side, !left)}
                        onKeyDown={toggleDrawer(side, !left)}
                    >
                        <List style={{marginLeft: "20%"}}>
                            <Link to="/home" className={classes.drawer}>
                                <ListItem button key={"Home"}>
                                    <ListItemIcon>
                                        <HomeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Home"} />
                                </ListItem>
                            </Link>
                            <Link to="/trips" className={classes.drawer}>
                                <ListItem button key={"Trips"}>
                                    <ListItemIcon>
                                        <CardTravelIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Upcoming Trips"} />
                                </ListItem>
                            </Link>
                        </List>
                    </div>

                    <Typography variant="caption" style={{marginBottom: 15}}>
                        <CopyrightIcon fontSize="small" style={{paddingTop: 7}}/> {new Date().getFullYear()} All Rights Reserved
                        <br></br>
                    </Typography>
                </Grid>
            </>
        );
    }

    const renderButton = (token, logOutUser) => {

        if (token) {
            return (
                <div>
                    <Tooltip title="Log Out" placement="bottom">
                        <Link
                            to="/"
                            className={classes.link}
                            onClick={() => logOutUser(props)}
                        >
                            <Button style={{color: "white"}}>
                                <ExitToAppIcon style={{marginLeft: "25px"}}/>
                            </Button>
                        </Link>
                    </Tooltip>
                </div>
            )
        } else {
            return (
                <div>
                    <Link
                        to="/"
                        className={classes.link}
                    >
                        <Button style={{color: "white"}}>Login</Button>
                    </Link>
                </div>
            );
        };
    };

    const renderDrawer = () => {
        /*
        NOTE:
            Resizing the width of the drawer to be mobile and desktop friendly.
            This method is being used since we need to pass in a dynamic value
            for PaperProps.
        */

        if (window.screen.width < 600) {
            return (
                <Drawer
                    open={left}
                    onClose={toggleDrawer('left', !left)}
                    PaperProps={{"style": {"width": "70%"}}}
                >
                    {sideList('left')}
                </Drawer>
            );
        } else if (window.screen.width >= 600 && window.screen.width < 960) {
            return (
                <Drawer
                    open={left}
                    onClose={toggleDrawer('left', !left)}
                    PaperProps={{"style": {"width": "35%"}}}
                >
                    {sideList('left')}
                </Drawer>
            );
        } else {
            return (
                <Drawer
                    open={left}
                    onClose={toggleDrawer('left', !left)}
                    PaperProps={{"style": {"width": "30%"}}}
                >
                    {sideList('left')}
                </Drawer>
            );
        };
    };

    const renderMenuButton = (token) => {
        if (token) {
            return (
                <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer('left', !left)}
                >
                    <MenuIcon />
                </IconButton>
            );
        } else {
            return (
                <span>
                    <Typography variant="h6" className={classes.title}>
                        MyB&R
                    </Typography>
                </span>
            );
        };
    };

    return (
        /*
        NOTE:
            Consuming multiple context in the navbar component below
        */

        <div className={classes.root}>
            <UserContext.Consumer>{
                (userContext) => {

                    const { token, logOutUser } = userContext;
                    return (
                        <>
                            <AppBar position="fixed">
                                <Toolbar>
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                >
                                    {renderMenuButton(token)}
                                    <img
                                        id="miniLogo"
                                        src={myBnR_mini}
                                        width="5%"
                                        height="1%"
                                    />
                                    {renderButton(token, logOutUser)}
                                </Grid>
                                </Toolbar>
                            </AppBar>

                            {renderDrawer()}
                        </>
                    )
            }}</UserContext.Consumer>
        </div>
    )
}

export default withRouter(Navbar);
