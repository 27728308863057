export function signUpReducer (state, action) {
    switch (action.type) {
        case "SET_EMAIL":
            return { ...state, email: action.payload}
        case "SET_PASSWORD":
            return { ...state, password: action.payload}
        case "SET_CONFIRMED_PASSWORD":
            return { ...state, confirmedPassword: action.payload}
        case "SET_SECURITY_QUESTION":
            return { ...state, securityQuestion: action.payload}
        case "SET_SECURITY_ANSWER":
            return { ...state, securityAnswer: action.payload}
        case "SET_AGREED_TO_TERMS_AND_CONDITIONS":
            return { ...state, agreedToTerms: action.payload}
        case "SET_AGREED_TO_PRIVACY_POLICY":
                return { ...state, agreedToPrivacyPolicy: action.payload}
        case "SET_EMAIL_ERROR":
            return { ...state, emailError: action.payload}
        case "SET_PASSWORD_ERROR":
            return { ...state, passwordError: action.payload}
        case "SET_CONFIRMED_PASSWORD_ERROR":
            return { ...state, confirmedPasswordError: action.payload}
        case "SET_SECURITY_QUESTION_ERROR":
            return { ...state, securityQuestionError: action.payload}
        case "SET_SECURITY_ANSWER_ERROR":
            return { ...state, securityAnswerError: action.payload}

        case "SET_SHOW_EMAIL_ERROR":
            return { ...state, showEmailError: action.payload}
        case "SET_SHOW_PASSWORD_ERROR":
            return { ...state, showPasswordError: action.payload}
        case "SET_SHOW_CONFIRMED_PASSWORD_ERROR":
            return { ...state, showConfirmedPasswordError: action.payload}
        case "SET_SHOW_SECURITY_QUESTION_ERROR":
            return { ...state, showSecurityQuestionError: action.payload}
        case "SET_SHOW_SECURITY_ANSWER_ERROR":
            return { ...state, showSecurityAnswerError: action.payload}
    }
}
