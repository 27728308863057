import React, { useContext, useEffect, useReducer } from 'react';
import axios from "axios";
import moment from "moment";
import { HEADERS } from "../config";
import { Link } from "react-router-dom";
import { ERROR_MESSAGE, ERROR_TITLE } from "../constants.js";

// Context
import { LoadingWheelContext } from "../context/LoadingWheelContext";
import { AlertContext } from "../context/AlertContext";
import { UserContext } from "../context/UserContext";

// Reducers
import { tripsReducer } from "../reducers/TripsReducer";

// Material UI
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SvgIcon from '@material-ui/core/SvgIcon';
import { green, red, yellow } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    subHeading: {
        fontSize: theme.typography.pxToRem(13),
        fontWeight: theme.typography.fontWeightRegular,
    },
    table: {
        minWidth: "100%",
    },
    label: {
        marginTop: 2
    },
    link: {
        textDecoration: "none",
        marginRight: theme.spacing(1),
        '&:visited': {
            color: "inherit"
        },
    },
}));


function Trips(props) {
    const classes = useStyles();
    const initialState = {
        upcomingTrips: [],
    };
    const [trips, dispatchTrips] = useReducer(tripsReducer, initialState);
    const { updateIsLoading, isLoading } = useContext(LoadingWheelContext);
    const { updateAlertMessage, updateIsAlertOpen, updateAlertTitle } = useContext(AlertContext);
    const { logOutUser } = useContext(UserContext);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getData = () => {

        return new Promise((resolve, reject) => {
            updateIsLoading(true)

            axios.get(
                process.env.REACT_APP_API_ENDPOINT + "/v1/getUpcomingTrips",
                Object.assign({}, HEADERS, {cancelToken: source.token})
            )
            .then((result) => {
                updateIsLoading(false);
                if (result.status == 222 && result.data.sessionTimeout) {
                    return logOutUser(props)
                };
                return resolve(result.data.getUpcomingTrips);
            })
            .catch(err => reject(err));
        });
    };

    const showUpcomingTrips = () => {
        if (trips.upcomingTrips.length > 0) {
            return (
                <React.Fragment>
                    <Grid item xs={12}>
                        { trips.upcomingTrips.map((upcomingTrip, index) => {
                            return (
                                <div
                                    className={classes.root}
                                    key={index}
                                >
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                justify="space-between"
                                                alignItems="flex-end"
                                            >
                                                <Grid item xs={12} sm={8} md={9}>
                                                    <Typography className={classes.heading}>
                                                        {upcomingTrip.p15_tripname}
                                                    </Typography>
                                                </Grid>

                                                <Box>
                                                    <Typography className={classes.heading}>
                                                        {moment(upcomingTrip.p15_startdate).format("MMM D, YYYY")}
                                                        &nbsp;-&nbsp;
                                                        {moment(upcomingTrip.p15_enddate).format("MMM D, YYYY")}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails style={{display: "block"}}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        className={classes.subHeading}
                                                    >Final Payment Due: {moment(upcomingTrip.p15_finalpaymentduedate).format("MMM D, YYYY")}
                                                    </Typography>
                                                </Grid>

                                                {displayItineraryButton(upcomingTrip)}

                                                <Grid item xs={12} sm={6}>
                                                    <FormControl fullWidth={true} >
                                                        <Link
                                                            to={
                                                                "/userform?guestId="
                                                                + encodeURIComponent(upcomingTrip.p15_guestsid)
                                                                + "&tripDepartureId="
                                                                + encodeURIComponent(upcomingTrip.p15_tripdeparturesid)
                                                                + "&tripName="
                                                                + encodeURIComponent(upcomingTrip.p15_tripname)
                                                                + "&startDate="
                                                                + encodeURIComponent(moment(upcomingTrip.p15_startdate).format("MMM D, YYYY"))
                                                                + "&endDate="
                                                                + encodeURIComponent(moment(upcomingTrip.p15_enddate).format("MMM D, YYYY"))
                                                                + "&activeStep=0"
                                                            }
                                                            className={classes.link}>
                                                            <Button
                                                                size="small"
                                                                variant="outlined"
                                                                color="primary"
                                                                fullWidth={true}
                                                            >Update My Travel Info
                                                            </Button>
                                                        </Link>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>

                                            <br></br>

                                            <TableContainer>
                                                <Table
                                                    className={classes.table}
                                                    size="small"
                                                    aria-label="a dense table"
                                                >
                                                    <TableBody>
                                                        <TableRow key={index}>
                                                            <TableCell component="th" scope="row"><b>Please complete the below information</b></TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>
                                                        {Object.keys(upcomingTrip.sectionStatus).map((key, index) => {
                                                            if (upcomingTrip.sectionStatus[key] != null) {
                                                                return (
                                                                    <TableRow
                                                                        key={index}
                                                                        onClick={() => openFormStepper(upcomingTrip, key)}
                                                                        style={{cursor: "pointer"}}
                                                                    >
                                                                        <TableCell component="th" scope="row">{key}</TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                upcomingTrip.sectionStatus[key]
                                                                                ? <SvgIcon style={{color: "#52b456"}}>
                                                                                    <path d="M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm-2 17l-5-5 1.4-1.4 3.6 3.6 7.6-7.6L19 8l-9 9z" />
                                                                                </SvgIcon>
                                                                                : <SvgIcon style={{color: "#f44336"}} viewBox="0 0 19 19">
                                                                                    <path d="M10.185,1.417c-4.741,0-8.583,3.842-8.583,8.583c0,4.74,3.842,8.582,8.583,8.582S18.768,14.74,18.768,10C18.768,5.259,14.926,1.417,10.185,1.417 M10.185,17.68c-4.235,0-7.679-3.445-7.679-7.68c0-4.235,3.444-7.679,7.679-7.679S17.864,5.765,17.864,10C17.864,14.234,14.42,17.68,10.185,17.68 M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z" />
                                                                                </SvgIcon>
                                                                            }
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            }
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                    <br></br>
                                </div>
                            )})
                        }
                    </Grid>
                </React.Fragment>
            )
        } else if (!isLoading && trips.upcomingTrips.length == 0) {
           return (
               <>
                   <Grid item xs={12}>
                       <Typography component="p">
                           Looks like you don't have any upcoming trips,
                           head over to our website to find your next adventure.
                       </Typography>
                   </Grid>
               </>
           )
        }
    };

    const openFormStepper = (upcomingTrip, selectedSection) => {
        return props.history.push(
            "/userform?guestId="
            + encodeURIComponent(upcomingTrip.p15_guestsid)
            + "&tripDepartureId="
            + encodeURIComponent(upcomingTrip.p15_tripdeparturesid)
            + "&tripName="
            + encodeURIComponent(upcomingTrip.p15_tripname)
            + "&startDate="
            + encodeURIComponent(moment(upcomingTrip.p15_startdate).format("MMM D, YYYY"))
            + "&endDate="
            + encodeURIComponent(moment(upcomingTrip.p15_enddate).format("MMM D, YYYY"))
            + "&activeStep="
            + calculateActiveStep(upcomingTrip.sectionStatus, selectedSection)
        )
    }

    const calculateActiveStep = (sectionStatus, selectedSection) => {
        let sections = {
            "Gear": 0,
            "Contact Info": 1,
            "Traveller Profile": 2,
            "Arrival & Departure": 3,
            "Payment & Insurance": 4
        };

        // No gear means changing the order of the sections
        if (!("Gear" in sectionStatus)) {
            sections = {
                "Contact Info": 0,
                "Traveller Profile": 1,
                "Arrival & Departure": 2,
                "Payment & Insurance": 3
            }
        }

        return sections[selectedSection]
    };

    const displayItineraryButton = (trip) => {
        if (trip.guestItineraryId) {
            return (
                <Grid
                    item
                    xs={12}
                    sm={6}
                >
                    <FormControl fullWidth={true} >
                        <div className={classes.link}>
                            <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                fullWidth={true}
                                onClick={() => window.open("https://itineraries.butterfield.com/itinerary/Landing/" + trip.guestItineraryId)}
                            > View Itinerary
                            </Button>
                        </div>
                    </FormControl>
                </Grid>
            )
        }
    };

    useEffect(() => {

        getData()
        .then((upcomingTrips) => {
            console.log(upcomingTrips);
            dispatchTrips({
                type: "SET_UPCOMING_TRIPS",
                payload: upcomingTrips
            });
        })
        .catch((err) => {
            if (!axios.isCancel(err)) {
                updateIsLoading(false);
                updateAlertTitle(ERROR_TITLE);
                updateAlertMessage(ERROR_MESSAGE);
                updateIsAlertOpen(true);
            };
        });

        return () => {
            source.cancel();
            updateIsLoading(false);
        };
    }, []);

    return (
        <div>
            <br></br><br></br>
            <Typography variant="h4" component="h4">
                Upcoming Trips
            </Typography>
            <br></br>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography component="p">
                        Plans for your trip are set in motion well before your
                        departure and finalized a month before it begins.
                        For this reason, your MyB&R Trip Page will lock 45 days
                        before your trip start date, so please be sure to add your
                        preferences at your earliest convenience. If you wish to
                        make any changes within 45 days of your trip, please
                        contact your Travel Coordinator at 1-800-678-1147.
                    </Typography>
                </Grid>

                {showUpcomingTrips()}
            </Grid>
        </div>
    );
}

export default Trips;
