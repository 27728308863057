import React from 'react';
import Typography from '@material-ui/core/Typography';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CallIcon from '@material-ui/icons/Call';
import Grid from '@material-ui/core/Grid';

function ContactInformation() {

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography component="h4" style={{fontSize: "15px"}}>
                    <b>Contact Us</b>
                </Typography>
            </Grid>
            <br></br>
            <Grid item xs={12}>
                <span onClick={() => window.open("mailTo: info@butterfield.com")}>
                    <MailOutlineIcon size="small" style={{float: "left"}}/>
                    &nbsp;&nbsp;
                    <span>Email info@butterfield.com</span>
                </span>
            </Grid>
            <Grid item xs={12} sm={6}>
                <span onClick={() => window.open("tel: +18665519090")}>
                    <CallIcon  size="small" style={{float: "left"}}/>
                    &nbsp;&nbsp;
                    <span>Call 1-866-551-9090</span>
                </span>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="caption" component="i">
                    Monday to Friday, 9am-5pm EST
                </Typography>
            </Grid>
        </Grid>
    );
};

export default ContactInformation;
