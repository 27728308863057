export function contactInfoReducer (state, action) {
    switch(action.type) {
        // MISC
        case "SET_NAME_ON_CARD":
            return { ...state, nameOnCard: action.payload}
        case "SET_BOOKING_ID":
            return { ...state, bookingID: action.payload}
        case "SET_GUEST_OWNER_ID":
            return { ...state, guestOwnerId: action.payload}
        case "SET_STEPS":
            return { ...state, steps: action.payload}
        case "SET_COMPLETED_SECTIONS":
            return { ...state, completedSections: action.payload}
        case "SET_ACTIVE_STEP":
            return {...state, activeStep: action.payload}
        case "SET_CURRENTLY_EXPANDED":

            /*
            NOTE:
                Accounting for same section being clicked on. If a user clicks
                on the same section then it should collapse the section and set
                the active step to the length of the array so that no section is
                expanded.
            */
            if (state.currentlyExpanded == action.payload) {
                return {
                    ...state,
                    currentlyExpanded: state.steps.length,
                    activeStep: state.steps.length
                }
            } else {
                return {...state, currentlyExpanded: action.payload}
            }
        case "SET_TRIP_ARRIVING_TAB_INDEX":
            return { ...state, arrivingTabIndex: action.payload}
        case "SET_TRIP_DEPARTURE_TAB_INDEX":
            return { ...state, departureTabIndex: action.payload}
        case "SET_IS_IFRAME_OPEN":
            return { ...state, isIframeOpen: action.payload}
        case "SET_IS_INVOICE_OPEN":
            return { ...state, isInvoiceOpen: action.payload}
        case "SET_ARRIVAL_TABS":
            return { ...state, arrivalTabs: [...state.arrivalTabs, action.payload]}
        case "SET_DEPARTURE_TABS":
            return { ...state, departureTabs: [...state.departureTabs, action.payload]}
        case "SET_ORIGINAL_STATE":
            return { ...state, originalState: Object.assign(state.originalState, action.payload)}
        case "SET_GEAR":
            return { ...state, gear: action.payload}
        case "SET_GEAR_COPY":
            return { ...state, gearCopy: action.payload}
        case "SET_WAIVER_SIGNED":
            return { ...state, b_signedWaiverReceived: action.payload}
        case "SET_ARE_FIELDS_LOCKED":
            return { ...state, b_updatesLocked: action.payload}
        case "SET_STAFF_EMAIL":
            return { ...state, staff_email: action.payload}
        case "SET_STAFF_FULL_NAME":
            return { ...state, staff_fullname: action.payload}
        case "SET_STAFF_PHONE":
            return { ...state, staff_telephone: action.payload}
        case "SET_SPEED_DIAL_OPEN_STATUS":
            return { ...state, speedDialIsOpen: action.payload}
        case "SET_STAFF_PHOTO_URL":
            return { ...state, staff_photo_url: action.payload}

        // NAME
        case "SET_PREFIX":
            return { ...state, p15_title: action.payload}
        case "SET_FIRST_NAME":
            return { ...state, firstname: action.payload}
        case "SET_LAST_NAME":
            return { ...state, lastname: action.payload}
        case "SET_MIDDLE_NAME":
            return { ...state, middlename: action.payload}
        case "SET_PREFERRED_NAME":
            return { ...state, p15_preferredname: action.payload}

        // PHONE NUMBERS
        case "SET_MOBILE_PHONE":
            return { ...state, mobilephone: action.payload}
        case "SET_HOME_PHONE":
            return { ...state, telephone2: action.payload}
        case "SET_WORK_PHONE":
            return { ...state, telephone1: action.payload}

        // HOME ADDRESS
        case "SET_ADDRESS_1":
            return { ...state, address1_line1: action.payload}
        case "SET_ADDRESS_2":
            return { ...state, address1_line2: action.payload}
        case "SET_CITY":
            return { ...state, address1_city: action.payload}
        case "SET_STATE":
            return { ...state, address1_stateorprovince: action.payload}
        case "SET_ZIP":
            return { ...state, address1_postalcode: action.payload}
        case "SET_COUNTRY":
            return { ...state, address1_country: action.payload}
        case "SET_ADDRESS_1_NAME":
            return { ...state, address1_name: action.payload}
        case "SET_ADDRESS_2_NAME":
            return { ...state, address2_name: action.payload}

        // SHIPPING ADDRESS
        case "SET_SAME_AS_HOME_ADRESS":
            return { ...state, sameAsHomeAddress: action.payload}
        case "SET_SHIPPING_ADRESS_1":
            return { ...state, address2_line1: action.payload}
        case "SET_SHIPPING_ADRESS_2":
            return { ...state, address2_line2: action.payload}
        case "SET_SHIPPING_CITY":
            return { ...state, address2_city: action.payload}
        case "SET_SHIPPING_ZIP":
            return { ...state, address2_postalcode: action.payload}
        case "SET_SHIPPING_STATE":
            return { ...state, address2_stateorprovince: action.payload}
        case "SET_SHIPPING_COUNTRY":
            return { ...state, address2_country: action.payload}

        // EMERGENCY CONTACT
        case "SET_EMERGENCY_FULL_NAME":
            return { ...state, p15_emergency1name: action.payload}
        case "SET_EMERGENCY_RELATIONSHIP":
            return { ...state, p15_emergency1relationship: action.payload}
        case "SET_EMERGENCY_EMAIL":
            return { ...state, p15_emergency1email: action.payload}
        case "SET_EMERGENCY_MOBILE_PHONE":
            return { ...state, p15_emergency1mobilephone: action.payload}
        case "SET_EMERGENCY_WORK_PHONE":
            return { ...state, p15_emergency1workphone: action.payload}
        case "SET_EMERGENCY_HOME_PHONE":
            return { ...state, p15_emergency1homephone: action.payload}

        // SECONDARY EMERGENCY CONTACT
        case "SET_ADD_SECOND_CONTACT":
            return { ...state, addSecondEmergencyContact: action.payload}
        case "SET_SECONDARY_EMERGENCY_FULL_NAME":
            return { ...state, p15_emergency2name: action.payload}
        case "SET_SECONDARY_EMERGENCY_RELATIONSHIP":
            return { ...state, p15_emergency2relationship: action.payload}
        case "SET_SECONDARY_EMERGENCY_EMAIL":
            return { ...state, p15_emergency2email: action.payload}
        case "SET_SECONDARY_EMERGENCY_MOBILE_PHONE":
            return { ...state, p15_emergency2mobilephone: action.payload}
        case "SET_SECONDARY_EMERGENCY_WORK_PHONE":
            return { ...state, p15_emergency2workphone: action.payload}
        case "SET_SECONDARY_EMERGENCY_HOME_PHONE":
            return { ...state, p15_emergency2homephone: action.payload}
        case "SET_USER_CONFIRMED_CONTACT_INFO":
            return { ...state, br_confirmedvalidcontactinfo: action.payload}

        // PERSONAL DETAILS
        case "SET_SHOW_FLIPPER_SIZE":
            return { ...state, br_tifflippersize: action.payload}
        case "SET_SHOW_SHOE_SIZE":
            return { ...state, br_tifshoesize: action.payload}
        case "SET_SHOW_HEIGHT":
            return { ...state, br_tifheightrequired: action.payload}
        case "SET_SHOW_TSHIRT":
            return { ...state, br_tiftshirtrequired: action.payload}
        case "SET_SHOW_BIKE_JERSEY":
            return { ...state, br_jerseysizerequired: action.payload}
        case "SET_SHOW_WEIGHT":
            return { ...state, br_tifweight: action.payload}
        case "SET_HEIGHT_FT":
            return { ...state, br_height_ft: action.payload}
        case "SET_HEIGHT_INCHES":
            return { ...state, br_height_in: action.payload}
        case "SET_WEIGHT":
            return { ...state, br_weight: action.payload}
        case "SET_DATE_OF_BIRTH":
            return { ...state, birthdate: action.payload}
        case "SET_SELECTED_BIKE_JERSEY_SIZE":
            return { ...state, br_jerseysize: action.payload}
        case "SET_SELECTED_FLIPPER_SIZE":
            return { ...state, br_flippersize: action.payload}
        case "SET_SELECTED_SHOE_SIZE":
            return { ...state, br_shoesize: action.payload}
        case "SET_SELECTED_TSHIRT_SIZE":
            return { ...state, br_tshirtsize: action.payload}
        case "SET_SHOW_WETSUIT":
            return { ...state, br_tifwetsuitsize: action.payload}
        case "SET_SELECTED_WETSUIT":
            return { ...state, br_wetsuitsize: action.payload}

        case "SET_VACCINATION_STATUS":
            return { ...state, br_vaccinationstatus: action.payload}
        case "SET_VACCINATION_TYPE":
            return { ...state, br_vaccinetype: action.payload}
        case "SET_DATE_OF_LAST_VACCINATION":
            return { ...state, br_dateoflastvaccination: action.payload}
        case "SET_ALLERGIES":
            return { ...state, br_allergies: action.payload}
        case "SET_DIATARY_PREFERENCES":
            return { ...state, br_dietarypreferences: action.payload}
        case "SET_MEDICAL_CONDITIONS":
            return { ...state, br_medicalconditions: action.payload}
        case "SET_NAME_ON_PASSPORT":
            return { ...state, p15_nameonpassport: action.payload}
        case "SET_PASSPORT_NUMBER":
            return { ...state, p15_passportnumber: action.payload}
        case "SET_SHOW_PASSPORT_SECTION":
            return { ...state, br_tifpassportflag: action.payload}
        case "SET_UPLOAD_PASSPORT_PHOTO":
            return { ...state, br_passportuploadflag: action.payload}
        case "SET_IS_PASSPORT_CONFIRMATION_CHECKED":
            return { ...state, br_travellingwithpassportonrecord: action.payload}
        case "SET_PLACE_OF_ISSUE":
            return { ...state, p15_placeofissue: action.payload}
        case "SET_PLACE_OF_BIRTH":
            return { ...state, p15_placeofbirth: action.payload}
        case "SET_ISSUE_DATE":
            return { ...state, p15_passportissued: action.payload}
        case "SET_EXPIRY_DATE":
            return { ...state, p15_passportexpires: action.payload}
        case "SET_NATIONALITY":
            return { ...state, p15_passportnationality: action.payload}
        case "SET_SHOW_VISA":
            return { ...state, br_visaflag: action.payload}
        case "SET_VISA_NUMBER":
            return { ...state, br_visanumber: action.payload}
        case "SET_VISA_EXPIRY_DATE":
            return { ...state, br_visadateofexpiry: action.payload}
        case "SET_VISA_ISSUE_DATE":
            return { ...state, br_visavaliditydate: action.payload}
        case "SET_IS_PASSPORT_PHOTO_UPLOADED":
            return { ...state, isPassportPhotoUploaded: action.payload}
        case "SET_IS_VISA_PHOTO_UPLOADED":
            return { ...state, isVisaPhotoUploaded: action.payload}


        // GEAR
        case "SET_SELECTED_BIKE":
            return { ...state, selectedBike: action.payload}
        case "SET_SELECTED_HELMET":
            return { ...state, selectedHelmet: action.payload}
        case "SET_SELECTED_PEDALS":
            return { ...state, selectedPedal: action.payload}
        case "SET_SELECTED_SEAT_COVER":
            return { ...state, selectedSeatCover: action.payload}
        case "SET_SELECTED_MIRROR":
            return { ...state, selectedMirror: action.payload}
        case "SET_SELECTED_GOLF_CLUB_PREFERENCE":
            return { ...state, selectedGolfClubPreference: action.payload}

        // ARRIVAL & DEPARTURE
        case "SET_SHOW_TRIP_PRE_POST_ASSISTANCE":
            return { ...state, br_offerprepostassistance: action.payload}
        case "SET_SHOW_PRETRIP_HOTEL_NAME":
            return { ...state, br_tifnightbeforehotelflag: action.payload}
        case "SET_SHOW_TRIP_INTRO":
            return { ...state, br_pudoarrivalintro: action.payload}
        case "SET_SHOW_POST_TRIP_TRAVEL_PLANS":
            return { ...state, br_tifposttriptravelplans: action.payload}
        case "SET_SHOW_TRIP_FLIGHTS":
            return { ...state, br_flightsflag: action.payload}
        case "SET_SHOW_TRIP_TRAINS":
            return { ...state, br_trainsflag: action.payload}

        case "SET_TRIP_PICK_UP_LOCATION":
            return { ...state, br_pickuplocation: action.payload}
        case "SET_TRIP_DROP_OFF_CITY":
            return { ...state, br_dropoffcity: action.payload}
        case "SET_TRIP_PICK_UP_DATE":
            return { ...state, p15_startdate: action.payload}
        case "SET_TRIP_DROP_OFF_DATE":
            return { ...state, p15_enddate: action.payload}
        case "SET_TRIP_PICK_UP_TIME":
            return { ...state, br_pickuptime: action.payload}
        case "SET_TRIP_DROP_OFF_TIME":
            return { ...state, br_dropofftime: action.payload}
        case "SET_HOTEL_NAME":
            return { ...state, br_hotelnamenightpriortotrip: action.payload}
        case "SET_CONSUMER_POST_TRIPS":
            return { ...state, br_posttriptravelplans: action.payload}
        case "SET_TRIP_ARRIVAL_CARRIER":
            return { ...state, br_flightarrivaloncarrier: action.payload}
        case "SET_TRIP_ARRIVAL_FLIGHT_NO":
            return { ...state, br_flightarrivalflightnumber: action.payload}
        case "SET_TRIP_ARRIVAL_CITY":
            return { ...state, br_flightarrivalcity: action.payload}
        case "SET_TRIP_ARRIVAL_DATE":
            return { ...state, br_flightdateofarrival: action.payload}
        case "SET_TRIP_ARRIVAL_TIME":
            return { ...state, br_flightarrivaltime: action.payload}
        case "SET_TRIP_ARRIVAL_TRAIN_TIME":
            return { ...state, br_trainarrivaltime: action.payload}
        case "SET_TRIP_ARRIVAL_TRAIN_DATE":
            return { ...state, br_trainarrivaldate: action.payload}

        case "SET_TRIP_DEPARTURE_CARRIER":
            return { ...state, br_flightdepartureoncarrier: action.payload}
        case "SET_TRIP_DEPARTURE_FLIGHT_NO":
            return { ...state, br_flightdepartureflightnumber: action.payload}
        case "SET_TRIP_DEPARTURE_CITY":
            return { ...state, br_flightdeparturecity: action.payload}
        case "SET_TRIP_DEPARTURE_DATE":
            return { ...state, br_flightdateofdeparture: action.payload}
        case "SET_TRIP_DEPARTURE_TIME":
            return { ...state, br_flightdeparturetime: action.payload}
        case "SET_TRIP_DEPARTURE_TRAIN_CARRIER":
            return { ...state, br_departuretraincarrier: action.payload}
        case "SET_TRIP_DEPARTURE_TRAIN_NUMBER":
            return { ...state, br_departuretrainnumber: action.payload}
        case "SET_TRIP_DEPARTURE_TRAIN_STATION":
            return { ...state, br_departuretrainstation: action.payload}
        case "SET_TRIP_ARRIVAL_TRAIN_CARRIER":
            return { ...state, br_arrivaltraincarrier: action.payload}
        case "SET_TRIP_ARRIVAL_TRAIN_NUMBER":
            return { ...state, br_arrivaltrainnumber: action.payload}
        case "SET_TRIP_ARRIVAL_TRAIN_STATION":
            return { ...state, br_arrivaltrainstation: action.payload}
        case "SET_TRIP_DEPARTURE_TRAIN_DATE":
            return { ...state, br_traindeparturedate: action.payload}
        case "SET_TRIP_DEPARTURE_TRAIN_TIME":
            return { ...state, br_traindeparturetime: action.payload}
        case "SET_TRIP_ARRIVAL_TRAIN_DATE":
            return { ...state, br_trainarrivaldate: action.payload}
        case "SET_TRIP_ARRIVAL_TRAIN_TIME":
            return { ...state, br_trainarrivaltime: action.payload}
        case "SET_IS_VACCINATION_DATE_VALID":
            return { ...state, isVaccinationDateValid: action.payload }
        case "SET_IS_VACCINATION_STATUS_VALID":
            return { ...state, isVaccinationStatusValid: action.payload}
        case "SET_IS_VACCINE_TYPE_VALID":
            return { ...state, isVaccineTypeValid: action.payload}

        // PAYMENT & INSURANCE
        case "SET_SELECTED_PAYMENT_METHOD":
            return { ...state, br_finalpaymentmethod: action.payload}
        case "SET_SELECTED_CREDIT_CARD":
            return { ...state, p15_creditcardid: action.payload}
        case "SET_CREDIT_CARDS":
            return { ...state, creditCards: action.payload}
        case "SET_PREFERRED_INSURANCE_OPTION":
            return { ...state, br_insurancestatus: action.payload}
        case "SET_BOOKING_NUMBER":
            return { ...state, p15_bookingnumber: action.payload}
        case "SET_USER_AGREED_TO_TERMS_AND_CONDITIONS_INVOICE":
            return { ...state, br_agreedtotermsandconditions: action.payload}
        case "SET_USER_AGREED_TO_AUTHORIZE_FINAL_PAYMENT":
            return { ...state, br_authorizedfinalpayment: action.payload}
        case "SET_INSURANCE_PURCHASE_URL":
            return { ...state, insurancePurchaseURL: action.payload}
        case "SET_INVOICE":
            return { ...state, invoice: action.payload}
        case "SET_HIDE_CREDIT_CARD_OPTIONS":
            return { ...state, br_offercreditcardpaymentoption: action.payload}
        case "SET_FINAL_PAYMENT_OPTIONS":
            return { ...state, finalPaymentOptions: action.payload}
        case "SET_INSURANCE_COMMENTS":
            return { ...state, br_insurancecomments: action.payload}
        case "SET_LOCK_AUTHORIZE_PAYMENT_CHECKBOX":
            return { ...state, lockAuthorizePaymentCheckbox: action.payload}

        // OTHER INFORMATION
        case "SET_TRAVELER_COMMENTS":
            return { ...state, br_travellercomments: action.payload}
        case "SET_TASK_DESCRIPTION":
            return { ...state, taskDescription: action.payload}

        // SNACKBAR
        case "SET_SNACKBAR_MESSAGE":
            return {...state, snackbarMessage: action.payload}
        case "SET_SNACKBAR_STATUS":
            return {...state, snackbarStatus: action.payload}
        case "SET_SNACKBAR_IS_OPEN":
            return {...state, snackbarIsOpen: action.payload}

        // SIZES
        case "SET_T_SHIRT_SIZES":
            return {...state, tShirtSizes: action.payload}
        case "SET_FLIPPER_SIZES":
            return {...state, flipperSizes: action.payload}
        case "SET_JERSEY_SIZES":
            return {...state, jerseySizes: action.payload}
        case "SET_WETSUIT_SIZES":
            return {...state, wetsuitSizes: action.payload}

        // CITIES
        case "SET_ARRIVAL_CITIES":
            return {...state, citiesArriving: action.payload}
        case "SET_DEPARTURE_CITIES":
            return {...state, citiesDeparting: action.payload}


        // INPUT STATUSES
        // Gear
        case "SET_IS_BIKE_VALID":
            return { ...state, showBikeError: action.payload}
        case "SET_IS_HELMET_VALID":
            return { ...state, showHelmetError: action.payload}
        case "SET_IS_PEDAL_VALID":
            return { ...state, showPedalError: action.payload}
        case "SET_IS_SEAT_COVER_VALID":
            return { ...state, showSeatCoverError: action.payload}
        case "SET_IS_MIRROR_VALID":
            return { ...state, showMirrorError: action.payload}

        // Contact Info
        case "SET_IS_FIRST_NAME_VALID":
            return { ...state, isFirstNameValid: action.payload}
        case "SET_IS_LAST_NAME_VALID":
            return { ...state, isLastNameValid: action.payload}
        case "SET_IS_DATE_OF_BIRTH_VALID":
            return { ...state, isBirthDateValid: action.payload}
        case "SET_IS_MOBILE_PHONE_VALID":
            return { ...state, isMobilePhoneValid: action.payload}
        case "SET_IS_ADDRESS_1_LINE_1_VALID":
            return { ...state, isAddress1Line1Valid: action.payload}
        case "SET_IS_ADDRESS_1_CITY_VALID":
            return { ...state, isAddress1CityValid: action.payload}
        case "SET_IS_ADDRESS_1_POSTAL_CODE_VALID":
            return { ...state, isAddress1PostalCodeValid: action.payload}
        case "SET_IS_ADDRESS_1_COUNTRY_VALID":
            return { ...state, isAddress1CountryValid: action.payload}
        case "SET_IS_EMERGENCY_1_NAME_VALID":
            return { ...state, isEmergency1NameValid: action.payload}
        case "SET_IS_EMERGENCY_1_RELATIONSHIP_VALID":
            return { ...state, isEmergency1RelationshipValid: action.payload}
        case "SET_IS_EMERGENCY_1_MOBILE_PHONE_VALID":
            return { ...state, isEmergency1MobilePhoneValid: action.payload}
        case "SET_IS_BR_CONFIRMED_VALID_CONTACT_INFO_VALID":
            return { ...state, isBrConfirmedValidContactInfoValid: action.payload}
        case "SET_IS_ADDRESS_2_LINE_1_VALID":
            return {...state, isAddress2Line1Valid: action.payload}
        case "SET_IS_ADDRESS_2_CITY_VALID":
            return {...state, isAddress2CityValid: action.payload}
        case "SET_IS_ADDRESS_2_POSTAL_CODE_VALID":
            return {...state, isAddress2PostalCodeValid: action.payload}
        case "SET_IS_ADDRESS_2_COUNTRY_VALID":
            return {...state, isAddress2CountryValid: action.payload}

        // Traveller Profile
        case "SET_IS_HEIGHT_INCHES_VALID":
            return { ...state, isHeightInchesValid: action.payload }
        case "SET_IS_HEIGHT_FEET_VALID":
            return { ...state, isHeightFeetValid: action.payload }
        case "SET_IS_TRAVELLING_WITH_PASSPORT_ON_RECORD_VALID":
            return { ...state, isTravellingWithPassportOnRecordValid: action.payload }
        case "SET_IS_NAME_ON_PASSPORT_VALID":
            return { ...state, isNameOnPassportValid: action.payload }
        case "SET_IS_PASSPORT_NUMBER_VALID":
            return { ...state, isPassportNumberValid: action.payload }
        case "SET_IS_PASSPORT_ISSUE_DATE_VALID":
            return { ...state, isPassportIssueDateValid: action.payload }
        case "SET_IS_PASSPORT_EXPIRY_DATE_VALID":
            return { ...state, isPassportExpiryDateValid: action.payload }
        case "SET_IS_PASSPORT_NATIONALITY_VALID":
            return { ...state, isPassportNationalityValid: action.payload }
        case "SET_IS_WEIGHT_VALID":
            return { ...state, isWeightValid: action.payload }
        case "SET_IS_SHOE_SIZE_VALID":
            return { ...state, isShoeSizeValid: action.payload }
        case "SET_IS_FLIPPER_SIZE_VALID":
            return { ...state, isFlipperSizeValid: action.payload }
        case "SET_IS_WET_SUIT_SIZE_VALID":
            return { ...state, isWetSuitSizeValid: action.payload }
        case "SET_IS_TSHIRT_SIZE_VALID":
            return { ...state, isTShirtSizeValid: action.payload }
        case "SET_IS_JERSEY_SIZE_VALID":
            return { ...state, isJerseySizeValid: action.payload }
        case "SET_IS_TRAVEL_VISA_NUMBER_VALID":
            return { ...state, isTravelVisaNumberValid: action.payload }
        case "SET_IS_WAIVER_RECEIVED_VALID":
            return {...state, isWaiverReceived: action.payload }

        // Gear
        case "SET_IS_SELECTED_BIKE_VALID":
            return { ...state, isSelectedBikeValid: action.payload }
        case "SET_IS_SELECTED_HELMET_VALID":
            return { ...state, isSelectedHelmetValid: action.payload }
        case "SET_IS_SELECTED_PEDAL_VALID":
            return { ...state, isSelectedPedalValid: action.payload }
        case "SET_IS_SELECTED_SEAT_COVER_VALID":
            return { ...state, isSelectedSeatCoverValid: action.payload }
        case "SET_IS_SELECTED_MIRROR_VALID":
            return { ...state, isSelectedMirrorValid: action.payload }
        case "SET_IS_SELECTED_GOLF_CLUB_PREFERENCE_VALID":
            return { ...state, isSelectedGolfClubPreferenceValid: action.payload }

        // Payment
        case "SET_IS_INSURANCE_STATUS_VALID":
            return { ...state, isInsuranceStatusValid: action.payload }
        case "SET_IS_AGREED_TO_TERMS_AND_CONDITIONS_VALID":
            return { ...state, isAgreedToTermsAndConditionsValid: action.payload }
        case "SET_IS_FINAL_PAYMENT_METHOD_VALID":
            return { ...state, isFinalPaymentMethodValid: action.payload }
        case "SET_IS_AGREED_TO_AUTHORIZE_PAYMENT_VALID":
            return { ...state, isAgreedToAuthorizePaymentValid: action.payload }
        case "SET_IS_SELECTED_CREDIT_CARD_VALID":
            return { ...state, isSelectedCreditCardValid: action.payload }


        // Arrival & Departure
        case "SET_IS_HOTEL_NAME_PRIOR_TO_TRIP_VALID":
            return {...state, isHotelNamePriorToTripValid: action.payload}
        // FLight arrival
        case "SET_IS_FLIGHT_ARRIVAL_DATE_VALID":
            return {...state, isFlightArrivalDateValid: action.payload}
        case "SET_IS_FLIGHT_ARRIVAL_CARRIER_VALID":
            return {...state, isFlightArrivalCarrierValid: action.payload}
        case "SET_IS_FLIGHT_ARRIVAL_FLIGHT_NO_VALID":
            return {...state, isFlightArrivalNumberValid: action.payload}
        case "SET_IS_FLIGHT_ARRIVAL_TIME_VALID":
            return {...state, isFlightArrivalTimeValid: action.payload}

        // Flight departure
        case "SET_IS_FLIGHT_DEPARTURE_DATE_VALID":
            return {...state, isFlightDepartureDateValid: action.payload}
        case "SET_IS_FLIGHT_DEPARTURE_CARRIER_VALID":
            return {...state, isFlightDepartureCarrierValid: action.payload}
        case "SET_IS_FLIGHT_DEPARTURE_FLIGHT_NO_VALID":
            return {...state, isFlightDepartureNumberValid: action.payload}
        case "SET_IS_FLIGHT_DEPARTURE_TIME_VALID":
            return {...state, isFlightDepartureTimeValid: action.payload}

        // Train arrival
        case "SET_IS_TRAIN_ARRIVAL_DATE_VALID":
            return {...state, isTrainArrivalDateValid: action.payload}
        case "SET_IS_TRAIN_ARRIVAL_TIME_VALID":
            return {...state, isTrainArrivalTimeValid: action.payload}
        case "SET_IS_TRAIN_ARRIVAL_STATION_VALID":
            return {...state, isTrainArrivalStationValid: action.payload}
        case "SET_IS_TRAIN_ARRIVAL_TRAIN_NO_VALID":
            return {...state, isTrainArrivalNumberValid: action.payload}

        // Train departure
        case "SET_IS_TRAIN_DEPARTURE_DATE_VALID":
            return {...state, isTrainDepartureDateValid: action.payload}
        case "SET_IS_TRAIN_DEPARTURE_TIME_VALID":
            return {...state, isTrainDepartureTimeValid: action.payload}
        case "SET_IS_TRAIN_DEPARTURE_STATION_VALID":
            return {...state, isTrainDepartureStationValid: action.payload}
        case "SET_IS_TRAIN_DEPARTURE_TRAIN_NO_VALID":
            return {...state, isTrainDepartureNumberValid: action.payload}

        // ERRORS
        case "SET_NAME_ON_CARD_ERROR":
            return { ...state, nameOnCardError: action.payload}
        case "SET_SHOW_NAME_ON_CARD_ERROR":
            return { ...state, showNameOnCardError: action.payload}
        case "SET_FIRST_NAME_ERROR":
            return { ...state, firstNameError: action.payload}
        case "SET_SHOW_FIRST_NAME_ERROR":
            return { ...state, showFirstNameError: action.payload}
        case "SET_LAST_NAME_ERROR":
            return { ...state, lastNameError: action.payload}
        case "SET_SHOW_LAST_NAME_ERROR":
            return { ...state, showLastNameError: action.payload}
        case "SET_MOBILE_PHONE_ERROR":
            return { ...state, mobilePhoneError: action.payload}
        case "SET_SHOW_MOBILE_PHONE_ERROR":
            return { ...state, showMobilePhoneError: action.payload}
        case "SET_ADDRESS_1_ERROR":
            return { ...state, address1Error: action.payload}
        case "SET_SHOW_ADDRESS_1_ERROR":
            return { ...state, showAddress1Error: action.payload}
        case "SET_CITY_ERROR":
            return { ...state, cityError: action.payload}
        case "SET_SHOW_CITY_ERROR":
            return { ...state, showCityError: action.payload}
        case "SET_STATE_ERROR":
            return { ...state, stateError: action.payload}
        case "SET_SHOW_STATE_ERROR":
            return { ...state, showStateError: action.payload}
        case "SET_ZIP_ERROR":
            return { ...state, zipError: action.payload}
        case "SET_SHOW_ZIP_ERROR":
            return { ...state, showZipError: action.payload}
        case "SET_COUNTRY_ERROR":
            return { ...state, countryError: action.payload}
        case "SET_SHOW_COUNTRY_ERROR":
            return { ...state, showCountryError: action.payload}
        case "SET_SHIPPING_ADDRESS_1_ERROR":
            return { ...state, shippingAddress1Error: action.payload}
        case "SET_SHOW_SHIPPING_ADDRESS_1_ERROR":
            return { ...state, showShippingAddress1Error: action.payload}
        case "SET_SHIPPING_CITY_ERROR":
            return { ...state, shippingCityError: action.payload}
        case "SET_SHOW_SHIPPING_CITY_ERROR":
            return { ...state, showShippingCityError: action.payload}
        case "SET_SHIPPING_STATE_ERROR":
            return { ...state, shippingStateError: action.payload}
        case "SET_SHOW_SHIPPING_STATE_ERROR":
            return { ...state, showShippingStateError: action.payload}
        case "SET_SHIPPING_ZIP_ERROR":
            return { ...state, shippingZipError: action.payload}
        case "SET_SHOW_SHIPPING_ZIP_ERROR":
            return { ...state, showShippingZipError: action.payload}
        case "SET_SHIPPING_COUNTRY_ERROR":
            return { ...state, shippingCountryError: action.payload}
        case "SET_SHOW_SHIPPING_COUNTRY_ERROR":
            return { ...state, showShippingCountryError: action.payload}
        case "SET_EMERGENCY_FULL_NAME_ERROR":
            return { ...state, emergencyFullNameError: action.payload}
        case "SET_SHOW_EMERGENCY_FULL_NAME_ERROR":
            return { ...state, showEmergencyFullNameError: action.payload}
        case "SET_EMERGENCY_RELATIONSHIP_ERROR":
            return { ...state, emergencyRelationshipError: action.payload}
        case "SET_SHOW_EMERGENCY_RELATIONSHIP_ERROR":
            return { ...state, showEmergencyRelationshipError: action.payload}
        case "SET_EMERGENCY_EMAIL_ERROR":
            return { ...state, emergencyEmailError: action.payload}
        case "SET_SHOW_EMERGENCY_EMAIL_ERROR":
            return { ...state, showEmergencyEmailError: action.payload}
        case "SET_EMERGENCY_MOBILE_PHONE_ERROR":
            return { ...state, emergencyMobilePhoneError: action.payload}
        case "SET_SHOW_EMERGENCY_MOBILE_PHONE_ERROR":
            return { ...state, showEmergencyMobilePhoneError: action.payload}
        case "SET_SECONDARY_EMERGENCY_FULL_NAME_ERROR":
            return { ...state, secondaryEmergencyFullNameError: action.payload}
        case "SET_SHOW_SECONDARY_EMERGENCY_FULL_NAME_ERROR":
            return { ...state, showSecondaryEmergencyFullNameError: action.payload}
        case "SET_SECONDARY_EMERGENCY_RELATIONSHIP_ERROR":
            return { ...state, secondaryEmergencyRelationshipError: action.payload}
        case "SET_SHOW_SECONDARY_EMERGENCY_RELATIONSHIP_ERROR":
            return { ...state, showSecondaryEmergencyRelationshipError: action.payload}
        case "SET_SECONDARY_EMERGENCY_EMAIL_ERROR":
            return { ...state, secondaryEmergencyEmailError: action.payload}
        case "SET_SHOW_SECONDARY_EMERGENCY_EMAIL_ERROR":
            return { ...state, showSecondaryEmergencyEmailError: action.payload}
        case "SET_SECONDARY_EMERGENCY_MOBILE_PHONE_ERROR":
            return { ...state, secondaryEmergencyMobilePhoneError: action.payload}
        case "SET_SHOW_SECONDARY_EMERGENCY_MOBILE_PHONE_ERROR":
            return { ...state, showSecondaryEmergencyMobilePhoneError: action.payload}
        case "SET_HEIGHT_FT_ERROR":
            return { ...state, heightFtError: action.payload}
        case "SET_SHOW_HEIGHT_FT_ERROR":
            return { ...state, showHeightFtError: action.payload}
        case "SET_HEIGHT_INCHES_ERROR":
            return { ...state, heightInchesError: action.payload}
        case "SET_SHOW_HEIGHT_INCHES_ERROR":
            return { ...state, showHeightInchesError: action.payload}
        // case "SET_SHOW_DATE_OF_BIRTH_ERROR":
        //     return { ...state, showDateOfBirthError: action.payload }
        case "SET_NAME_ON_PASSPORT_ERROR":
            return { ...state, nameOnPassportError: action.payload}
        case "SET_SHOW_NAME_ON_PASSPORT_ERROR":
            return { ...state, showNameOnPassportError: action.payload}
        case "SET_PASSPORT_NUMBER_ERROR":
            return { ...state, passportNumberError: action.payload}
        case "SET_SHOW_PASSPORT_NUMBER_ERROR":
            return { ...state, showPassportNumberError: action.payload}

        case "SET_SHOW_PASSPORT_ISSUE_DATE_ERROR":
            return { ...state, showPassportIssueDateError: action.payload}
        case "SET_SHOW_PASSPORT_EXPIRY_DATE_ERROR":
            return { ...state, showPassportExpiryDateError: action.payload}

        case "SET_PLACE_OF_BIRTH_ERROR":
            return { ...state, placeOfBirthError: action.payload}
        case "SET_SHOW_PLACE_OF_BIRTH_ERROR":
            return { ...state, showPlaceOfBirthError: action.payload}

        case "SET_NATIONALITY_ERROR":
            return { ...state, nationalityError: action.payload}
        case "SET_SHOW_NATIONALITY_ERROR":
            return { ...state, showNationalityError: action.payload}

        case "SET_SHOW_USER_CONFIRM_PASSPORT_INFO_ERROR":
            return { ...state, showUserConfirmedPassportInfoError: action.payload}

        case "SET_SHOW_USER_CONFIRM_CONTACT_INFO_ERROR":
            return { ...state, showUserConfirmedContactInfoError: action.payload}

        case "SET_WEIGHT_ERROR":
            return { ...state, weightError: action.payload}
        case "SET_SHOW_WEIGHT_ERROR":
            return { ...state, showWeightError: action.payload}

        case "SET_SHOE_SIZE_ERROR":
            return { ...state, shoeSizeError: action.payload}
        case "SET_SHOW_SHOE_SIZE_ERROR":
            return { ...state, showShoesizeError: action.payload}

        case "SET_FLIPPER_SIZE_ERROR":
            return { ...state, flipperSizeError: action.payload}
        case "SET_SHOW_FLIPPER_SIZE_ERROR":
            return { ...state, showFlipperSizeError: action.payload}

        case "SET_WET_SUIT_ERROR":
            return { ...state, wetSuitSizeError: action.payload}
        case "SET_SHOW_WET_SUIT_ERROR":
            return { ...state, showWetSuitSizeError: action.payload}

        case "SET_TSHIRT_SIZE_ERROR":
            return { ...state, tShirtSizeError: action.payload}
        case "SET_SHOW_TSHIRT_SIZE_ERROR":
            return { ...state, showTshirtSizeError: action.payload}

        case "SET_JERSEY_SIZE_ERROR":
            return { ...state, jerseySizeError: action.payload}
        case "SET_SHOW_JERSEY_SIZE_ERROR":
            return { ...state, showJerseySizeError: action.payload}

        case "SET_VISA_NUMBER_ERROR":
            return { ...state, visaNumberError: action.payload}
        case "SET_SHOW_VISA_NUMBER_ERROR":
            return { ...state, showVisaNumberError: action.payload}
        case "SET_VISA_EXPIRY_DATE_ERROR":
            return { ...state, showVisaIssueDateError: action.payload}
        case "SET_VISA_ISSUE_DATE_ERROR":
            return { ...state, showVisaExpiryDateError: action.payload}

        // PAYMENT METHOD
        case "SET_SHOW_CREDIT_CARD_SECTION":
            return {
                ...state,
                showCreditCardSection: action.payload,
                showAddCreditCardButton: !action.payload
            }
        case "SET_SHOW_ADD_CREDIT_CARD_BUTTON":
            return { ...state, showAddCreditCardButton: action.payload}
    }
}
