import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


function TabPanel(props) {
    const { children, value, index, height, ...other } = props;

    return (
        <Typography
            style={{minHeight: height, boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}>
                {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    height: PropTypes.any.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function TabComponent(props) {
    /*
    INPUT:
        tabs: Array - [{label: "Flights", icon: JSX/Material UI},...]
        handleChange: Function - updates state
        value: String - bound and holds current status
        height: Integer - sets the height of the tab content
        children: Array - holds JSX where key is the TabPanel's index
    OUTPUT:
        Tab component
    */

    const classes = useStyles();
    const {tabs, handleChange, value, height, children} = props;

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    scrollButtons="auto"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    {tabs.map((tab, index) => {
                        return <Tab label={tab.label} icon={tab.icon} key={index}/>
                    })}
                </Tabs>
            </AppBar>
            {
                tabs.map((tab, index) => {
                    return (
                        <TabPanel value={value} index={index} height={height} key={index}>
                            {children[index]}
                        </TabPanel>
                    )
                })
            }
        </div>
    );
}


TabComponent.propTypes = {
    tabs: PropTypes.any.isRequired,
    handleChange: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    height: PropTypes.any.isRequired,
    children: PropTypes.any.isRequired,
};
