export const getURLParams = () => {
  var O_params = {}

  if (document.location.href.split("?").length === 1) {
      return O_params;//No params, ditch with empty object
  }

  for(var i_ptr=0; i_ptr < document.location.href.split("?")[1].split("&").length; i_ptr++) {
    //each parameter set here
    if(
        (document.location.href.split("?")[1].split("&")[i_ptr].split("=").length === 1)
        || (document.location.href.split("?")[1].split("&")[i_ptr].split("=")[1] === "")
    ){
        continue;//leave these undefined
    }

    O_params[decodeURIComponent(document.location.href.split("?")[1].split("&")[i_ptr].split("=")[0])] = decodeURIComponent(document.location.href.split("?")[1].split("&")[i_ptr].split("=")[1]);
  }

  return O_params;
};
