import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function SnackBar(props) {

    const {
        isOpen,
        handleClose,
        message,
        status,
        toShowCloseButton
    } = props
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Snackbar
                open={isOpen}
                onClose={handleClose}
                message={message}
            >
                {
                    toShowCloseButton
                    ? <Alert
                        onClose={handleClose}
                        severity={status}>{message}
                    </Alert>
                    : <Alert
                        action={<div></div>}
                        onClose={handleClose}
                        severity={status}>{message}
                    </Alert>
                }
            </Snackbar>
        </div>
    );
}

SnackBar.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
};
