import React, { useContext, useEffect, useReducer } from "react"
import axios from "axios";
import { HEADERS } from "../config";
import { getURLParams } from "../utils.js";
import SHA512 from "crypto-js/sha512";
import { Link } from "react-router-dom";
import { ERROR_MESSAGE, ERROR_TITLE } from "../constants.js";

//MATERIAL UI
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from '@material-ui/icons/Phone';
import { makeStyles } from '@material-ui/core/styles';

// Custom Components
import ContactInformation from "../presentational/ContactInformation";

// CONTEXT
import { LoadingWheelContext } from "../context/LoadingWheelContext";
import { AlertContext } from "../context/AlertContext";
import { UserContext } from "../context/UserContext";

// REDUCER
import { forgotPasswordReducer } from "../reducers/ForgotPasswordReducer.js";

const useStyles = makeStyles(theme =>({
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    link: {
        textDecoration: "none",
        marginRight: theme.spacing(1),
        '&:visited': {
            color: "inherit"
        },
    },
}))

function ForgotPassword (props) {

    let initialState = {
        email: "",
        password: "",
        confirmedPassword: "",
        emailError: "",
        passwordError: "",
        confirmedPasswordError: "",
        securityQuestion: "",
        securityAnswer: "",
        showEmailError: false,
        showPasswordError: false,
        showConfirmedPasswordError: false,
        urlParams: getURLParams(),
    };

    let [forgotPasswordInfo, dispatchForgotPasswordInfo] = useReducer(forgotPasswordReducer, initialState);

    let classes = useStyles();
    let { history } = props;
    let { updateIsLoading } = useContext(LoadingWheelContext);
    let { updateUserInfo } = useContext(UserContext);
    let {
        updateAlertTitle, updateIsAlertOpen, updateAlertMessage,
        updateAlertSuccess, updateAlertButtons, resetState, updateOnExitButtonClick
    } = useContext(AlertContext);

    let handleSubmit = () => {

        // If valid, proceed with setting values and calling API
        if (isUserInputValid()) {
            updateIsLoading(true);

            var userData = {userEmail: forgotPasswordInfo.email}
            let route = "/v1/getSecurityQuestion"

            if (history.location.pathname === "/security/answer") {
                route = "/v1/validateSecurityAnswer";
                userData["securityAnswer"] = forgotPasswordInfo.securityAnswer;
            } else if (history.location.pathname === "/resetPassword") {
                delete userData["userEmail"]
                userData["userPassword"] = SHA512(forgotPasswordInfo.password).toString().toUpperCase()
                userData["userEmail"] = forgotPasswordInfo.urlParams["email"]
                userData["token"] = forgotPasswordInfo.urlParams["token"]
                route = "/v1/setForgottenPassword"
            };


            axios.post(process.env.REACT_APP_API_ENDPOINT + route, JSON.stringify(userData), HEADERS)
            .then((result) => {
                if (result.status !== 200) {
                    updateIsLoading(false);
                    updateAlertMessage(result.response.data.error.message);
                    updateIsAlertOpen(true);
                } else {
                    updateIsLoading(false);

                    if (history.location.pathname == "/security") {
                        dispatchForgotPasswordInfo({
                            type: "SET_SECURITY_QUESTION",
                            payload: result.data.securityQuestion
                        });
                        history.push("/security/answer");
                    } else if (history.location.pathname == "/security/answer") {
                        updateAlertSuccess(true)
                        updateAlertMessage(result.data.message);
                        updateOnExitButtonClick(() => history.push("/"));
                        updateAlertButtons([{
                            id: 0,
                            name: "Ok",
                            action: () => {
                                history.push("/");
                                resetState();
                            },
                            color: "primary",
                            variant: "contained",
                        }]);
                        updateIsAlertOpen(true);
                    } else if (history.location.pathname === "/resetPassword") {
                        updateIsAlertOpen(true)
                        updateAlertSuccess(true)
                        updateAlertMessage(result.data.message);

                        localStorage.setItem("token", result.data.token)
                        localStorage.setItem("firstName", result.data.firstname);
                        localStorage.setItem("lastName", result.data.lastname);
                        HEADERS["headers"]["Authorization"] = "Bearer " + result.data.token;
                        updateUserInfo({token: result.data.token});
                        updateOnExitButtonClick(() => history.push("/home"));
                        updateAlertButtons([{
                            id: 0,
                            name: "Ok",
                            action: () => {
                                history.push("/home");
                                resetState();
                            },
                            color: "primary",
                            variant: "contained",
                        }]);
                    };
                };
            })
            .catch((err) => {
                updateIsLoading(false)
                updateAlertTitle(ERROR_TITLE)
                if (err.response.status >= 500) {
                    updateAlertMessage(ERROR_MESSAGE)
                } else {
                    updateAlertMessage(err.response.data.error.message)
                };
                updateIsAlertOpen(true)
            })
        }
    };

    let isUserInputValid = () => {
        var isValid = true;

        if (history.location.pathname === "/security") {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(forgotPasswordInfo.email)) {
                dispatchForgotPasswordInfo({type: "SET_EMAIL_ERROR", payload: "Invalid email address"})
                dispatchForgotPasswordInfo({type: "SET_SHOW_EMAIL_ERROR", payload: true})
                isValid = false;
            } else {
                dispatchForgotPasswordInfo({type: "SET_EMAIL_ERROR", payload: ""})
                dispatchForgotPasswordInfo({type: "SET_SHOW_EMAIL_ERROR", payload: false})
            }
        } else if (history.location.pathname === "/resetPassword") {
            if (forgotPasswordInfo.password.length < 8) {
                dispatchForgotPasswordInfo({type: "SET_SHOW_PASSWORD_ERROR", payload: true})
                dispatchForgotPasswordInfo({type: "SET_PASSWORD_ERROR", payload: "Password must be at least 8 characters long"})
                isValid = false;
            } else {
                dispatchForgotPasswordInfo({type: "SET_SHOW_PASSWORD_ERROR", payload: false})
                dispatchForgotPasswordInfo({type: "SET_PASSWORD_ERROR", payload: ""})
            }

            // Validate confirm password
            if (forgotPasswordInfo.password.trim() !== "" && forgotPasswordInfo.confirmedPassword !== forgotPasswordInfo.password) {
                dispatchForgotPasswordInfo({type: "SET_CONFIRMED_PASSWORD_ERROR", payload: "Confirm Password does not match password"})
                dispatchForgotPasswordInfo({type: "SET_SHOW_CONFIRMED_PASSWORD_ERROR", payload: true})
                isValid = false;
            } else if ((forgotPasswordInfo.confirmedPassword || "") === "") {
                dispatchForgotPasswordInfo({type: "SET_CONFIRMED_PASSWORD_ERROR", payload: "Confirm Password cannot be empty"})
                dispatchForgotPasswordInfo({type: "SET_SHOW_CONFIRMED_PASSWORD_ERROR", payload: true})
                isValid = false;
            } else {
                dispatchForgotPasswordInfo({type: "SET_CONFIRMED_PASSWORD_ERROR", payload: ""})
                dispatchForgotPasswordInfo({type: "SET_SHOW_CONFIRMED_PASSWORD_ERROR", payload: false})
            }
        }

        return isValid;
    };

    let renderForm = () => {
        let form = (
            <Grid item xs={12}>
                <FormControl fullWidth={true}>
                    <TextField
                        error={forgotPasswordInfo.showEmailError}
                        id="email"
                        label="Email"
                        helperText={forgotPasswordInfo.emailError}
                        className={classes.textField}
                        margin="normal"
                        onChange={(event) => dispatchForgotPasswordInfo({
                            type: "SET_EMAIL", payload: event.target.value
                        })}
                    />
                </FormControl>
            </Grid>
        )

        if (history.location.pathname === "/security/answer") {
            form = (
                <React.Fragment>
                    <Grid item xs={12} sm={6} md={6}>
                        <FormControl fullWidth={true}>
                            <TextField
                                value={forgotPasswordInfo.securityQuestion}
                                id="securityQuestion"
                                label="Security Question"
                                className={classes.textField}
                                margin="normal"
                                type="text"
                                name="securityQuestion"
                                disabled={true}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <FormControl fullWidth={true}>
                            <TextField
                                id="securityAnswer"
                                label="Security Answer"
                                className={classes.textField}
                                margin="normal"
                                type="text"
                                onChange={(event) => dispatchForgotPasswordInfo({
                                    type: "SET_SECURITY_ANSWER",
                                    payload: event.target.value
                                })}
                            />
                        </FormControl>
                    </Grid>
                </React.Fragment>
            )
        } else if (history.location.pathname === "/resetPassword") {
            form = (
                <React.Fragment>
                    <Grid item xs={12} sm={6} md={6}>
                        <FormControl fullWidth={true}>
                            <TextField
                                error={forgotPasswordInfo.showPasswordError}
                                id="password"
                                label="Password"
                                helperText={forgotPasswordInfo.passwordError}
                                className={classes.textField}
                                margin="normal"
                                type="password"
                                name="password"
                                onChange={(event) => dispatchForgotPasswordInfo({
                                    type: "SET_PASSWORD",
                                    payload: event.target.value
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <FormControl fullWidth={true}>
                            <TextField
                                error={forgotPasswordInfo.showConfirmedPasswordError}
                                id="confirmedPassword"
                                label="Confirm Password"
                                helperText={forgotPasswordInfo.confirmedPasswordError}
                                className={classes.textField}
                                margin="normal"
                                type="password"
                                onChange={(event) => dispatchForgotPasswordInfo({
                                    type: "SET_CONFIRMED_PASSWORD",
                                    payload: event.target.value
                                })}
                            />
                        </FormControl>
                    </Grid>
                </React.Fragment>
            )
        }

        return form;
    }

    let renderParagraph = () => {
        var paragraph = `Reset your password by entering the email address used to create your MyB&R account below.`

        if (history.location.pathname === "/security/answer") {
            paragraph = `Enter your security answer below to proceed with setting your new password.`
        } else if (history.location.pathname === "/resetPassword") {
            paragraph = "Reset your password by entering your new password below."
        };

        return paragraph;
    }

    useEffect(() => {

        // If token exists then redirect to dashboard otherwise set values in localStorage
        if (localStorage.getItem("token")) history.push("/home");

        // Redirect user to "/security" route if params object is empty
        if (
            (
                forgotPasswordInfo.urlParams["email"] === undefined
                || forgotPasswordInfo.urlParams["token"] === undefined
            )
            && history.location.pathname === "/resetPassword"
        ) {
            history.push("/security")
        }
    }, [])

    return (
        <div>
            <CssBaseline>
            <Card className={classes.card}>
                <CardContent>
                    <br></br>
                    <Typography variant="h5" component="h6">
                        MyB&R: Reset Password
                    </Typography>
                    <br></br>
                    <Typography component="i">
                        {renderParagraph()}
                    </Typography>
                    <br></br>

                    {/*FORM*/}
                    <Grid container spacing={2}>
                        {renderForm()}
                    </Grid>
                </CardContent>


                <CardActions >
                    <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="flex-end"
                    >
                        <Grid item>
                            <Link to="/" className={classes.link}>
                                <Button
                                    variant="outlined"
                                    color="primary">Back
                                </Button>
                            </Link>
                        </Grid>
                        <Grid>
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                                color="primary">Reset Password
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
                <br></br>
            </Card>

            <br></br>
            <br></br>
            <br></br>
            <ContactInformation/>
            <br></br>

            </CssBaseline>
        </div>
    )
}

export default ForgotPassword;
