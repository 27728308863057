export const formStepperState = {
  snackbarStatus: "success",
  snackbarMessage: "",
  snackbarIsOpen: false,
  staff_email: null,
  staff_telephone: null,
  staff_fullname: null,
  staff_photo_url: null,
  speedDialIsOpen: false,
  bookingID: "",
  guestOwnerId: "",
  steps: [],
  completedSections: new Set(),
  originalState: {},
  gear: [],
  gearCopy: [],
  activeStep: 0,
  currentlyExpanded: null,
  arrivingTabIndex: 0,
  departureTabIndex: 0,
  isIframeOpen: false,
  isInvoiceOpen: false,
  b_updatesLocked: false,
  br_vaccinationstatus: "select",
  br_vaccinetype: "",
  br_dateoflastvaccination: null,
  vaccinationStatusOptions: [
      {value: "select", name: "--Select--"},
      {value: "100000000", name: "Fully Vaccinated"},
      {value: "100000001", name: "Partially Vaccinated"},
      {value: "100000002", name: "Not Vaccinated"},
      {value: "100000003", name: "Undisclosed"},
  ],
  p15_title: "",
  firstname: "",
  middlename: "",
  lastname: "",
  p15_preferredname: "",
  mobilephone: "",
  telephone1: "",
  telephone2: "",
  address1_line1: "",
  address1_line2: "",
  address1_city: "",
  address1_stateorprovince: "",
  address1_postalcode: "",
  address1_country: "",
  sameAsHomeAddress: true,
  address2_line1: "",
  address2_line2: "",
  address2_city: "",
  address2_stateorprovince: "",
  address2_postalcode: "",
  address2_country: "",
  address1_name: "",
  address2_name: "",
  p15_emergency1name: "",
  p15_emergency1relationship: "",
  p15_emergency1email: "",
  p15_emergency1mobilephone: "",
  p15_emergency1homephone: "",
  p15_emergency1workphone: "",
  addSecondEmergencyContact: false,
  p15_emergency2name: "",
  p15_emergency2relationship: "",
  p15_emergency2email: "",
  p15_emergency2mobilephone: "",
  p15_emergency2homephone: "",
  p15_emergency2workphone: "",
  br_confirmedvalidcontactinfo: false,
  br_height_ft: "",
  br_height_in: "",
  birthdate: null,
  br_weight: "",
  isAllergiesChecked: false,
  br_allergies: "",
  isDietaryPreferencesChecked: false,
  br_dietarypreferences: "",
  isMedicalConditionsChecked: false,
  br_medicalconditions: "",
  br_travellingwithpassportonrecord: false,
  br_tifpassportflag: false,
  br_passportuploadflag: false,
  p15_nameonpassport: "",
  p15_passportnumber: "",
  p15_placeofissue: "",
  p15_placeofbirth: "",
  p15_passportissued: null,
  p15_passportexpires: null,
  p15_passportnationality: "",
  b_signedWaiverReceived: false,
  br_visaflag: false,
  br_visanumber: "",
  br_visadateofexpiry: null,
  br_visavaliditydate: null,
  br_jerseysize:  "select",
  jerseySizes: [{value: "select", name: "--Select--"}],
  br_flippersize: "select",
  flipperSizes: [{value: "select", name: "--Select--"}],
  wetsuitSizes: [{value: "select", name: "--Select--"}],
  br_shoesize: "select",
  shoeSizes: [
      {value: "select", name: "--Select--"},
      {value: "6", name: "6"},
      {value: "6.5", name: "6.5"},
      {value: "7", name: "7"},
      {value: "7.5", name: "7.5"},
      {value: "8", name: "8"},
      {value: "8.5", name: "8.5"},
      {value: "9", name: "9"},
      {value: "9.5", name: "9.5"},
      {value: "10", name: "10"},
      {value: "10.5", name: "10.5"},
      {value: "11", name: "11"},
      {value: "11.5", name: "11.5"},
      {value: "12", name: "12"},
      {value: "12.5", name: "12.5"},
      {value: "13", name: "13"},
      {value: "13.5", name: "13.5"},
      {value: "14", name: "14"},
      {value: "14.5", name: "14.5"}
  ],
  br_tshirtsize: "select",
  tShirtSizes: [{value: "select", name: "--Select--"}],
  br_tifflippersize: false,
  br_tifshoesize: false,
  br_tifweight: false,
  br_tifwetsuitsize: false,
  br_wetsuitsize: "select",
  br_tifheightrequired: false,
  br_tiftshirtrequired: false,
  br_jerseysizerequired: false,
  selectedBike: "select",
  selectedHelmet: "select",
  selectedPedal: "select",
  selectedSeatCover: "select",
  selectedMirror: "select",
  selectedGolfClubPreference: "select",
  citiesArriving: [{value: "select", name: "--Select--"}],
  citiesDeparting: [{value: "select", name: "--Select--"}],
  br_pickuplocation: "",
  br_dropoffcity: "",
  p15_startdate: "",
  p15_enddate: "",
  br_pickuptime: "",
  br_dropofftime: "",
  br_hotelnamenightpriortotrip: "",
  br_posttriptravelplans: "",
  br_flightarrivaloncarrier: "",
  br_flightarrivalflightnumber: "",
  br_flightarrivalcity: "select",
  br_flightdateofarrival: null,
  br_flightarrivaltime: null,
  br_flightdepartureoncarrier: "",
  br_flightdepartureflightnumber: "",
  br_flightdeparturecity: "select",
  br_flightdateofdeparture: null,
  br_flightdeparturetime: null,
  br_offerprepostassistance: false,
  br_tifnightbeforehotelflag: false,
  br_pudoarrivalintro: false,
  br_tifposttriptravelplans: false,
  br_flightsflag: false,
  br_trainsflag: false,
  br_departuretraincarrier: "",
  br_departuretrainnumber: "",
  br_departuretrainstation: "",
  br_traindeparturedate: null,
  br_traindeparturetime: null,
  br_arrivaltraincarrier: "",
  br_arrivaltrainnumber: "",
  br_arrivaltrainstation: "",
  br_trainarrivaldate: null,
  br_trainarrivaltime: null,
  arrivalTabs: [],
  departureTabs: [],

  // PAYMENT & INSURANCE
  isPassportPhotoUploaded: false,
  isVisaPhotoUploaded: false,
  lockAuthorizePaymentCheckbox: false,
  showCreditCardSection: false,
  showAddCreditCardButton: true,
  nameOnCard: "",
  br_insurancestatus: "select",
  p15_creditcardid: "select",
  br_finalpaymentmethod: "select",
  br_agreedtotermsandconditions: false,
  br_authorizedfinalpayment: false,
  br_offercreditcardpaymentoption: true,
  br_insurancecomments: "",
  insurancePurchaseURL: "",
  p15_bookingnumber: "",
  invoice: {},
  finalPaymentOptions: [
      {value: "select", name: "--Select--"},
      {value: 100000000, name: "Credit card"},
      {value: 100000001, name: "Wire"},
      {value: 100000002, name: "Cheque"},
  ],
  creditCards: [],

  //OTHER INFORMATION
  br_travellercomments: "",
  taskDescription: "",

  // SECTION INPUT STATUSES
  // Contact Info
  isFirstNameValid: true,
  isLastNameValid: true,
  isBirthDateValid: true,
  isMobilePhoneValid: true,
  isAddress1Line1Valid: true,
  isAddress1CityValid: true,
  isAddress1PostalCodeValid: true,
  isAddress1CountryValid: true,
  isAddress2Line1Valid: true,
  isAddress2CityValid: true,
  isAddress2PostalCodeValid: true,
  isAddress2CountryValid: true,
  isEmergency1NameValid: true,
  isEmergency1RelationshipValid: true,
  isEmergency1MobilePhoneValid: true,
  isBrConfirmedValidContactInfoValid: true,

  // Traveller Profile
  isHeightInchesValid: true,
  isHeightFeetValid: true,
  isTravellingWithPassportOnRecordValid: true,
  isNameOnPassportValid: true,
  isPassportNumberValid: true,
  isPassportExpiryDateValid: true,
  isPassportIssueDateValid: true,
  isVaccinationDateValid: true,
  isVaccinationStatusValid: true,
  isVaccineTypeValid: true,
  isPassportNationalityValid: true,
  isWeightValid: true,
  isShoeSizeValid: true,
  isFlipperSizeValid: true,
  isWetSuitSizeValid: true,
  isTShirtSizeValid: true,
  isJerseySizeValid: true,
  isTravelVisaNumberValid: true,
  isWaiverReceived: true,

  // Gear
  isSelectedBikeValid: true,
  isSelectedHelmetValid: true,
  isSelectedPedalValid: true,
  isSelectedSeatCoverValid: true,
  isSelectedMirrorValid: true,
  isSelectedGolfClubPreferenceValid: true,

  // Payment & Insurance
  isInsuranceStatusValid: true,
  isAgreedToTermsAndConditionsValid: true,
  isFinalPaymentMethodValid: true,
  isAgreedToAuthorizePaymentValid: true,
  isSelectedCreditCardValid: true,

  // Arrival and Departure
  isHotelNamePriorToTripValid: true,
  isFlightArrivalNumberValid: true,
  isFlightArrivalCarrierValid: true,
  isFlightArrivalDateValid: true,
  isFlightArrivalTimeValid: true,

  isFlightDepartureNumberValid: true,
  isFlightDepartureCarrierValid: true,
  isFlightDepartureDateValid: true,
  isFlightDepartureTimeValid: true,

  // train
  isTrainArrivalNumberValid: true,
  isTrainArrivalStationValid: true,
  isTrainArrivalDateValid: true,
  isTrainArrivalTimeValid: true,

  isTrainDepartureNumberValid: true,
  isTrainDepartureStationValid: true,
  isTrainDepartureDateValid: true,
  isTrainDepartureTimeValid: true,

  //ERRORS
  nameOnCardError: "",
  showNameOnCardError: false,
  shippingAddress1Error: "",
  showShippingAddress1Error: false,
  shippingCityError: "",
  showShippingCityError: false,
  shippingStateError: "",
  showShippingStateError: false,
  shippingZipError: "",
  showShippingZipError: false,
  shippingCountryError: "",
  showShippingCountryError: false
}
