import React, { createContext } from 'react';

export const UserContext = createContext();

class UserProvider extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            token: localStorage.getItem("token")
        }

        this.updateUserInfo = this.updateUserInfo.bind(this);
        this.logOutUser = this.logOutUser.bind(this);
    }


    updateUserInfo (userObj) {
        this.setState({token: userObj.token})
    }


    logOutUser(props) {
        localStorage.clear();
        this.setState({token: ""});
        props.history.push("/");
    }


    render () {
        return (
            <UserContext.Provider
                value={{
                    ...this.state,
                    updateUserInfo: this.updateUserInfo,
                    logOutUser: this.logOutUser,
                }}
            >
                {this.props.children}
            </UserContext.Provider>
        );
    }
}

export default UserProvider;
