import React, { useContext, useEffect } from "react";
import { getURLParams } from "../utils.js";
import axios from "axios";
import { HEADERS } from "../config";
import { ERROR_MESSAGE, ERROR_TITLE } from "../constants.js";

import { AlertContext } from "../context/AlertContext";
import { LoadingWheelContext } from "../context/LoadingWheelContext";


function SuccessForgotPassword (props) {
    /*
    NOTE:
        1. User enters email to receive link to update credentials (API call w/ response).
        Response a) 200 - message: "Please check your inbox for the password recovery email. Note: it may be in SPAM or Promotions."
                    Page refreshes once user clicks OK button.
        Response b) 222 - error: "Account not found" and refreshes the page

        2. User opens link that will have email and token. On page load API request to the server will made.
        Response a) 200 - OK Navigate to resetPassword page
        Response b) 222 - error: "Something went wrong"

        3. User sets new password and makes API call to server
        Response a) 200 - message: "Successfully changed password"
                    User is logged in with new credentials.
        Response b) 222 - error: "Failed attempt to reset password"
                    Page refreshes to allow user to try again.
    */

    const {
        updateAlertTitle, updateAlertMessage, updateIsAlertOpen,
        updateAlertSuccess, updateAlertButtons, updateOnExitButtonClick
    } = useContext(AlertContext);
    const { updateIsLoading } = useContext(LoadingWheelContext);
    const { history } = props

    useEffect(() => {
        const params = getURLParams();

        if (params["token"] === undefined || params["email"] === undefined) {
            history.push("/security")
            return;
        }

        let closeFunc = () => history.push("/security");

        updateIsLoading(true)

        axios.post(
            process.env.REACT_APP_API_ENDPOINT + "/v1/checkForgotPasswordToken",
            JSON.stringify({token: params["token"], userEmail: params["email"]}),
            HEADERS
        )
        .then(result => {
            updateIsLoading(false)
            history.push(
                "/resetPassword?email="
                + params["email"]
                + "&token="
                + params["token"]
            )
        })
        .catch(err => {
            updateIsLoading(false)
            updateAlertTitle(ERROR_TITLE)
            if (err.response.status >= 500) {
                updateAlertMessage(ERROR_MESSAGE)
            } else {
                updateAlertMessage(err.response.data.error.message)
            };
            updateOnExitButtonClick(closeFunc)
            updateIsAlertOpen(true)
        })
    }, [])

    return <div></div>;
}


export default SuccessForgotPassword;
