import React, { useContext, useEffect, useReducer } from "react";
import { UserContext } from "../context/UserContext";
import { AlertContext } from "../context/AlertContext";
import { LoadingWheelContext } from "../context/LoadingWheelContext";

import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import { green } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from '@material-ui/icons/Phone';

import { getURLParams } from "../utils.js";
import axios from "axios";
import { HEADERS } from "../config";
import { ERROR_MESSAGE, ERROR_TITLE } from "../constants.js";

import { successAccountCreationReducer } from "../reducers/SuccessAccountCreationReducer.js";

function SuccessAccountCreation (props) {

    let initialState = {
        isAccountEnabled: false,
        accountCreationMsg: "",
        urlParams: getURLParams(),
    };

    const [accountInfo, dispatchAccountInfo] = useReducer(successAccountCreationReducer, initialState);

    const { updateUserInfo } = useContext(UserContext);
    const {
        updateAlertTitle, updateAlertMessage, updateOnExitButtonClick,
        updateIsAlertOpen, updateAlertSuccess, updateAlertButtons
    } = useContext(AlertContext);
    const { updateIsLoading } = useContext(LoadingWheelContext);
    const { history } = props;

    let determineRenderSuccessMessage = () => {
        if (accountInfo.isAccountEnabled) {
            return (
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid item xs={12} style={{textAlign: "center"}}>
                        <Typography component="h1" variant="h2">
                            Welcome to B&R
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography component="h2" variant="h2" style={{textAlign: "center"}}>
                            <DoneIcon style={{ color: green[500], fontSize: 100 }}/>
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "center"}}>
                        <Typography component="h6" variant="h6">
                            {accountInfo.accountCreationMsg}
                        </Typography>
                    </Grid>
                </Grid>
            )
        };

        return <div></div>;
    }

    useEffect(() => {

        const params = getURLParams();
        if (
            params["token"] === undefined
            || params["email"] === undefined
            || params["contactid"] === undefined
        ) {
            history.push("/signup")
            return;
        }

        let closeFunc = () => history.push("/");


        /*
        NOTE:
            If the sessionID does exists then redirect to dashboard. Otherwise
            grab the email and token and send it back to API to enable the accout.
            Then if the request is successful then log user in and show success message.
            Otherwise if the request is not successful, display the error message
            and redirect the user to the log in page once they click OK button.
        */
        if (localStorage.getItem("token") === null) {
            updateIsLoading(true)
            axios.post(
                process.env.REACT_APP_API_ENDPOINT + "/v1/enableAccount",
                JSON.stringify({
                    token: params["token"],
                    userEmail: params["email"],
                    contactid: params["contactid"]
                }),
                HEADERS
            )
            .then(result => {

                // Failure
                if (result.status !== 200) {
                    updateIsLoading(false);
                    updateAlertMessage(result.response.data.error.message);
                    updateOnExitButtonClick(closeFunc)
                    updateIsAlertOpen(true);
                } else {

                    // Success
                    updateIsLoading(false);
                    localStorage.setItem("token", result.data.token);
                    localStorage.setItem("firstName", result.data.firstname);
                    localStorage.setItem("lastName", result.data.lastname);
                    HEADERS["headers"]["Authorization"] = "Bearer " + result.data.token;
                    updateUserInfo({token: result.data.token});
                    dispatchAccountInfo({type: "SET_ACCOUNT_CREATION_MESSAGE", payload: result.data.message});
                    dispatchAccountInfo({type: "SET_IS_ACCOUNT_ENABLED", payload: true});

                    setTimeout(() => {
                        history.push("/home");
                    }, 2000);
                }
            })
            .catch(err => {
                updateIsLoading(false)
                updateAlertTitle(ERROR_TITLE)
                updateOnExitButtonClick(closeFunc)
                if (err.response.status >= 500) {
                    updateAlertMessage(ERROR_MESSAGE)
                } else {
                    updateAlertMessage(err.response.data.error.message)
                };
                updateIsAlertOpen(true)
            })
        } else {
            history.push("/home");
        }
    }, [])

    return (
        <div>
            <br></br>
            <br></br>
            {determineRenderSuccessMessage()}
        </div>
    )
}


export default SuccessAccountCreation;
