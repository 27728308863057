import React, { useContext, useEffect, useReducer } from "react"
import axios from "axios";
import { HEADERS } from "../config";

//Material UI
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

// Material Table
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MaterialTable from 'material-table'
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

// Custom Components
import CustomSelect from "../presentational/CustomSelect";

// Reducers
import { changeLogReducer } from "../reducers/ChangeLogReducer";

// Context
import { LoadingWheelContext } from "../context/LoadingWheelContext";
import { UserContext } from "../context/UserContext";

const useStyles = makeStyles(theme =>({
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    link: {
        textDecoration: "none",
        marginRight: theme.spacing(1),
        '&:visited': {
            color: "inherit"
        },
    }
}))

function ChangeLog (props) {

    let classes = useStyles();
    const { logOutUser } = useContext(UserContext);
    const initialState = {
        selectedTravelAdvisor: "all",
        travelAdvisors: [],
        showTable: false,
        selectedStartDate: new Date(),
        selectedEndDate: new Date(),
        selectedFieldName: "all",
        fieldNames: [],
        changeLogRecords: [],
    };
    const [changeLog, dispatchChangeLog] = useReducer(changeLogReducer, initialState);

    const { updateIsLoading } = useContext(LoadingWheelContext);
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    const isUserAllowedToViewChangeLog = (callback) => {
        updateIsLoading(true)
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/v1/changelogs/canUserAccessChangelog", {token: localStorage.getItem("token")}, HEADERS)
        .then((result) => {
            updateIsLoading(false);
            return callback(result);
        })
        .catch((err) => {
            updateIsLoading(false);
            return callback(err)
        })
    };

    const displayTable = () => {
        if (changeLog.showTable) {
            return (
                <>
                    <div style={{ maxWidth: '100%' }}>
                        <MaterialTable
                            icons={tableIcons}
                            columns={[
                                { title: 'Departure Name', field: 'departure_name' },
                                { title: 'Trip Name', field: 'trip_name' },
                                { title: 'Guest Name', field: 'guest_name' },
                                { title: 'Previous Value', field: 'previous_value', type: "date"},
                                { title: 'Current Value', field: 'current_value', type: "date"},
                                { title: 'Field', field: 'field_name' },
                                { title: 'Departure Travel Coordinator', field: 'departure_travel_coordinator_name'},
                                { title: 'Audit Created On', field: 'audit_created_on', type: "date"},
                                { title: "Modified By", field: 'record_modified_by'}
                            ]}
                            data={changeLog.changeLogRecords}
                            title="Change Log"
                        />
                    </div>
                    <br></br>
                    <br></br>
                </>
            )
        }
    };

    const getChangeLogRecords = (data, callback) => {
        updateIsLoading(true);
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/v1/changelogs", data, HEADERS)
        .then((result) => {
            updateIsLoading(false);
            return callback(result);
        })
    };

    const handleSubmit = () => {

        let postObject = {
            startDate: changeLog.selectedStartDate,
            endDate: changeLog.selectedEndDate,
            advisor: changeLog.selectedTravelAdvisor, fieldName: changeLog.selectedFieldName,
            token: localStorage.getItem("token")
        };

        getChangeLogRecords(postObject, (result) => {

            if (result.status == 222 && result.data.sessionTimeout) {
                return logOutUser(props)
            };

            dispatchChangeLog({type: "SET_CHANGE_LOG_RECORDS", payload: result.data.changes});
            dispatchChangeLog({type: "SET_SHOW_TABLE", payload: true});
        });
    };

    useEffect(() => {
        isUserAllowedToViewChangeLog((result) => {
            if (result.status == 202 && result.data.sessionTimeout) {
                return logOutUser(props);
            } else if (result.status != 202) {
                props.history.goBack();
            } else {
                let newTravelAdvisors = [{"value": "all", "name": "All Travel Advisors"},];
                let newFieldNames = [{"value": "all", "name": "Select All"}];

                for (let advisor in result.data["travelAdvisors"]) {
                    newTravelAdvisors.push({
                        value: result.data["travelAdvisors"][advisor]["departure_travel_coordinator"],
                        name: result.data["travelAdvisors"][advisor]["departure_travel_coordinator_name"]
                    });
                };

                for (let field in result.data["fieldNames"]) {
                    newFieldNames.push({
                        value: result.data["fieldNames"][field]["field_name"],
                        name: result.data["fieldNames"][field]["field_name"]
                    })
                };

                dispatchChangeLog({type: "SET_TRAVEL_ADVISORS", payload: newTravelAdvisors});
                dispatchChangeLog({type: "SET_FIELD_NAMES_FILTER", payload: newFieldNames})
            }
        });
    }, [])

    return (
        <>
            <br></br>
            <br></br>
            <Card className={classes.card}>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl fullWidth={true}>
                                <CustomSelect
                                    options={changeLog.travelAdvisors}
                                    inputLabelId="inputLabelTravelAdvisors"
                                    handleChange={(event) => dispatchChangeLog({
                                        type: "SET_SELECTED_TRAVEL_ADVISOR",
                                        payload: event.target.value
                                    })}
                                    labelName="Travel Advisor"
                                    value={changeLog.selectedTravelAdvisor}
                                    selectId="selectTravelAdvisor"
                                    selectLabelId="selectLabelTravelAdvisor"
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl fullWidth={true}>
                                <CustomSelect
                                    options={changeLog.fieldNames}
                                    inputLabelId="inputLabelFieldName"
                                    handleChange={(event) => dispatchChangeLog({
                                        type: "SET_SELECTED_FIELD_NAME",
                                        payload: event.target.value
                                    })}
                                    labelName="Field Name"
                                    value={changeLog.selectedFieldName}
                                    selectId="selectFieldName"
                                    selectLabelId="selectLabelFieldName"
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl fullWidth={true}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker"
                                        label="Start Date"
                                        value={changeLog.selectedStartDate}
                                        onChange={(date) => dispatchChangeLog({type: "SET_START_DATE_FILTER", payload: date})}
                                        KeyboardButtonProps={{'aria-label': 'change date'}}
                                    />
                                </MuiPickersUtilsProvider>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl fullWidth={true}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker"
                                        label="End Date"
                                        value={changeLog.selectedEndDate}
                                        onChange={(date) => dispatchChangeLog({type: "SET_END_DATE_FILTER", payload: date})}
                                        KeyboardButtonProps={{'aria-label': 'change date'}}
                                    />
                                </MuiPickersUtilsProvider>
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Grid
                        container
                        justify="space-between"
                    >
                        <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="flex-end"
                            item
                        >
                            <Button
                                variant="contained"
                                className={classes.link}
                                color="primary"
                                onClick={handleSubmit}>Submit
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
                <br></br>
            </Card>

            <br></br>
            <br></br>
            {displayTable()}
        </>
    )
}

export default ChangeLog;
