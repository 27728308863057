import React, { useContext, useEffect, useReducer } from 'react';
import axios from "axios";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { HEADERS } from "../config";
import { getURLParams } from "../utils.js";
import { CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import { formStepperState } from "../states/formStepper.js";
import { ERROR_MESSAGE, ERROR_TITLE } from "../constants.js";

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import StepButton from '@material-ui/core/StepButton';

// Material UI
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Input from '@material-ui/core/Input';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from '@material-ui/icons/Phone';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Hidden from '@material-ui/core/Hidden';
import FormHelperText from '@material-ui/core/FormHelperText';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import TrainIcon from '@material-ui/icons/Train';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { KeyboardDatePicker, TimePicker  } from '@material-ui/pickers';

// Context
import { LoadingWheelContext } from "../context/LoadingWheelContext";
import { AlertContext } from "../context/AlertContext";
import { UserContext } from "../context/UserContext";

// Reducers
import { contactInfoReducer } from "../reducers/FormStepperReducer";

// Custom Components
import TabComponent from "../presentational/Tab";
import CustomSelect from "../presentational/CustomSelect";
import IframeModal from "../presentational/IframeModal";
import SnackBar from "../presentational/SnackBar";
import SpeedDialFloatingButton from "../presentational/SpeedDialFloatingButton";

// Assets
import butterfieldRobinson from "../assets/butterfieldRobinson.png";
import "../assets/css/FormStepper.css";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(1),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    textField: {
        marginTop: 0,
    },
    subHeader: {
        paddingBottom: "0px !important",
        paddingLeft: "0px !important"
    },
    datePicker: {
        marginTop: 0
    },
    link: {
        textDecoration: "none",
        marginRight: theme.spacing(1),
        '&:visited': {
            color: "inherit"
        },
    }
}));


function UserForm(props) {

    const urlParams = getURLParams();
    // Create an instance of Elements.
    const stripe = useStripe();
    const elements = useElements();
    const { history } = props

    const classes = useStyles();
    const [contactInfo, dispatchContactInfo] = useReducer(contactInfoReducer, formStepperState);

    // timer
    let expiryTimer = null;

    // axios cancel token
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // NOTE: These components need to be imported for every container component
    const { updateIsLoading } = useContext(LoadingWheelContext);
    const {
        updateAlertMessage,
        updateIsAlertOpen,
        updateAlertTitle,
        updateAlertButtons,
        updateAlertSuccess,
        updateMaxWidth,
        resetState,
        updateOnExitButtonClick
    } = useContext(AlertContext);
    const { updateIsUserLoggedIn, logOutUser } = useContext(UserContext);


    // Validation
    const areGearDetailsValid_v2 = () => {

        let isValid = true;
        let {Bike, Helmet, Pedals, Cover, Mirror, GolfClubPreference} = getApplicableGearItems();
        let toValidateBike = Bike;
        let toValidateHelmet = Helmet;
        let toValidatePedals = Pedals;
        let toValidateSeatCover = Cover;
        let toValidateMirror = Mirror;
        let toValidateGolfClubPreference = GolfClubPreference;

        if (toValidateBike && contactInfo.selectedBike == "select") {
            dispatchContactInfo({type: "SET_IS_SELECTED_BIKE_VALID", payload: false});
            isValid = false;
        } else {
            dispatchContactInfo({type: "SET_IS_SELECTED_BIKE_VALID", payload: true});
        };

        if (toValidateHelmet && contactInfo.selectedHelmet == "select") {
            dispatchContactInfo({type: "SET_IS_SELECTED_HELMET_VALID", payload: false});
            isValid = false;
        } else {
            dispatchContactInfo({type: "SET_IS_SELECTED_HELMET_VALID", payload: true});
        };

        if (toValidatePedals && contactInfo.selectedPedal == "select") {
            dispatchContactInfo({type: "SET_IS_SELECTED_PEDAL_VALID", payload: false});
            isValid = false;
        } else {
            dispatchContactInfo({type: "SET_IS_SELECTED_PEDAL_VALID", payload: true});
        };

        if (toValidateSeatCover && contactInfo.selectedSeatCover == "select") {
            dispatchContactInfo({type: "SET_IS_SELECTED_SEAT_COVER_VALID", payload: false});
            isValid = false;
        } else {
            dispatchContactInfo({type: "SET_IS_SELECTED_SEAT_COVER_VALID", payload: true});
        };

        if (toValidateMirror && contactInfo.selectedMirror == "select") {
            dispatchContactInfo({type: "SET_IS_SELECTED_MIRROR_VALID", payload: false});
            isValid = false;
        } else {
            dispatchContactInfo({type: "SET_IS_SELECTED_MIRROR_VALID", payload: true});
        };

        if (toValidateGolfClubPreference && contactInfo.selectedGolfClubPreference == "select") {
            dispatchContactInfo({type: "SET_IS_SELECTED_GOLF_CLUB_PREFERENCE_VALID", payload: false});
            isValid = false;
        } else {
            dispatchContactInfo({type: "SET_IS_SELECTED_GOLF_CLUB_PREFERENCE_VALID", payload: true});
        };

        return isValid;
    };

    const areContactDetailsValid_v2 = () => {

        /*
        NOTE:
            Validate the following sections:
            firstName, lastName, address1_line1, city, postalcode, country,
            emergency (full name, relationship, mobilePhone, agree contactInfo is correct)
        */
        let isValid = true;

        if ((contactInfo.firstname || "").trim() == "") {
            dispatchContactInfo({type: "SET_IS_FIRST_NAME_VALID", payload: false});
            isValid = false;
        } else {
            dispatchContactInfo({type: "SET_IS_FIRST_NAME_VALID", payload: true})
        };

        if ((contactInfo.lastname || "").trim() == "") {
            dispatchContactInfo({type: "SET_IS_LAST_NAME_VALID", payload: false});
            isValid = false;
        } else {
            dispatchContactInfo({type: "SET_IS_LAST_NAME_VALID", payload: true})
        };

        if ((contactInfo.mobilephone || "").trim() == "") {
            dispatchContactInfo({type: "SET_IS_MOBILE_PHONE_VALID", payload: false});
            isValid = false;
        } else {
            dispatchContactInfo({type: "SET_IS_MOBILE_PHONE_VALID", payload: true})
        };

        if ((contactInfo.address1_line1 || "").trim() == "") {
            dispatchContactInfo({type: "SET_IS_ADDRESS_1_LINE_1_VALID", payload: false});
            isValid = false;
        } else {
            dispatchContactInfo({type: "SET_IS_ADDRESS_1_LINE_1_VALID", payload: true})
        };

        if ((contactInfo.address1_city || "").trim() == "") {
            dispatchContactInfo({type: "SET_IS_ADDRESS_1_CITY_VALID", payload: false});
            isValid = false;
        } else {
            dispatchContactInfo({type: "SET_IS_ADDRESS_1_CITY_VALID", payload: true})
        };

        if ((contactInfo.address1_postalcode || "").trim() == "") {
            dispatchContactInfo({type: "SET_IS_ADDRESS_1_POSTAL_CODE_VALID", payload: false});
            isValid = false;
        } else {
            dispatchContactInfo({type: "SET_IS_ADDRESS_1_POSTAL_CODE_VALID", payload: true})
        };

        if ((contactInfo.address1_country || "").trim() == "") {
            dispatchContactInfo({type: "SET_IS_ADDRESS_1_COUNTRY_VALID", payload: false});
            isValid = false;
        } else {
            dispatchContactInfo({type: "SET_IS_ADDRESS_1_COUNTRY_VALID", payload: true})
        };

        if ((contactInfo.p15_emergency1name || "").trim() == "") {
            dispatchContactInfo({type: "SET_IS_EMERGENCY_1_NAME_VALID", payload: false});
            isValid = false;
        } else {
            dispatchContactInfo({type: "SET_IS_EMERGENCY_1_NAME_VALID", payload: true})
        };

        if ((contactInfo.p15_emergency1relationship || "").trim() == "") {
            dispatchContactInfo({type: "SET_IS_EMERGENCY_1_RELATIONSHIP_VALID", payload: false});
            isValid = false;
        } else {
            dispatchContactInfo({type: "SET_IS_EMERGENCY_1_RELATIONSHIP_VALID", payload: true})
        };

        if ((contactInfo.p15_emergency1mobilephone || "").trim() == "") {
            dispatchContactInfo({type: "SET_IS_EMERGENCY_1_MOBILE_PHONE_VALID", payload: false});
            isValid = false;
        } else {
            dispatchContactInfo({type: "SET_IS_EMERGENCY_1_MOBILE_PHONE_VALID", payload: true})
        };

        if (!contactInfo.br_confirmedvalidcontactinfo) {
            dispatchContactInfo({type: "SET_IS_BR_CONFIRMED_VALID_CONTACT_INFO_VALID", payload: false})
            isValid = false;
        } else {
            dispatchContactInfo({type: "SET_IS_BR_CONFIRMED_VALID_CONTACT_INFO_VALID", payload: true})
        };

        // shipping info validate if different from address
        if (!contactInfo.sameAsHomeAddress) {
            if ((contactInfo.address2_line1 || "").trim() == "") {
                dispatchContactInfo({type: "SET_IS_ADDRESS_2_LINE_1_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_ADDRESS_2_LINE_1_VALID", payload: true})
            };

            if ((contactInfo.address2_city || "").trim() == "") {
                dispatchContactInfo({type: "SET_IS_ADDRESS_2_CITY_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_ADDRESS_2_CITY_VALID", payload: true})
            };

            if ((contactInfo.address2_postalcode || "").trim() == "") {
                dispatchContactInfo({type: "SET_IS_ADDRESS_2_POSTAL_CODE_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_ADDRESS_2_POSTAL_CODE_VALID", payload: true})
            };

            if ((contactInfo.address2_country || "").trim() == "") {
                dispatchContactInfo({type: "SET_IS_ADDRESS_2_COUNTRY_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_ADDRESS_2_COUNTRY_VALID", payload: true})
            };
        }

        window.scroll({behavior: "smooth", top: 0})

        return isValid;
    };

    const areTravelerProfileDetailsValid_v2 = () => {

        let isValid = true;

        if ((contactInfo.birthdate || "").trim() == "") {
            dispatchContactInfo({type: "SET_IS_DATE_OF_BIRTH_VALID", payload: false});
            isValid = false;
        } else {
            dispatchContactInfo({type: "SET_IS_DATE_OF_BIRTH_VALID", payload: true});
        };

        // vaccine
        if (contactInfo.br_vaccinationstatus == "select") {
            dispatchContactInfo({type: "SET_IS_VACCINATION_STATUS_VALID", payload: false});
            isValid = false;
        } else {
            dispatchContactInfo({type: "SET_IS_VACCINATION_STATUS_VALID", payload: true});
        };

        if ((contactInfo.br_vaccinetype || "").trim() == "") {
            dispatchContactInfo({type: "SET_IS_VACCINE_TYPE_VALID", payload: false});
            isValid = false;
        } else {
            dispatchContactInfo({type: "SET_IS_VACCINE_TYPE_VALID", payload: true});
        }

        if ((contactInfo.br_dateoflastvaccination || "").trim() == "") {
            dispatchContactInfo({type: "SET_IS_VACCINATION_DATE_VALID", payload: false});
            isValid = false;
        } else {
            dispatchContactInfo({type: "SET_IS_VACCINATION_DATE_VALID", payload: true});
        }

        // height
        if (contactInfo.br_tifheightrequired) {
            if ((contactInfo.br_height_ft || "") == "") {
                dispatchContactInfo({type: "SET_IS_HEIGHT_FEET_VALID", payload: false});
                isValid = false;
            } else if (contactInfo.br_height_ft < 3 || contactInfo.br_height_ft > 7) {
                dispatchContactInfo({type: "SET_IS_HEIGHT_FEET_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_HEIGHT_FEET_VALID", payload: true});
            };

            if ((contactInfo.br_height_in || "") == "") {
                dispatchContactInfo({type: "SET_IS_HEIGHT_INCHES_VALID", payload: false});
                isValid = false;
            } else if (contactInfo.br_height_in < 0 || contactInfo.br_height_in > 11) {
                dispatchContactInfo({type: "SET_IS_HEIGHT_INCHES_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_HEIGHT_INCHES_VALID", payload: true});
            };
        };

        // passport upload
        if (
            !contactInfo.isPassportPhotoUploaded
            && document.getElementById("passport")
            && document.getElementById("passport").files.length == 0
        ) {
            isValid = false;
        }

        // passport details
        if (contactInfo.br_tifpassportflag) {
            if ((contactInfo.p15_nameonpassport || "").trim() == "") {
                dispatchContactInfo({type: "SET_IS_NAME_ON_PASSPORT_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_NAME_ON_PASSPORT_VALID", payload: true});
            };

            if ((contactInfo.p15_passportnumber || "").trim() == "") {
                dispatchContactInfo({type: "SET_IS_PASSPORT_NUMBER_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_PASSPORT_NUMBER_VALID", payload: true});
            };

            // passport issue date
            if ((contactInfo.p15_passportissued || "").trim() == "") {
                dispatchContactInfo({type: "SET_IS_PASSPORT_ISSUE_DATE_VALID", payload: false})
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_PASSPORT_ISSUE_DATE_VALID", payload: true});
            };

            // passport expiry date
            if (
                ((contactInfo.p15_passportexpires || "").trim() == "")
                || moment(contactInfo.p15_passportexpires) < moment(urlParams["endDate"]).add(6, "M")
            ) {
                dispatchContactInfo({type: "SET_IS_PASSPORT_EXPIRY_DATE_VALID", payload: false})
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_PASSPORT_EXPIRY_DATE_VALID", payload: true});
            };

            // passport nationality
            if ((contactInfo.p15_passportnationality || "").trim() == "") {
                dispatchContactInfo({type: "SET_IS_PASSPORT_NATIONALITY_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_PASSPORT_NATIONALITY_VALID", payload: true});
            };

            if (!contactInfo.br_travellingwithpassportonrecord) {
                dispatchContactInfo({type: "SET_IS_TRAVELLING_WITH_PASSPORT_ON_RECORD_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_TRAVELLING_WITH_PASSPORT_ON_RECORD_VALID", payload: true});
            };
        };

        // weight
        if (contactInfo.br_tifweight) {
            if ((contactInfo.br_weight || "").trim() == "") {
                dispatchContactInfo({type: "SET_IS_WEIGHT_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_WEIGHT_VALID", payload: true});
            };
        };

        // shoe size
        if (contactInfo.br_tifshoesize) {
            if (contactInfo.br_shoesize == "select") {
                dispatchContactInfo({type: "SET_IS_SHOE_SIZE_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_SHOE_SIZE_VALID", payload: true});
            };
        };

        // flipper size
        if (contactInfo.br_tifflippersize) {
            if (contactInfo.br_flippersize == "select") {
                dispatchContactInfo({type: "SET_IS_FLIPPER_SIZE_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_FLIPPER_SIZE_VALID", payload: true});
            };
        };

        // wetsuite size
        if (contactInfo.br_tifwetsuitsize) {
            if (contactInfo.br_wetsuitsize == "select") {
                dispatchContactInfo({type: "SET_IS_WET_SUIT_SIZE_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_WET_SUIT_SIZE_VALID", payload: true});
            };
        };

        // t-shirt size
        if (contactInfo.br_tiftshirtrequired) {
            if (contactInfo.br_tshirtsize == "select") {
                dispatchContactInfo({type: "SET_IS_TSHIRT_SIZE_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_TSHIRT_SIZE_VALID", payload: true});
            };
        };

        // jersey size
        if (contactInfo.br_jerseysizerequired) {
            if (contactInfo.br_jerseysize == "select") {
                dispatchContactInfo({type: "SET_IS_JERSEY_SIZE_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_JERSEY_SIZE_VALID", payload: true});
            };
        };

        // visa details
        if (contactInfo.br_visaflag) {
            if ((contactInfo.br_visanumber || "").trim() == "") {
                dispatchContactInfo({type: "SET_IS_TRAVEL_VISA_NUMBER_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_TRAVEL_VISA_NUMBER_VALID", payload: true});
            };

            if (
                !contactInfo.isVisaPhotoUploaded
                && document.getElementById("visa")
                && document.getElementById("visa").files.length == 0
            ) {
                isValid = false;
            };
        };


        if (!contactInfo.b_signedWaiverReceived) {
            dispatchContactInfo({type: "SET_IS_WAIVER_RECEIVED_VALID", payload: false});
            isValid = false;
        } else {
            dispatchContactInfo({type: "SET_IS_WAIVER_RECEIVED_VALID", payload: true});
        };

        window.scroll({behavior: "smooth", top: 0})
        return isValid;
    };

    const areArrivalAndDepartureDetailsValid_v2 = () => {

        let isValid = true;

        if ((contactInfo.br_hotelnamenightpriortotrip || "").trim() == "") {
            dispatchContactInfo({type: "SET_IS_HOTEL_NAME_PRIOR_TO_TRIP_VALID", payload: false});
            isValid = false;
        };

        // flight deteails
        if (contactInfo.br_flightsflag) {

            // arrival
            if ((contactInfo.br_flightdateofarrival || "") == "") {
                dispatchContactInfo({type: "SET_IS_FLIGHT_ARRIVAL_DATE_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_FLIGHT_ARRIVAL_DATE_VALID", payload: true});
            };

            if ((contactInfo.br_flightarrivaloncarrier || "") == "") {
                dispatchContactInfo({type: "SET_IS_FLIGHT_ARRIVAL_CARRIER_VALID", payload: false})
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_FLIGHT_ARRIVAL_CARRIER_VALID", payload: true})
            }

            if ((contactInfo.br_flightarrivalflightnumber || "") == "") {
                dispatchContactInfo({type: "SET_IS_FLIGHT_ARRIVAL_FLIGHT_NO_VALID", payload: false})
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_FLIGHT_ARRIVAL_FLIGHT_NO_VALID", payload: true})
            }

            if ((contactInfo.br_flightarrivaltime || "") == "") {
                dispatchContactInfo({type: "SET_IS_FLIGHT_ARRIVAL_TIME_VALID", payload: false})
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_FLIGHT_ARRIVAL_TIME_VALID", payload: true})
            }

            // departure
            if ((contactInfo.br_flightdateofdeparture || "") == "") {
                dispatchContactInfo({type: "SET_IS_FLIGHT_DEPARTURE_DATE_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_FLIGHT_DEPARTURE_DATE_VALID", payload: true});
            };

            if ((contactInfo.br_flightdepartureoncarrier || "") == "") {
                dispatchContactInfo({type: "SET_IS_FLIGHT_DEPARTURE_CARRIER_VALID", payload: false})
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_FLIGHT_DEPARTURE_CARRIER_VALID", payload: true})
            }

            if ((contactInfo.br_flightdepartureflightnumber || "") == "") {
                dispatchContactInfo({type: "SET_IS_FLIGHT_DEPARTURE_FLIGHT_NO_VALID", payload: false})
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_FLIGHT_DEPARTURE_FLIGHT_NO_VALID", payload: true})
            }

            if ((contactInfo.br_flightdeparturetime || "") == "") {
                dispatchContactInfo({type: "SET_IS_FLIGHT_DEPARTURE_TIME_VALID", payload: false})
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_FLIGHT_DEPARTURE_TIME_VALID", payload: true})
            }
        };

        // train details
        if (contactInfo.br_trainsflag) {

            // arrival
            if ((contactInfo.br_trainarrivaldate || "") == "") {
                dispatchContactInfo({type: "SET_IS_TRAIN_ARRIVAL_DATE_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_TRAIN_ARRIVAL_DATE_VALID", payload: true});
            };

            if ((contactInfo.br_trainarrivaltime || "") == "") {
                dispatchContactInfo({type: "SET_IS_TRAIN_ARRIVAL_TIME_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_TRAIN_ARRIVAL_TIME_VALID", payload: true});
            };

            if ((contactInfo.br_arrivaltrainnumber || "").trim() == "") {
                dispatchContactInfo({type: "SET_IS_TRAIN_ARRIVAL_TRAIN_NO_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_TRAIN_ARRIVAL_TRAIN_NO_VALID", payload: true});
            };

            if ((contactInfo.br_arrivaltrainstation || "").trim() == "") {
                dispatchContactInfo({type: "SET_IS_TRAIN_ARRIVAL_STATION_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_TRAIN_ARRIVAL_STATION_VALID", payload: true});
            };

            // departure
            if ((contactInfo.br_traindeparturedate || "") == "") {
                dispatchContactInfo({type: "SET_IS_TRAIN_DEPARTURE_DATE_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_TRAIN_DEPARTURE_DATE_VALID", payload: true});
            };

            if ((contactInfo.br_traindeparturetime || "") == "") {
                dispatchContactInfo({type: "SET_IS_TRAIN_DEPARTURE_TIME_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_TRAIN_DEPARTURE_TIME_VALID", payload: true});
            };

            if ((contactInfo.br_departuretrainnumber || "").trim() == "") {
                dispatchContactInfo({type: "SET_IS_TRAIN_DEPARTURE_TRAIN_NO_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_TRAIN_DEPARTURE_TRAIN_NO_VALID", payload: true});
            };

            if ((contactInfo.br_departuretrainstation || "").trim() == "") {
                dispatchContactInfo({type: "SET_IS_TRAIN_DEPARTURE_STATION_VALID", payload: false});
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_TRAIN_DEPARTURE_STATION_VALID", payload: true});
            };
        };

        return isValid;
    };

    const arePaymentAndInsuranceDetailsValid_v2 = () => {

        let isValid = true;

        /*
        NOTE:
            Only run this code block if there's a Payment & Insurance section
            being displayed.
        */
        if (
            Object.keys(contactInfo.invoice).length > 0
            && (contactInfo.bookingID || "") != ""
        ) {
            if (contactInfo.br_insurancestatus == "select") {
                dispatchContactInfo({type: "SET_IS_INSURANCE_STATUS_VALID", payload: false})
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_INSURANCE_STATUS_VALID", payload: true})
            };

            /*
            NOTE:
                Only makes sense to validate the terms and conditions if the
                traveller is able to see the invoice
            */
            if (
                contactInfo.invoice.invoiceLines
                && !contactInfo.br_agreedtotermsandconditions
            ) {
                dispatchContactInfo({type: "SET_IS_AGREED_TO_TERMS_AND_CONDITIONS_VALID", payload: false})
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_AGREED_TO_TERMS_AND_CONDITIONS_VALID", payload: true})
            }

            if (contactInfo.br_finalpaymentmethod == "select") {
                dispatchContactInfo({type: "SET_IS_FINAL_PAYMENT_METHOD_VALID", payload: false})
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_FINAL_PAYMENT_METHOD_VALID", payload: true})
            }

            if (contactInfo.br_finalpaymentmethod != "select" && !contactInfo.br_authorizedfinalpayment) {
                dispatchContactInfo({type: "SET_IS_AGREED_TO_AUTHORIZE_PAYMENT_VALID", payload: false})
                isValid = false;
            } else {
                dispatchContactInfo({type: "SET_IS_AGREED_TO_AUTHORIZE_PAYMENT_VALID", payload: true})
            }


            // // Run if credit card option is available and applicable
            // if (
            //     contactInfo.br_offercreditcardpaymentoption
            //     && contactInfo.br_finalpaymentmethod == "100000000"
            //     && contactInfo.p15_creditcardid == "select"
            // ) {
            //     console.log("4");
            //     dispatchContactInfo({type: "SET_IS_SELECTED_CREDIT_CARD_VALID", payload: false})
            //     isValid = false;
            // } else {
            //     dispatchContactInfo({type: "SET_IS_SELECTED_CREDIT_CARD_VALID", payload: true})
            // }
        };

        return isValid;
    };

    const isFormComplete = () => {

        let isComplete = true;
        if (
            !areTravelerProfileDetailsValid_v2()
            || !areContactDetailsValid_v2()
            || !areGearDetailsValid_v2()
            || !arePaymentAndInsuranceDetailsValid_v2()
            || !areArrivalAndDepartureDetailsValid_v2()
        ) {
            isComplete = false;
        };

        return isComplete;
    };

    const displayInvoiceNote = (data) => {
        if (
            Object.keys(data.invoice).length == 0
            && data.getUpcomingTrip[0].bookingID.trim().length > 0
        ) {
            updateAlertTitle("Missing Invoice");
            updateAlertMessage(`Your invoice is being generated shortly, please check back later.`);
            updateIsAlertOpen(true);
            updateAlertButtons([
                {
                    id: 0,
                    name: "Ok",
                    action: () => updateIsAlertOpen(false),
                    color: "primary",
                    variant: "contained",
                }
            ]);
        }
    };

    const displayFormCompletionMessage = () => {
        updateAlertTitle("Congratulations");
        updateAlertMessage(`
            You're all set!
            Thanks for providing us with the information we needed.
        `);
        updateIsAlertOpen(true);
        updateAlertButtons([
            {
                id: 0,
                name: "Take Me To Home Page",
                action: () => {
                    props.history.push("/home");
                    updateIsAlertOpen(false)
                },
                color: "primary",
                variant: "contained",
            }
        ]);
    };

    const validatePaymentAndInsurance = () => {

        if (arePaymentAndInsuranceDetailsValid_v2()) {
            addSectionToCompletedSections("Payment & Insurance");
            if (isFormComplete()) {
                displayFormCompletionMessage();
            };
        } else {
            if (contactInfo.completedSections.has("Payment & Insurance")){
                removeSectionFromCompletedSections("Payment & Insurance");
            };
        };

        incrementActiveStepAndCurrentlyExpanded();
    };

    const validateArrivalAndDeparture = () => {
        if (areArrivalAndDepartureDetailsValid_v2()) {
            addSectionToCompletedSections("Arrival & Departure");
            if (isFormComplete()) {
                displayFormCompletionMessage();
            };
        } else {
            if (contactInfo.completedSections.has("Arrival & Departure")){
                removeSectionFromCompletedSections("Arrival & Departure");
            };
        };

        incrementActiveStepAndCurrentlyExpanded();
    };

    const validateGear = () => {
        if (areGearDetailsValid_v2()) {
            addSectionToCompletedSections("Gear");
            if (isFormComplete()) {
                displayFormCompletionMessage();
            };
        } else {
            if (contactInfo.completedSections.has("Gear")){
                removeSectionFromCompletedSections("Gear");
            };
        };

        incrementActiveStepAndCurrentlyExpanded();
    };

    const validateTravelerProfile = () => {
        if (areTravelerProfileDetailsValid_v2()) {
            addSectionToCompletedSections("Traveller Profile");
            if (isFormComplete()) {
                displayFormCompletionMessage();
            };
        } else {
            if (contactInfo.completedSections.has("Traveller Profile")){
                removeSectionFromCompletedSections("Traveller Profile");
            };
        };

        incrementActiveStepAndCurrentlyExpanded();
    };

    const validateOtherInformationAndSpecialRequests = () => {
        if (isFormComplete()) {
            displayFormCompletionMessage();
        };

        incrementActiveStepAndCurrentlyExpanded();
    };

    // ERRORS
    const displayUserConfirmedPassportInfoIsCorrectError = () => {
        if (!contactInfo.isTravellingWithPassportOnRecordValid) {
            return (
                <div>
                    <br></br>
                    <Typography variant="caption" display="block" gutterBottom color="error">
                        Please agree to verify passport information is correct
                    </Typography>
                </div>
            )
        }
    }

    const displayUserConfirmedContactInfoIsCorrectError = () => {
        if (!contactInfo.isBrConfirmedValidContactInfoValid) {
            return (
                <div>
                    <br></br>
                    <Typography variant="caption" display="block" gutterBottom color="error">
                        Please agree that the contact information is correct
                    </Typography>
                </div>
            )
        }
    }

    const displayPassportError = () => {
        if ((contactInfo.p15_passportexpires || "") == "") return;
        if (
            moment(urlParams["endDate"]).add(6, 'M')
            .isAfter(moment(contactInfo.p15_passportexpires))
        ) {
            return (
                <Typography variant="caption" display="block" gutterBottom color="error">
                    Your passport is not valid for 6 months after the end of your trip.
                    Please update it and enter the new information once in your possession.
                </Typography>
            )
        }
    }

    const displayInsuranceOptionError = () => {
        if (!contactInfo.isInsuranceStatusValid) {
            return (
                <div>
                    <br></br>
                    <Typography variant="subtitle2" display="block" component="i" gutterBottom color="error">
                        Please select an option
                    </Typography>
                </div>
            )
        }
    }

    const displayUserAgreedTermsAndConditionsError = () => {
        if (!contactInfo.isAgreedToTermsAndConditionsValid) {
            return (
                <div>
                    <br></br>
                    <Typography variant="subtitle2" display="block" component="i" gutterBottom color="error">
                        Please agree to the Terms and Conditions
                    </Typography>
                </div>
            )
        }
    }

    const displayUserAgreedToAuthorizePaymentError = () => {
        if (!contactInfo.isAgreedToAuthorizePaymentValid) {
            return (
                <div>
                    <br></br>
                    <Typography variant="subtitle2" display="block" component="i" gutterBottom color="error">
                        Please agree to authorize final payment
                    </Typography>
                </div>
            )
        }
    }

    const displayDateError = (prop) => {
        if (prop) {
            return <FormHelperText style={{color: "red"}}>Please select a date</FormHelperText>;
        }
    }

    const displayVaccinationStatusError = () => {
        if (!contactInfo.isVaccinationStatusValid) {
            return <FormHelperText style={{color: "red"}}>Please select a vaccination status</FormHelperText>;
        }
    }

    const displayTimeError = (prop) => {
        if (prop) {
            return <FormHelperText style={{color: "red"}}>Please select a time</FormHelperText>;
        }
    }

    const displayHelmetError = () => {
        if (!contactInfo.isSelectedHelmetValid) {
            return <FormHelperText style={{color: "red"}}>Please select a helmet</FormHelperText>;
        }
    }

    const displayMirrorError = () => {
        if (!contactInfo.isSelectedMirrorValid) {
            return <FormHelperText style={{color: "red"}}>Please select a mirror</FormHelperText>;
        }
    }

    const displayGolfClubPreferenceError = () => {
        if (!contactInfo.isSelectedGolfClubPreferenceValid) {
            return <FormHelperText style={{color: "red"}}>Please select a golf club preference</FormHelperText>;
        }
    }

    const displayPedalError = () => {
        if (!contactInfo.isSelectedPedalValid) {
            return <FormHelperText style={{color: "red"}}>Please select a bike pedal</FormHelperText>;
        }
    }

    const displaySeatCoverError = () => {
        if (!contactInfo.isSelectedSeatCoverValid) {
            return <FormHelperText style={{color: "red"}}>Please select a seat cover</FormHelperText>;
        }
    }

    const displayBikeError = () => {
        if (!contactInfo.isSelectedBikeValid) {
            return <FormHelperText style={{color: "red"}}>Please select a bike</FormHelperText>;
        }
    }

    const displayWaiverError = () => {
        if (!contactInfo.isWaiverReceived) {
            return <FormHelperText style={{color: "red"}}>Please sign your waiver</FormHelperText>;
        }
    };

    // DISPLAYING
    const displayAddCreditCardButton = () => {
        if (
            contactInfo.showAddCreditCardButton
            && (!contactInfo.br_authorizedfinalpayment && !contactInfo.lockAuthorizePaymentCheckbox)
        ) {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => dispatchContactInfo({type: "SET_SHOW_CREDIT_CARD_SECTION", payload: true})}
                    fullWidth={true}>Add new credit card
                </Button>
            )
        }
    };

    const displayCreditCardForm = () => {
        if (contactInfo.showCreditCardSection) {
            return (
                <FormControl
                    fullWidth={true}
                    disabled={contactInfo.b_updatesLocked || contactInfo.br_authorizedfinalpayment}
                >
                    <TextField
                        disabled={contactInfo.b_updatesLocked}
                        className={classes.textField}
                        id="nameOnCard"
                        label="Name on card"
                        margin="normal"
                        value={contactInfo.nameOnCard}
                        error={contactInfo.showNameOnCardError}
                        helperText={contactInfo.nameOnCardError}
                        onChange={(event) => dispatchContactInfo({
                            type: "SET_NAME_ON_CARD",
                            payload: event.target.value
                        })}
                    />
                    <br></br>
                    <label>
                        <CardElement
                            options={{
                                hidePostalCode: true,
                                style: {
                                    base: {
                                        fontSize: '16px',
                                        color: '#424770',
                                        '::placeholder': {
                                            color: '#aab7c4',
                                        },
                                    },
                                    invalid: {
                                        color: '#9e2146',
                                    },
                                },
                            }}
                        />
                    </label>
                    <br></br>
                    <br></br>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={handleCreditCardSubmission}
                        className={classes.button}>Save credit card
                    </Button>
                </FormControl>
            );
        };
    };

    const displayShippingAddress = () => {
        if (!contactInfo.sameAsHomeAddress) {
            return (
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth={true} >
                            <TextField
                                disabled={contactInfo.b_updatesLocked}
                                className={classes.textField}
                                id="shippingAddress"
                                label="Address"
                                margin="normal"
                                value={contactInfo.address2_line1}
                                error={!contactInfo.isAddress2Line1Valid}
                                onChange={(event) => dispatchContactInfo({
                                    type: "SET_SHIPPING_ADRESS_1",
                                    payload: event.target.value
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth={true} >
                            <TextField
                                disabled={contactInfo.b_updatesLocked}
                                className={classes.textField}
                                id="shippingAddress2"
                                label="Address 2"
                                margin="normal"
                                value={contactInfo.address2_line2}
                                onChange={(event) => dispatchContactInfo({
                                    type: "SET_SHIPPING_ADRESS_2",
                                    payload: event.target.value
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth={true} >
                            <TextField
                                disabled={contactInfo.b_updatesLocked}
                                className={classes.textField}
                                id="shippingCity"
                                label="City"
                                margin="normal"
                                value={contactInfo.address2_city}
                                error={!contactInfo.isAddress2CityValid}
                                onChange={(event) => dispatchContactInfo({
                                    type: "SET_SHIPPING_CITY",
                                    payload: event.target.value
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth={true} >
                            <TextField
                                disabled={contactInfo.b_updatesLocked}
                                className={classes.textField}
                                id="shippingState"
                                label="State/Province"
                                margin="normal"
                                value={contactInfo.address2_stateorprovince}
                                onChange={(event) => dispatchContactInfo({
                                    type: "SET_SHIPPING_STATE",
                                    payload: event.target.value
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth={true} >
                            <TextField
                                disabled={contactInfo.b_updatesLocked}
                                className={classes.textField}
                                id="shippingZip"
                                label="Zip/Postal Code"
                                margin="normal"
                                value={contactInfo.address2_postalcode}
                                error={!contactInfo.isAddress2PostalCodeValid}
                                onChange={(event) => dispatchContactInfo({
                                    type: "SET_SHIPPING_ZIP",
                                    payload: event.target.value
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth={true} >
                            <TextField
                                disabled={contactInfo.b_updatesLocked}
                                className={classes.textField}
                                id="shippingCountry"
                                label="Country"
                                margin="normal"
                                value={contactInfo.address2_country}
                                error={!contactInfo.isAddress2CountryValid}
                                onChange={(event) => dispatchContactInfo({
                                    type: "SET_SHIPPING_COUNTRY",
                                    payload: event.target.value
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth={true} >
                            <TextField
                                disabled={contactInfo.b_updatesLocked}
                                className={classes.textField}
                                id="address2_name"
                                label="Address Comment"
                                margin="normal"
                                value={contactInfo.address2_name}
                                onChange={(event) => dispatchContactInfo({
                                    type: "SET_ADDRESS_2_NAME",
                                    payload: event.target.value
                                })}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            )
        }
    }

    const displaySecondEmergencyContact = () => {
        if (contactInfo.addSecondEmergencyContact) {
            return (
                <>
                <Grid container spacing={4}>
                    <Grid item xs={12} className={classes.subHeader}>
                        <Typography variant="subtitle1" component="h4">
                            Second Emergency Contact
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth={true} >
                            <TextField
                                disabled={contactInfo.b_updatesLocked}
                                className={classes.textField}
                                id="secondaryFullName"
                                label="Full Name"
                                margin="normal"
                                value={contactInfo.p15_emergency2name}
                                error={contactInfo.showSecondaryEmergencyFullNameError}
                                helperText={contactInfo.secondaryEmergencyFullNameError}
                                onChange={(event) => dispatchContactInfo({
                                    type: "SET_SECONDARY_EMERGENCY_FULL_NAME",
                                    payload:event.target.value
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth={true} >
                            <TextField
                                disabled={contactInfo.b_updatesLocked}
                                className={classes.textField}
                                id="secondaryRelationship"
                                label="Relationship"
                                margin="normal"
                                value={contactInfo.p15_emergency2relationship}
                                error={contactInfo.showSecondaryEmergencyRelationshipError}
                                helperText={contactInfo.secondaryEmergencyRelationshipError}
                                onChange={(event) => dispatchContactInfo({
                                    type: "SET_SECONDARY_EMERGENCY_RELATIONSHIP",
                                    payload:event.target.value
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth={true} >
                            <TextField
                                disabled={contactInfo.b_updatesLocked}
                                className={classes.textField}
                                id="secondaryEmail"
                                label="Email"
                                margin="normal"
                                type="email"
                                value={contactInfo.p15_emergency2email}
                                error={contactInfo.showSecondaryEmergencyEmailError}
                                helperText={contactInfo.secondaryEmergencyEmailError}
                                onChange={(event) => dispatchContactInfo({
                                    type: "SET_SECONDARY_EMERGENCY_EMAIL",
                                    payload:event.target.value
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth={true} >
                            <TextField
                                disabled={contactInfo.b_updatesLocked}
                                className={classes.textField}
                                id="emergencyMobile"
                                label="Mobile Phone"
                                margin="normal"
                                value={contactInfo.p15_emergency2mobilephone}
                                error={contactInfo.showSecondaryEmergencyMobilePhoneError}
                                helperText={contactInfo.secondaryEmergencyMobilePhoneError}
                                onChange={(event) => dispatchContactInfo({
                                    type: "SET_SECONDARY_EMERGENCY_MOBILE_PHONE",
                                    payload:event.target.value
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth={true} >
                            <TextField
                                disabled={contactInfo.b_updatesLocked}
                                className={classes.textField}
                                id="secondaryHomePhone"
                                label="Home Phone"
                                margin="normal"
                                value={contactInfo.p15_emergency2homephone}
                                onChange={(event) => dispatchContactInfo({
                                    type: "SET_SECONDARY_EMERGENCY_HOME_PHONE",
                                    payload:event.target.value
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth={true} >
                            <TextField
                                disabled={contactInfo.b_updatesLocked}
                                className={classes.textField}
                                id="work"
                                label="Work Phone"
                                margin="normal"
                                value={contactInfo.p15_emergency2workphone}
                                onChange={(event) => dispatchContactInfo({
                                    type: "SET_SECONDARY_EMERGENCY_WORK_PHONE",
                                    payload:event.target.value
                                })}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <br></br>
                <br></br>
                </>
            )
        }
    }

    const displayBikeJersey = () => {
        if (contactInfo.br_jerseysizerequired) {
            return (
                <Grid item xs={12} sm={6}>
                    <FormControl
                        fullWidth={true}
                        disabled={contactInfo.b_updatesLocked}
                        error={!contactInfo.isJerseySizeValid}
                    >
                        <CustomSelect
                            options={contactInfo.jerseySizes}
                            inputLabelId="inputLabelJerseySize"
                            handleChange={(event) => dispatchContactInfo({
                                type: "SET_SELECTED_BIKE_JERSEY_SIZE",
                                payload: event.target.value
                            })}
                            labelName="Bike Jersey Size"
                            value={contactInfo.br_jerseysize}
                            selectId="selectJerseySize"
                            selectLabelId="selectLabelJerseySize"
                            error={!contactInfo.isJerseySizeValid}
                        >
                            &nbsp;&nbsp;
                            <Link onClick={() => openUrl("https://www.butterfield.com/wp-content/uploads/2019/06/BR-Jersey-Size-Chart.png")}>Chart</Link>
                        </CustomSelect>
                    </FormControl>
                </Grid>
            )
        }
    }

    const displayFlipperSize = () => {
        if (contactInfo.br_tifflippersize) {
            return (
                <Grid item xs={12} sm={6}>
                    <FormControl
                        fullWidth={true}
                        disabled={contactInfo.b_updatesLocked}
                        error={!contactInfo.isFlipperSizeValid}
                    >
                        <CustomSelect
                            options={contactInfo.flipperSizes}
                            inputLabelId="inputLabelFlipperSize"
                            handleChange={(event) => dispatchContactInfo({
                                type: "SET_SELECTED_FLIPPER_SIZE",
                                payload: event.target.value
                            })}
                            labelName="Flipper Size"
                            value={contactInfo.br_flippersize}
                            selectId="selectFlipperSize"
                            selectLabelId="selectLabelFlipperSize"
                            error={!contactInfo.isFlipperSizeValid}
                        />
                    </FormControl>
                </Grid>
            )
        }
    }

    const displayShoeSize = () => {
        if (contactInfo.br_tifshoesize) {
            return (
                <Grid item xs={12} sm={6}>
                    <FormControl
                        fullWidth={true}
                        disabled={contactInfo.b_updatesLocked}
                        error={!contactInfo.isShoeSizeValid}
                    >
                        <CustomSelect
                            options={contactInfo.shoeSizes}
                            inputLabelId="inputLabelShoeSize"
                            handleChange={(event) => dispatchContactInfo({
                                type: "SET_SELECTED_SHOE_SIZE",
                                payload: event.target.value
                            })}
                            labelName="Shoe Size"
                            value={contactInfo.br_shoesize}
                            selectId="selectShoeSize"
                            selectLabelId="selectLabelShoeSize"
                            error={!contactInfo.isShoeSizeValid}
                        />
                    </FormControl>
                </Grid>
            )
        }
    }

    const displayTshirtSize = () => {
        if (contactInfo.br_tiftshirtrequired) {
            return (
                <Grid item xs={12} sm={6}>
                    <FormControl
                        fullWidth={true}
                        disabled={contactInfo.b_updatesLocked}
                        error={!contactInfo.isTShirtSizeValid}
                    >
                        <CustomSelect
                            options={contactInfo.tShirtSizes}
                            inputLabelId="inputLabelTshirtSize"
                            handleChange={(event) => dispatchContactInfo({
                                type: "SET_SELECTED_TSHIRT_SIZE",
                                payload: event.target.value
                            })}
                            labelName="T-Shirt Size"
                            value={contactInfo.br_tshirtsize}
                            selectId="selectTshirtSize"
                            selectLabelId="selectLabelTshirtSize"
                            error={!contactInfo.isTShirtSizeValid}
                        />
                    </FormControl>
                </Grid>
            )
        }
    }

    const displayHeight = () => {
        if (contactInfo.br_tifheightrequired) {
            return (
                <React.Fragment>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth={true} >
                            <TextField
                                disabled={contactInfo.b_updatesLocked}
                                className={classes.textField}
                                id="heightOne"
                                label="Height (ft.)"
                                margin="normal"
                                type="number"
                                value={contactInfo.br_height_ft}
                                error={!contactInfo.isHeightFeetValid}
                                onChange={(event) => dispatchContactInfo({
                                    type: "SET_HEIGHT_FT",
                                    payload: event.target.value
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth={true} >
                            <TextField
                                disabled={contactInfo.b_updatesLocked}
                                className={classes.textField}
                                id="heightTwo"
                                label="Height (in.)"
                                margin="normal"
                                type="number"
                                value={contactInfo.br_height_in}
                                error={!contactInfo.isHeightInchesValid}
                                onChange={(event) => dispatchContactInfo({
                                    type: "SET_HEIGHT_INCHES",
                                    payload: event.target.value
                                })}
                            />
                        </FormControl>
                    </Grid>
                </React.Fragment>
            )
        }
    }

    const displayWeight = () => {
        if (contactInfo.br_tifweight) {
            return (
                <React.Fragment>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth={true}>
                            <TextField
                                disabled={contactInfo.b_updatesLocked}
                                className={classes.textField}
                                id="weight"
                                label="Weight (lbs)"
                                margin="normal"
                                type="number"
                                value={contactInfo.br_weight}
                                error={!contactInfo.isWeightValid}
                                onChange={(event) => dispatchContactInfo({
                                    type: "SET_WEIGHT",
                                    payload: event.target.value
                                })}
                            />
                        </FormControl>
                    </Grid>
                </React.Fragment>
            )
        }
    }

    const displayWetSuit = () => {

        if (contactInfo.br_tifwetsuitsize) {
            return (
                <React.Fragment>
                    <Grid item xs={12} sm={6}>
                        <FormControl
                            fullWidth={true}
                            disabled={contactInfo.b_updatesLocked}
                            error={!contactInfo.isWetSuitSizeValid}
                        >
                            <CustomSelect
                                options={contactInfo.wetsuitSizes}
                                inputLabelId="inputLabelWetsuit"
                                handleChange={(event) => dispatchContactInfo({
                                    type: "SET_SELECTED_WETSUIT",
                                    payload: event.target.value
                                })}
                                labelName="Wet Suit"
                                value={contactInfo.br_wetsuitsize}
                                selectId="selectWetsuit"
                                selectLabelId="selectLabelWetsuit"
                                error={!contactInfo.isWetSuitSizeValid}
                            />
                        </FormControl>
                    </Grid>
                </React.Fragment>
            )
        }
    }

    const displayExtras = () => {
        return contactInfo.gear.map((gearItem, index) => {
            if (gearItem.displayType == "dropdown") {
                return (
                    <Grid item xs={12} sm={4} key={index}>
                        {buildGearExtras(gearItem)}
                    </Grid>
                )
            }
        })
    };

    const displayPassport = () => {
        if (contactInfo.br_tifpassportflag) {
            return (
                <React.Fragment>
                    <Grid container spacing={4}>
                        <Grid item xs={12} className={classes.subHeader}>
                            <Typography variant="subtitle1" component="h4">
                                Passport
                            </Typography>
                            {displayPassportError()}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth={true} >
                                <TextField
                                    disabled={contactInfo.b_updatesLocked}
                                    className={classes.textField}
                                    id="nameOnPassport"
                                    label="Name on Passport"
                                    margin="normal"
                                    value={contactInfo.p15_nameonpassport}
                                    error={!contactInfo.isNameOnPassportValid}
                                    onChange={(event) => dispatchContactInfo({
                                        type: "SET_NAME_ON_PASSPORT",
                                        payload: event.target.value
                                    })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth={true} >
                                <TextField
                                    disabled={contactInfo.b_updatesLocked}
                                    className={classes.textField}
                                    id="passportNumber"
                                    label="Passport Number"
                                    margin="normal"
                                    value={contactInfo.p15_passportnumber}
                                    error={!contactInfo.isPassportNumberValid}
                                    onChange={(event) => dispatchContactInfo({
                                        type: "SET_PASSPORT_NUMBER",
                                        payload: event.target.value
                                    })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth={true} >
                                <TextField
                                    disabled={contactInfo.b_updatesLocked}
                                    className={classes.textField}
                                    id="placeOfIssue"
                                    label="Place of Issue"
                                    margin="normal"
                                    value={contactInfo.p15_placeofissue}
                                    onChange={(event) => dispatchContactInfo({
                                        type: "SET_PLACE_OF_ISSUE",
                                        payload: event.target.value
                                    })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth={true}>
                                <KeyboardDatePicker
                                    disabled={contactInfo.b_updatesLocked}
                                    className={classes.datePicker}
                                    format="MM/DD/YYYY"
                                    margin="normal"
                                    placeholder="MM/DD/YYYY"
                                    id="passportIssueDate"
                                    label="Issue Date"
                                    value={parseDate(contactInfo.p15_passportissued)}
                                    onChange={(date) => dispatchContactInfo({
                                        type: "SET_ISSUE_DATE",
                                        payload: formatDate(date)
                                    })}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                {displayDateError(!contactInfo.isPassportIssueDateValid)}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth={true}>
                                <KeyboardDatePicker
                                    disabled={contactInfo.b_updatesLocked}
                                    className={classes.datePicker}
                                    format="MM/DD/YYYY"
                                    margin="normal"
                                    placeholder="MM/DD/YYYY"
                                    id="issueDate"
                                    label="Expiration Date"
                                    value={parseDate(contactInfo.p15_passportexpires)}
                                    onChange={(date) => dispatchContactInfo({
                                        type: "SET_EXPIRY_DATE",
                                        payload: formatDate(date)
                                    })}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                {displayDateError(!contactInfo.isPassportExpiryDateValid)}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth={true} >
                                <TextField
                                    disabled={contactInfo.b_updatesLocked}
                                    className={classes.textField}
                                    id="placeOfBirth"
                                    label="Place of Birth"
                                    margin="normal"
                                    value={contactInfo.p15_placeofbirth}
                                    onChange={(event) => dispatchContactInfo({
                                        type: "SET_PLACE_OF_BIRTH",
                                        payload: event.target.value
                                    })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth={true} >
                                <TextField
                                    disabled={contactInfo.b_updatesLocked}
                                    className={classes.textField}
                                    id="nationality"
                                    label="Nationality"
                                    margin="normal"
                                    value={contactInfo.p15_passportnationality}
                                    error={!contactInfo.isPassportNationalityValid}
                                    onChange={(event) => dispatchContactInfo({
                                        type: "SET_NATIONALITY",
                                        payload: event.target.value
                                    })}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br></br><br></br>

                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            {displayUserConfirmedPassportInfoIsCorrectError()}
                            <FormControlLabel
                                disabled={contactInfo.b_updatesLocked}
                                control={
                                    <Checkbox
                                        required={true}
                                        checked={contactInfo.br_travellingwithpassportonrecord}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_IS_PASSPORT_CONFIRMATION_CHECKED",
                                            payload: event.target.checked
                                        })}
                                        value="primary"
                                    />
                                }
                                label="I confirm that the passport information above is correct"
                            />
                        </Grid>
                        <br></br>
                        <br></br>

                        {displayUploadPassportSection()}
                    </Grid>
                    <br></br><br></br>
                </React.Fragment>
            )
        }
    };

    const displayUploadPassportSection = () => {
        if (contactInfo.br_passportuploadflag) {
            return (
                <Grid item xs={12}>
                    <input
                        accept="image/*"
                        id="passport"
                        type="file"
                        style={{display: 'none'}}
                        onChange={(e) => {
                            document.getElementById("passportUpload").innerHTML = "&nbsp;&nbsp;&nbsp;" + e.target.files[0].name
                        }}
                    />
                    <label htmlFor="passport">
                        <Button
                            disabled={contactInfo.b_updatesLocked}
                            variant="contained"
                            color="default"
                            component="span"
                            startIcon={<CloudUploadIcon />}
                            size="small">Upload
                        </Button>
                        <Typography id="passportUpload" variant="caption" display="inline" gutterBottom></Typography>
                    </label>
                    <br></br><br></br>
                    {displayImageSizeNote()}
                    {
                        !contactInfo.isPassportPhotoUploaded
                        ? <Typography variant="caption" display="block" gutterBottom color="error">
                            Please take a photo or scan your passport and upload it.
                          </Typography>
                        : <Typography
                            variant="caption"
                            component="i"
                          >Thank you, your passport photo was received.
                          </Typography>
                    }
                </Grid>
            )
        };
    };

    const displayVisa = () => {
        if (contactInfo.br_visaflag) {
            return (
                <React.Fragment>
                    <Grid container spacing={4}>
                        <Grid item xs={12} className={classes.subHeader}>
                            <Typography variant="subtitle1" component="h4">
                                Travel Visa
                            </Typography>
                            <Typography variant="caption" display="block" gutterBottom>
                                Please note that a travel visa is required for this trip.
                                It is the travellers responsibility to ensure that a they
                                have a valid visa prior to departing, however if you require
                                any assistance with this process please contact your Travel
                                Coordinator and we’ll be happy to assist.
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth={true} >
                                <TextField
                                    disabled={contactInfo.b_updatesLocked}
                                    className={classes.textField}
                                    id="visaNumber"
                                    label="Number"
                                    margin="normal"
                                    value={contactInfo.br_visanumber}
                                    error={!contactInfo.isTravelVisaNumberValid}
                                    onChange={(event) => dispatchContactInfo({
                                        type: "SET_VISA_NUMBER",
                                        payload: event.target.value
                                    })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth={true} error={contactInfo.showVisaIssueDateError}>
                                <KeyboardDatePicker
                                    disabled={contactInfo.b_updatesLocked}
                                    className={classes.datePicker}
                                    format="MM/DD/YYYY"
                                    margin="normal"
                                    placeholder="MM/DD/YYYY"
                                    id="visaIssueDate"
                                    label="Issue Date"
                                    value={parseDate(contactInfo.br_visavaliditydate)}
                                    onChange={(date) => dispatchContactInfo({
                                        type: "SET_VISA_ISSUE_DATE",
                                        payload: formatDate(date)
                                    })}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                {displayDateError(contactInfo.showVisaIssueDateError)}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth={true} error={contactInfo.showVisaExpiryDateError}>
                                <KeyboardDatePicker
                                    disabled={contactInfo.b_updatesLocked}
                                    className={classes.datePicker}
                                    format="MM/DD/YYYY"
                                    margin="normal"
                                    placeholder="MM/DD/YYYY"
                                    id="visaExpireDate"
                                    label="Expiration Date"
                                    value={parseDate(contactInfo.br_visadateofexpiry)}
                                    onChange={(date) => dispatchContactInfo({
                                        type: "SET_VISA_EXPIRY_DATE",
                                        payload: formatDate(date)
                                    })}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                {displayDateError(contactInfo.showVisaExpiryDateError)}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <input
                                accept="image/*"
                                id="visa"
                                type="file"
                                style={{display: 'none'}}
                                onChange={(e) => {
                                    document.getElementById("visaUpload").innerHTML = "&nbsp;&nbsp;&nbsp;" + e.target.files[0].name
                                }}
                            />
                            <label htmlFor="visa">
                                <Button
                                    disabled={contactInfo.b_updatesLocked}
                                    variant="contained"
                                    color="default"
                                    component="span"
                                    startIcon={<CloudUploadIcon />}
                                    size="small">Upload
                                </Button>
                                <Typography id="visaUpload" variant="caption" display="inline" gutterBottom></Typography>
                            </label>
                            <br></br><br></br>
                            {displayImageSizeNote()}
                            {
                                !contactInfo.isVisaPhotoUploaded
                                ? <Typography variant="caption" display="block" gutterBottom color="error">
                                    Please take a photo or scan your travel visa and upload it.
                                </Typography>
                                : <Typography
                                    variant="caption"
                                    component="i"
                                   >Thank you, your travel visa photo was received.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                    <br></br><br></br>
                </React.Fragment>
            )
        }
    }

    const displayHotelNamePriorToTrip = () => {
        if (contactInfo.br_tifnightbeforehotelflag) {
            return (
                <FormControl fullWidth={true}>
                    <label style={{color: "rgba(0, 0, 0, 0.54)", lineHeight: 1, fontWeight: 400, fontSize: "1rem"}}>Hotel Name (Night prior to trip)</label>
                    <TextField
                        disabled={contactInfo.b_updatesLocked}
                        className={classes.textField}
                        id="hotelName"
                        margin="normal"
                        error={!contactInfo.isHotelNamePriorToTripValid}
                        value={contactInfo.br_hotelnamenightpriortotrip}
                        onChange={(event) => dispatchContactInfo({
                            type: "SET_HOTEL_NAME",
                            payload: event.target.value
                        })}
                    />
                </FormControl>
            )
        }
    };

    const displayCreditCardAuthorizationCheckbox = () => {
        if (contactInfo.p15_creditcardid != "select") {
            return (
                <Grid item xs={12}>
                    {displayUserAgreedToAuthorizePaymentError()}
                    <FormControlLabel
                        disabled={contactInfo.lockAuthorizePaymentCheckbox || contactInfo.b_updatesLocked}
                        control={
                            <Checkbox
                                checked={contactInfo.br_authorizedfinalpayment}
                                onChange={(e) => dispatchContactInfo({
                                    type: "SET_USER_AGREED_TO_AUTHORIZE_FINAL_PAYMENT",
                                    payload: e.target.checked
                                })}
                            />
                        }
                        label={`
                            I authorize that the remaining balance of $${contactInfo.invoice.p15_balancedue} USD on ${moment(contactInfo.invoice.p15_finalpaymentduedate).format("MMM D, YYYY")}.
                        `}
                    />
                </Grid>
            )
        }
    }

    const displayImageSizeNote = () => {
        return (
            <Typography variant="caption" display="block" gutterBottom>
                Maximum upload image size: 8 MB
            </Typography>
        );
    };

    const displayChequeInformation = () => {

        // 100000002 == Cheque
        if (contactInfo.br_finalpaymentmethod == "100000002") {
            return (
                <React.Fragment>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography component="p" display="block" gutterBottom>
                                Please make your cheque out to "Butterfield & Robinson Inc" and send it to your
                                Travel Coordinator's attention at the address below:
                                <br></br><br></br>
                                Butterfield & Robinson
                                <br></br>
                                70 Bond St., Suite 300
                                <br></br>
                                Toronto, ON, Canada, M5B 1X3
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            {displayUserAgreedToAuthorizePaymentError()}
                            <FormControlLabel
                                disabled={contactInfo.lockAuthorizePaymentCheckbox || contactInfo.b_updatesLocked}
                                control={
                                    <Checkbox
                                        checked={contactInfo.br_authorizedfinalpayment}
                                        onChange={(e) => dispatchContactInfo({
                                            type: "SET_USER_AGREED_TO_AUTHORIZE_FINAL_PAYMENT",
                                            payload: e.target.checked
                                        })}
                                    />
                                }
                                label={`
                                    I will mail a cheque for the remaining balance of $${contactInfo.invoice.p15_balancedue} USD on or before ${moment(contactInfo.invoice.p15_finalpaymentduedate).format("MMM D, YYYY")}.
                                `}
                            />
                        </Grid>
                    </Grid>
                    <br></br><br></br>
                </React.Fragment>
            )
        }
    }

    const displayPaymentInformation = () => {
        return (
            <>
                <Grid container spacing={4}>
                    <Grid item xs={12} className={classes.subHeader}>
                        <Typography variant="subtitle1" component="h4">
                            Final Payment
                        </Typography>

                        <Typography variant="caption" display="block" gutterBottom>
                                ${contactInfo.invoice.p15_balancedue} USD is due on {moment(contactInfo.invoice.p15_finalpaymentduedate).format("MMM D, YYYY")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            fullWidth={true}
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={() => dispatchContactInfo({
                                type: "SET_IS_INVOICE_OPEN",
                                payload: true
                            })}>Show Invoice
                        </Button>

                        <IframeModal
                            fullScreen={true}
                            heading="Invoice"
                            isOpen={contactInfo.isInvoiceOpen}
                            handleClick={(value) => {
                                dispatchContactInfo({
                                    type: "SET_IS_INVOICE_OPEN",
                                    payload: value
                                })
                            }}>
                            {displayInvoice()}
                        </IframeModal>
                    </Grid>

                    <Grid item xs={12}>
                        <Hidden smUp><br></br><br></br></Hidden>
                        {displayUserAgreedTermsAndConditionsError()}
                        <FormControlLabel
                            disabled={contactInfo.b_updatesLocked}
                            control={
                                <Checkbox
                                    checked={contactInfo.br_agreedtotermsandconditions}
                                    onChange={(e) => dispatchContactInfo({
                                        type: "SET_USER_AGREED_TO_TERMS_AND_CONDITIONS_INVOICE",
                                        payload: e.target.checked
                                    })}
                                />
                            }
                            label="I have read, understood and agree to the Terms and Conditions on my Invoice"
                        />
                        <br></br><br></br>

                        <Typography component="p" display="block" gutterBottom>
                            For more information on B&R's standard Terms & Conditions, please click&nbsp;
                            <a
                                target="_blank"
                                href="https://www.butterfield.com/terms-conditions/"
                            >here</a>.
                            Please note that in instances where your trip payment and cancellation terms
                            and condtions are different from what is listed on our website,
                            the information found on your invoice will apply to your specific trip.
                        </Typography>
                    </Grid>
                </Grid>
                <br></br><br></br>

                <Grid container spacing={4}>
                    <Grid item xs={12} className={classes.subHeader}>
                        <Typography variant="subtitle1" component="h4">
                            Final Payment Method
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                            If you wish to pay by any other means or have any issues authorizing final payment,
                            please contact your Travel Coordinator at 1-800-678-1147 or <u>info@butterfield.com</u> to
                            arrange payment.

                            <br></br><br></br>
                            *Once you select and confirm your final payment method in MyB&R please note that you will need to contact your Travel Coordinator before the final payment due date if there are any changes to the desired method of payment originally selected.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth={true}
                            error={!contactInfo.isFinalPaymentMethodValid}
                            disabled={contactInfo.b_updatesLocked || contactInfo.lockAuthorizePaymentCheckbox}
                        >
                            <CustomSelect
                                options={contactInfo.finalPaymentOptions}
                                inputLabelId="inputLabelPaymentMethod"
                                handleChange={(event) => {
                                    dispatchContactInfo({
                                        type: "SET_SELECTED_PAYMENT_METHOD",
                                        payload: event.target.value
                                    });

                                    /*
                                    NOTE:
                                        This step is done because three input fields are referring to one
                                        variable (br_authorizedfinalpayment).

                                        We're setting the br_authorizedfinalpayment to false after every time
                                        the traveler is changing the input field. This will in turn not authorize
                                        the final payment unexpectedly when changing between payment methods.
                                    */
                                    dispatchContactInfo({
                                        type: "SET_USER_AGREED_TO_AUTHORIZE_FINAL_PAYMENT",
                                        payload: false
                                    });
                                }}
                                labelName=""
                                value={contactInfo.br_finalpaymentmethod}
                                selectId="selectPaymentMethod"
                                selectLabelId="selectLabelPaymentMethod"
                                error={!contactInfo.isFinalPaymentMethodValid}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <br></br>

                {displayWireInformation()}
                {displayChequeInformation()}
                {displayCreditCardInformation()}
            </>
        )
    }

    const displayWireInformation = () => {

        // 100000001 == Wire
        if (contactInfo.br_finalpaymentmethod == "100000001") {
            return (
                <React.Fragment>
                    <Grid container spacing={4}>
                        <Grid container spacing={2} item xs={12}>
                            <Grid item xs={12}>
                                <Typography component="p" display="block" gutterBottom>
                                    Please reference your B&R booking number when arranging the transfer
                                    so we may correctly allocate if. (See REF Number below)
                                </Typography>
                            </Grid>

                            <Grid item xs={5} sm={2}>
                                <span><b>Bank:</b></span>
                            </Grid>
                            <Grid item xs={7} sm={10}>
                                <span>HSBC Bank USA NA</span>
                            </Grid>

                            <Grid item xs={5} sm={2}>
                                <span><b>Swift Code:</b></span>
                            </Grid>
                            <Grid item xs={7} sm={10}>
                                <span>MRMDUS33</span>
                            </Grid>

                            <Grid item xs={5} sm={2}>
                                <span><b>Account #:</b></span>
                            </Grid>
                            <Grid item xs={7} sm={10}>
                                <span>104002182</span>
                            </Grid>

                            <Grid item xs={5} sm={2}>
                                <span><b>Address:</b></span>
                            </Grid>
                            <Grid item xs={7} sm={10}>
                                <span>452 Fifth Ave New York NY 10018, USA</span>
                            </Grid>

                            <Grid item xs={5} sm={2}>
                                <span><b>Routing #:</b></span>
                            </Grid>
                            <Grid item xs={7} sm={10}>
                                <span>022000020</span>
                            </Grid>

                            <Grid item xs={5} sm={2}>
                                <span><b>ABA #:</b></span>
                            </Grid>
                            <Grid item xs={7} sm={10}>
                                <span>021001088</span>
                            </Grid>

                            <Grid item xs={5} sm={2}>
                                <span><b>Beneficiary Name:</b></span>
                            </Grid>
                            <Grid item xs={7} sm={10}>
                                <span><i>BUTTERFIELD & ROBINSON INC</i></span>
                            </Grid>

                            <Grid item xs={5} sm={2}>
                                <span><b>REF #:</b></span>
                            </Grid>
                            <Grid item xs={7} sm={10}>
                                {
                                    contactInfo.invoice.p15_bookingnumber
                                    ? <span>{contactInfo.invoice.p15_bookingnumber}</span>
                                    : <span><b>If possible, please use your last name as a reference on your transfer</b></span>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {displayUserAgreedToAuthorizePaymentError()}
                            {
                                Object.keys(contactInfo.invoice).length > 0
                                ? <FormControlLabel
                                    disabled={contactInfo.lockAuthorizePaymentCheckbox || contactInfo.b_updatesLocked}
                                    control={
                                        <Checkbox
                                            checked={contactInfo.br_authorizedfinalpayment}
                                            onChange={(e) => dispatchContactInfo({
                                                type: "SET_USER_AGREED_TO_AUTHORIZE_FINAL_PAYMENT",
                                                payload: e.target.checked
                                            })}
                                        />
                                    }
                                    label={`
                                        I will transfer the remaining balance of $${contactInfo.invoice.p15_balancedue} USD on ${moment(contactInfo.invoice.p15_finalpaymentduedate).format("MMM D, YYYY")}.
                                    `}
                                />
                                : null
                            }
                        </Grid>
                    </Grid>
                    <br></br><br></br>
                </React.Fragment>
            )
        }
    }

    const displayCreditCardInformation = () => {
        if (contactInfo.br_finalpaymentmethod == "100000000") {
            return (
                <React.Fragment>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            {
                                (!contactInfo.br_authorizedfinalpayment && contactInfo.creditCards.length > 1)
                                ? <FormControl
                                    fullWidth={true}
                                    error={contactInfo.showSelectedCreditCardError}
                                    disabled={contactInfo.b_updatesLocked || contactInfo.lockAuthorizePaymentCheckbox}
                                >
                                    <CustomSelect
                                        options={contactInfo.creditCards}
                                        inputLabelId="inputLabelPaymentMethod"
                                        handleChange={(event) => dispatchContactInfo({
                                            type: "SET_SELECTED_CREDIT_CARD",
                                            payload: event.target.value
                                        })}
                                        labelName=""
                                        value={contactInfo.p15_creditcardid}
                                        selectId="selectPaymentMethod"
                                        selectLabelId="selectLabelPaymentMethod"
                                        error={!contactInfo.isFinalPaymentMethodValid}
                                    />
                                </FormControl>
                                : <></>
                            }
                        </Grid>

                        <Grid item xs={12}>
                            {displayAddCreditCardButton()}
                            {displayCreditCardForm()}
                        </Grid>
                        {displayCreditCardAuthorizationCheckbox()}
                    </Grid>
                </React.Fragment>
            )
        }
    }

    const displayInvoice = () => {
        return (
            <React.Fragment>
                <br></br>
                <Container maxWidth="md">

                    {/*Date and Logo*/}
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-end"
                    >
                        <Grid item>
                            <Typography variant="h5" style={{textDecoration: "underline"}}>Invoice</Typography>
                            <br></br>
                            <Typography component="p">{moment(contactInfo.invoice.p15_invoicedate).format("MMM D, YYYY")}</Typography>
                        </Grid>
                        <Grid item>
                            <img src={butterfieldRobinson} height="65" width="160"/>
                        </Grid>
                    </Grid>
                    <br></br>

                    {/*Address and Trip Info*/}
                    <Grid
                        container
                        direction="row"
                        spacing={3}
                    >
                        <Grid item xs={12}>
                            <Typography component="p">
                                <span>{contactInfo.firstname + " " + contactInfo.lastname}</span>
                                <br></br>
                                <span>{contactInfo.address1_line1}</span>
                                <br></br>
                                <span>{contactInfo.address1_city + " " + contactInfo.address1_stateorprovince + " " + contactInfo.address1_postalcode}</span>
                                <br></br>
                                <span>{contactInfo.address1_country}</span>
                            </Typography>
                        </Grid>

                        <Grid
                            container
                            item
                            direction="row"
                            justify="space-between"
                            alignItems="flex-end"
                        >
                            <Grid item>
                                <Typography component="p">
                                    RE: {urlParams["tripName"]} {urlParams["startDate"]} to {urlParams["endDate"]}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography component="p">
                                    Booking #{contactInfo.invoice.p15_bookingnumber}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br></br>


                    {/*Booking cost*/}
                    <Grid
                        container
                        direction="row"
                        spacing={1}
                    >
                        <Grid item xs={4}>
                            <Typography component="p"><b>Booking Cost</b></Typography>
                        </Grid>

                        <Grid item xs={3}>
                            <Typography component="p"><b>Price</b></Typography>
                        </Grid>

                        <Grid item xs={2}>
                            <Typography component="p"><b>QTY</b></Typography>
                        </Grid>

                        <Grid item xs={3}>
                            <Typography component="p" style={{textAlign: "right"}}><b>Amount</b></Typography>
                        </Grid>

                        {
                            contactInfo.invoice.invoiceLines.map((line,index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Grid item xs={4}>
                                            <Typography component="p">{line.p15_name}</Typography>
                                        </Grid>

                                        <Grid item xs={3}>
                                            {
                                                line.p15_rate != null ?
                                                <Typography component="p">{line.p15_rate}</Typography>
                                                : <Typography component="p"></Typography>
                                            }
                                        </Grid>

                                        <Grid item xs={2}>
                                            <Typography component="p">{line.p15_quantity}</Typography>
                                        </Grid>

                                        <Grid item xs={3}>
                                            {
                                                line.p15_amount ?
                                                <Typography component="p" style={{textAlign: "right"}}>{line.p15_amount}</Typography>
                                                : <Typography component="p"></Typography>
                                            }
                                        </Grid>
                                    </React.Fragment>
                                )
                            })
                        }

                        <Grid item xs={9}>
                            <Typography component="p"><b>Subtotal</b></Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography component="p" style={{textAlign: "right"}}><b>${contactInfo.invoice.p15_balancedue}</b></Typography>
                        </Grid>

                        <Grid item xs={9}>
                            <Typography component="p"><b>Total Booking Costs</b></Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography component="p" style={{textAlign: "right"}}><b>${contactInfo.invoice.p15_balancedue} USD</b></Typography>
                        </Grid>

                        <Grid item xs={7}>
                            <Typography component="p"><b>Less Payments & Credits</b></Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography component="p"><b>Date</b></Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography component="p" style={{textAlign: "right"}}><b>Amount</b></Typography>
                        </Grid>

                        {displayInvoicePayments()}

                        <Grid item xs={7}>
                            <Typography component="p"><b>Balance Due</b></Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography component="p">{moment(contactInfo.invoice.p15_finalpaymentduedate).format("MMM D, YYYY")}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography component="p" style={{textAlign: "right"}}><b>${contactInfo.invoice.p15_balancedue} USD</b></Typography>
                        </Grid>
                    </Grid>
                    <br></br>
                    <Divider />

                    <Grid
                        container
                        direction="row"
                        spacing={1}
                    >
                        <Grid item xs={12}>
                            <Typography component="p"><b>Important notes about your booking:</b></Typography>
                        </Grid>
                        <br></br><br></br>

                        <Grid item xs={12}>
                            <Typography component="p"><b>Documentation Requirements:</b></Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <ul>
                                <li>
                                    {
                                        contactInfo.br_countryofentry
                                        ? <Typography component="p">A valid passport is required for entry into {contactInfo.br_countryofentry}</Typography>
                                        : <Typography component="p">A valid passport is required for your destination</Typography>
                                    }
                                </li>
                                <li>
                                    {
                                        contactInfo.br_countryofentry
                                        ? <Typography component="p">Your passport should be valid for at least 6 months AFTER you exit {contactInfo.br_countryofentry}</Typography>
                                        : <Typography component="p">Your passport should be valid for at least 6 months AFTER you exit your destination</Typography>
                                    }

                                </li>
                                <li>
                                    <Typography component="p">If required, procuring a visa is the responsibility of the traveller. Visa requirements differ based on the nationality of the traveller and the region of travel</Typography>
                                </li>
                                <li>
                                    <Typography component="p">For the most up to date information on visa requirements, please refer to <a href="https://cibtvisas.com/visas">https://cibtvisas.com/visas</a>. In addition to reviewing CIBT, you may also contact B&R for further direction.</Typography>
                                </li>
                                <li>
                                    <Typography component="p">In some cases, the traveller's visa will be included in the trip price. In the case that Butterfield & Robinson arranges a visa, the traveller will be alerted by their sales representative</Typography>
                                </li>
                                <li>
                                    <Typography component="p">We recommend obtaining travel and health insurance for all your travels: whether it be out of province and state or out of country. Butterfield & Robinson recommends Cavalry Elite Travel Insurance. To obtain a quote, please connect with your sales representative</Typography>
                                </li>
                                <li>
                                    <Typography component="p">If required, procuring proof of negative COVID-19 Test, recovery from COVID-19 or up-to-date vaccinations is the responsibility of the traveller. These requirements may differ based on the nationality of the traveller and the region of travel. Please check the COVID-19 specific requirements for entry into your destination country(ies) and re-entry to your country of residence prior to departure.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography component="p">B&R will endeavour to communicate the most current COVID-19 protocols and guidelines of the destination country(ies). However, these are subject to change as the global situation evolves. The enforcement of certain health and safety measures (i.e. mask wearing and social distancing) will vary in different regions. B&R will defer to local health authorities for best practices in their region. For a full-list of what to expect when travelling visit <a href="https://www.butterfield.com/what-to-expect-travel-with-br">
                                        https://www.butterfield.com/what-to-expect-travel-with-br/
                                    </a>.
                                    </Typography>
                                </li>
                            </ul>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography component="p">
                                For full details on our Traveller Vaccination Policy and B&R’s guiding principles for travel during COVID-19, please visit our website: <a href="https://butterfield.com/health-and-safety">https://butterfield.com/health-and-safety</a>.
                            </Typography>
                            <br></br>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography component="p"><b>Your Destination:</b></Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <ul>
                                <li>
                                    <Typography component="p">Please be aware that entry into another country may be refused even if the required information and travel documents are complete</Typography>
                                </li>
                                <li>
                                    <Typography component="p">Also note those living standards and practices, and the standards and conditions with respect to the provision of utilities, services and accommodation in your destination country(ies) may differ from those found at home</Typography>
                                </li>
                                <li>
                                    <Typography component="p">If you have any questions regarding these Notes, please don't hesitate to contact one of our Travel Advisors at <a href="tel:1-800-678-1147">1-800-678-1147</a></Typography>
                                </li>
                            </ul>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography component="p"><b>Payment Terms</b></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="p" dangerouslySetInnerHTML={{__html: contactInfo.invoice.p15_descriptionofterms.split("\n").join("<br>")}}></Typography>
                        </Grid>


                        <Grid item xs={12}>
                            <Typography component="p"><b>Cancellation Terms:</b></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="p" dangerouslySetInnerHTML={{__html: contactInfo.invoice.p15_descriptionofcancellationterms.split("\n").join("<br>")}}></Typography>
                        </Grid>


                        <Grid item xs={12}>
                            <Typography component="p">After your final payment is made no price increases are permitted, except for situations where your itinerary design is modified. In the situation where your trip price is increased due itinerary design a revised invoice will be presented as well as a detailed description of the reason for the increase.</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography component="p"><b>Final Payment is due {moment(contactInfo.invoice.p15_finalpaymentduedate).format("MMM D, YYYY")}.</b> Payment can be made by credit card, cheque, or wire.</Typography>
                        </Grid>
                    </Grid>
                    <br></br><br></br>
                </Container>
            </React.Fragment>
        )
    }

    const displayInsuranceSection = () => {
        if ((contactInfo.bookingID || "").trim() != "") {
            return (
                <>
                    <Grid item xs={12}>
                        <Typography component="p" display="block">
                            <b>Insurance should be purchased by the time final payment is made, in order to
                            have pre-existing conditions coverage.</b>&nbsp;
                            B&R recommends Cavalry Elite Travel
                            Insurance, which combines the best evacuation services with premier travel medical
                            insurance coverage into a single integrated program.

                            <br></br><br></br>
                            If you have any questions about Cavalry's policy or your quote, please reach out to &nbsp;
                            <u
                                style={{wordWrap: "break-word"}}
                                onClick={() => window.open("mailTo:butterfield@redpointtravelprotection.com")}>
                            butterfield@redpointtravelprotection.com
                            </u>
                            , or &nbsp;
                            <u onClick={() => window.open("tel:1-650-452-7310")}>+1-650-452-7310</u>.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="subtitle2" display="block" component="i" gutterBottom>
                            Please select one of the following before the final payment due date:
                        </Typography>
                        {displayInsuranceOptionError()}
                        <FormControl component="fieldset" className={classes.formControl} disabled={contactInfo.b_updatesLocked}>
                            <RadioGroup
                                value={contactInfo.br_insurancestatus.toString()}
                                onChange={(event) => {
                                    dispatchContactInfo({
                                        type: "SET_PREFERRED_INSURANCE_OPTION",
                                        payload: event.target.value
                                    });

                                    {/*Remove old comments*/}
                                    if (event.target.value != "3") {
                                        dispatchContactInfo({
                                            type: "SET_INSURANCE_COMMENTS",
                                            payload: ""
                                        })
                                    }
                                }}
                            >
                                <Hidden smUp><br></br></Hidden>
                                <FormControlLabel
                                    value={"2"}
                                    control={<Radio color="primary" />}
                                    label="I will purchase insurance through Cavalry"
                                    labelPlacement="end"
                                />
                                <Hidden smUp><br></br></Hidden>
                                <FormControlLabel
                                    value={"4"}
                                    control={<Radio color="primary" />}
                                    label="I have already purchased insurance through Cavalry"
                                    labelPlacement="end"
                                />
                                <Hidden smUp><br></br></Hidden>
                                <FormControlLabel
                                    value={"3"}
                                    control={<Radio color="primary" />}
                                    label="I decline insurance from Cavalry"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {displayInsuranceComments()}
                    {displayInsuranceOptions()}
                </>
            )
        }
    }

    const displayInsuranceComments = () => {
        if (contactInfo.br_insurancestatus == "3") {
            return (
                <Grid item xs={12}>
                    <FormControl fullWidth={true} >
                        <InputLabel htmlFor="insuranceComments">Please provide your insurance policy info:</InputLabel>
                        <Input
                            disabled={contactInfo.b_updatesLocked}
                            className={classes.textField}
                            multiline={true}
                            rows={3}
                            id="insuranceComments"
                            type="text"
                            value={contactInfo.br_insurancecomments}
                            onChange={(event) => dispatchContactInfo({
                                type: "SET_INSURANCE_COMMENTS",
                                payload: event.target.value
                            })}
                        />
                    </FormControl>
                </Grid>
            )
        }
    };

    const displayInsuranceOptions = () => {
        if (
            contactInfo.br_insurancestatus == "select"
            || contactInfo.br_insurancestatus < 3
        ) {
            return (
                <>
                    <br></br>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => openUrl(contactInfo.invoice.insurancePurchaseURL)}
                            fullWidth={true}>Generate My Insurance Quote
                        </Button>
                    </Grid>
                </>
            )
        };
    };

    const displayPostTripPlans = () => {
        if (contactInfo.br_tifposttriptravelplans) {
            return (
                <FormControl fullWidth={true}>
                    <label style={{color: "rgba(0, 0, 0, 0.54)", lineHeight: 1, fontWeight: 400, fontSize: "1rem"}}>
                        Please indicate any plans you might have booked after your trip, that B&R has not arranged for you
                    </label>
                    <TextField
                        helperText={contactInfo.postTripTravelPlansError}
                        disabled={contactInfo.b_updatesLocked}
                        className={classes.textField}
                        id="plannedTrips"
                        margin="normal"
                        multiline={true}
                        variant="outlined"
                        value={contactInfo.br_posttriptravelplans}
                        onChange={(event) => dispatchContactInfo({
                            type: "SET_CONSUMER_POST_TRIPS",
                            payload: event.target.value
                        })}
                    />
                </FormControl>
            )
        }
    }

    const displayTravelAdvisorContactInfo = () => {
        if (contactInfo.br_offerprepostassistance) {
            return (
                <Typography variant="caption" display="block" component="i">
                    If you would like any help with your post trip travel,
                    please contact a B&R Travel Advisor at 1-800-678-1147.
                </Typography>
            )
        }
    }

    const displayHowWillYouBeArrivingAndDeparting = () => {
        if (contactInfo.br_flightsflag || contactInfo.br_trainsflag) {
            return (
                <>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Grid item xs={12}>
                                <Typography>
                                    How will you be arriving?
                                </Typography>
                            </Grid>
                            <br></br>
                            <TabComponent
                                height={460}
                                value={contactInfo.arrivingTabIndex}
                                handleChange={(event, value) => dispatchContactInfo({
                                    type: "SET_TRIP_ARRIVING_TAB_INDEX",
                                    payload: value
                                })}
                                tabs={contactInfo.arrivalTabs}
                            >
                                { buildTabArray(getArrivingFlightContent, getArrivingTrainContent)}
                            </TabComponent>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <Grid item xs={12}>
                                <Typography>
                                    How will you be leaving?
                                </Typography>
                            </Grid>
                            <br></br>
                            <TabComponent
                                height={460}
                                value={contactInfo.departureTabIndex}
                                handleChange={(event, value) => dispatchContactInfo({
                                    type: "SET_TRIP_DEPARTURE_TAB_INDEX",
                                    payload: value
                                })}
                                tabs={contactInfo.departureTabs}
                            >
                                { buildTabArray(getDepartureFlightContent, getDepartureTrainContent)}
                            </TabComponent>
                        </Grid>
                    </Grid>
                    <br></br><br></br>
                </>
            )
        };
    }

    const displayInvoicePayments = () => {
        if (contactInfo.invoice.payments) {
            return contactInfo.invoice.payments.map((payment, index) => {
                return (
                    <React.Fragment key={index}>
                        <Grid item xs={7}>
                            <Typography component="p">{payment.p15_paymentmethod_displayname}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography component="p">{moment(payment.p15_paymentdate).format("MMM D, YYYY")}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography component="p" style={{textAlign: "right"}}>{payment.p15_amount} USD</Typography>
                        </Grid>
                    </React.Fragment>
                )
            })
        }
    }

    const displayBikes = () => {
        if (contactInfo.gear.filter((gearItem) => gearItem.name == "Bike").length > 0) {
            return (
                <div>
                    <Grid container spacing={4}>
                        <Grid item xs={12} className={classes.subHeader}>
                            <Typography variant="subtitle1" component="h4">
                                Bike
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl disabled={contactInfo.b_updatesLocked}>
                                {displayBikeError()}
                                <RadioGroup
                                    aria-label="position"
                                    name="position"
                                    value={contactInfo.selectedBike}
                                    onChange={(event) => setBike(event.target.value)}
                                >
                                    <Grid container spacing={2}>
                                        {
                                            contactInfo.gear.map((gearItem, index) => {
                                                if (gearItem.displayType == "gallery") {
                                                    return gearItem.options.map((option, index) => {
                                                        return (
                                                            <Grid item xs={12} sm={6} key={index}>
                                                                <Paper elevation={3} style={{height: "100%"}}>
                                                                    <Box justifyContent="center" display="flex">
                                                                        <FormControlLabel
                                                                            labelPlacement="top"
                                                                            value={option.value}
                                                                            control={<Radio color="primary"/>}
                                                                            label={
                                                                                <div style={{textAlign:"center"}}>
                                                                                    <div style={{marginTop: 10}}>
                                                                                        <a
                                                                                            style={{"fontSize":"9pt"}}
                                                                                            target="BIKES"
                                                                                            href="https://www.butterfield.com/our-equipment">More Info
                                                                                        </a>
                                                                                    </div>
                                                                                    <div>{option.name}</div>
                                                                                </div>
                                                                            }
                                                                        />
                                                                    </Box>
                                                                </Paper>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            })
                                        }
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br></br><br></br>
                </div>
            )
        }
    }

    const displaySpeedDial = () => {
        if (
            (contactInfo.staff_fullname || "") != ""
            && (
                ((contactInfo.staff_email || "") != "")
                || ((contactInfo.staff_telephone || "") != "")
            )
        ) {
            return (
                <SpeedDialFloatingButton
                    isOpen={contactInfo.speedDialIsOpen}
                    isHidden={false}
                    direction="up"
                    speedDialIcon={
                        <img
                            src={contactInfo.staff_photo_url}
                            width="55px"
                            height="53px"
                            style={{borderRadius: 28}}
                        />
                    }
                    handleOpen={setSpeedDialOpenStatus}
                    actions={buildActions()}
                />
            )
        }
    }

    // GETTING CONTENT
    const getArrivingFlightContent = () => {
        return (
            <React.Fragment key={0}>
                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <TextField
                            disabled={contactInfo.b_updatesLocked}
                            className={classes.textField}
                            id="arrivingCarrier"
                            label="Carrier"
                            margin="normal"
                            error={!contactInfo.isFlightArrivalCarrierValid}
                            value={contactInfo.br_flightarrivaloncarrier}
                            onChange={(event) => dispatchContactInfo({
                                type: "SET_TRIP_ARRIVAL_CARRIER",
                                payload: event.target.value
                            })}
                        />
                    </FormControl>
                </Grid>
                <br></br>

                <Grid item xs={12}>
                    <FormControl fullWidth={true} >
                        <TextField
                            disabled={contactInfo.b_updatesLocked}
                            className={classes.textField}
                            id="arrivingFlightNo"
                            label="Flight Number"
                            margin="normal"
                            error={!contactInfo.isFlightArrivalNumberValid}
                            value={contactInfo.br_flightarrivalflightnumber}
                            onChange={(event) => dispatchContactInfo({
                                type: "SET_TRIP_ARRIVAL_FLIGHT_NO",
                                payload: event.target.value
                            })}
                        />
                    </FormControl>
                </Grid>
                <br></br>

                <Grid item xs={12}>
                    <FormControl fullWidth={true} disabled={contactInfo.b_updatesLocked}>
                        <CustomSelect
                            options={contactInfo.citiesArriving}
                            inputLabelId="inputLabelArrivingCity"
                            handleChange={(event) => dispatchContactInfo({
                                type: "SET_TRIP_ARRIVAL_CITY",
                                payload: event.target.value
                            })}
                            labelName="City"
                            value={contactInfo.br_flightarrivalcity}
                            selectId="selectArrivingCity"
                            selectLabelId="selectLabelArrivingCity"
                        />
                    </FormControl>
                </Grid>
                <br></br>

                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <KeyboardDatePicker
                            disabled={contactInfo.b_updatesLocked}
                            className={classes.datePicker}
                            format="MM/DD/YYYY"
                            margin="normal"
                            placeholder="MM/DD/YYYY"
                            id="arrivalDate"
                            label="Date"
                            value={parseDate(contactInfo.br_flightdateofarrival)}
                            onChange={(date) => dispatchContactInfo({
                                type: "SET_TRIP_ARRIVAL_DATE",
                                payload: formatDate(date)
                            })}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                        {displayDateError(!contactInfo.isFlightArrivalDateValid)}
                    </FormControl>
                </Grid>
                <br></br>

                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <TimePicker
                            disabled={contactInfo.b_updatesLocked}
                            autoOk
                            label="Time"
                            value={getTime(contactInfo.br_flightarrivaltime)}
                            onChange={(date) => {
                                dispatchContactInfo({
                                    type: "SET_TRIP_ARRIVAL_TIME",
                                    payload: moment(date).format("HH:mm")
                                })
                            }}
                        />
                        {displayTimeError(!contactInfo.isFlightArrivalTimeValid)}
                    </FormControl>
                </Grid>
                <br></br>

            </React.Fragment>
        )
    };

    const getArrivingTrainContent = () => {
        return (
            <React.Fragment key={1}>
                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <TextField
                            disabled={contactInfo.b_updatesLocked}
                            className={classes.textField}
                            id="arrivingTrainCarrier"
                            label="Carrier"
                            margin="normal"
                            value={contactInfo.br_arrivaltraincarrier}
                            onChange={(event) => dispatchContactInfo({
                                type: "SET_TRIP_ARRIVAL_TRAIN_CARRIER",
                                payload: event.target.value
                            })}
                        />
                    </FormControl>
                </Grid>
                <br></br>

                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <TextField
                            disabled={contactInfo.b_updatesLocked}
                            className={classes.textField}
                            id="arrivingTrainNo"
                            label="Train Number"
                            margin="normal"
                            value={contactInfo.br_arrivaltrainnumber}
                            error={!contactInfo.isTrainArrivalNumberValid}
                            onChange={(event) => dispatchContactInfo({
                                type: "SET_TRIP_ARRIVAL_TRAIN_NUMBER",
                                payload: event.target.value
                            })}
                        />
                    </FormControl>
                </Grid>
                <br></br>

                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <TextField
                            disabled={contactInfo.b_updatesLocked}
                            className={classes.textField}
                            id="arrivingTrainStation"
                            label="Station"
                            margin="normal"
                            value={contactInfo.br_arrivaltrainstation}
                            error={!contactInfo.isTrainArrivalStationValid}
                            onChange={(event) => dispatchContactInfo({
                                type: "SET_TRIP_ARRIVAL_TRAIN_STATION",
                                payload: event.target.value
                            })}
                        />
                    </FormControl>
                </Grid>
                <br></br>

                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <KeyboardDatePicker
                            disabled={contactInfo.b_updatesLocked}
                            className={classes.datePicker}
                            format="MM/DD/YYYY"
                            margin="normal"
                            placeholder="MM/DD/YYYY"
                            id="arrivalTrainDate"
                            label="Date"
                            value={parseDate(contactInfo.br_trainarrivaldate)}
                            onChange={(date) => dispatchContactInfo({
                                type: "SET_TRIP_ARRIVAL_TRAIN_DATE",
                                payload: formatDate(date)
                            })}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                        {displayDateError(!contactInfo.isTrainArrivalDateValid)}
                    </FormControl>
                </Grid>
                <br></br>

                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <TimePicker
                            disabled={contactInfo.b_updatesLocked}
                            autoOk
                            label="Time"
                            value={getTime(contactInfo.br_trainarrivaltime)}
                            onChange={(date) => {
                                dispatchContactInfo({
                                    type: "SET_TRIP_ARRIVAL_TRAIN_TIME",
                                    payload: moment(date).format("HH:mm")
                                })
                            }}
                        />
                        {displayTimeError(!contactInfo.isTrainArrivalTimeValid)}
                    </FormControl>
                </Grid>
                <br></br>
            </React.Fragment>
        )
    }

    const getDepartureFlightContent = () => {
        return (
            <React.Fragment key={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <TextField
                            disabled={contactInfo.b_updatesLocked}
                            className={classes.textField}
                            id="departureCarrier"
                            label="Carrier"
                            margin="normal"
                            error={!contactInfo.isFlightDepartureCarrierValid}
                            value={contactInfo.br_flightdepartureoncarrier}
                            onChange={(event) => dispatchContactInfo({
                                type: "SET_TRIP_DEPARTURE_CARRIER",
                                payload: event.target.value
                            })}
                        />
                    </FormControl>
                </Grid>
                <br></br>

                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <TextField
                            disabled={contactInfo.b_updatesLocked}
                            className={classes.textField}
                            id="departureFlightNo"
                            label="Flight Number"
                            margin="normal"
                            error={!contactInfo.isFlightDepartureNumberValid}
                            value={contactInfo.br_flightdepartureflightnumber}
                            onChange={(event) => dispatchContactInfo({
                                type: "SET_TRIP_DEPARTURE_FLIGHT_NO",
                                payload: event.target.value
                            })}
                        />
                    </FormControl>
                </Grid>
                <br></br>

                <Grid>
                    <FormControl fullWidth={true} disabled={contactInfo.b_updatesLocked}>
                        <CustomSelect
                            options={contactInfo.citiesDeparting}
                            inputLabelId="inputLabelDepartureCity"
                            handleChange={(event) => dispatchContactInfo({
                                type: "SET_TRIP_DEPARTURE_CITY",
                                payload: event.target.value
                            })}
                            labelName="City"
                            value={contactInfo.br_flightdeparturecity}
                            selectId="selectDepartureCity"
                            selectLabelId="selectLabelDepartureCity"
                        />
                    </FormControl>
                </Grid>
                <br></br>

                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <KeyboardDatePicker
                            disabled={contactInfo.b_updatesLocked}
                            className={classes.datePicker}
                            format="MM/DD/YYYY"
                            margin="normal"
                            placeholder="MM/DD/YYYY"
                            id="departingFlightDate"
                            label="Date"
                            value={parseDate(contactInfo.br_flightdateofdeparture)}
                            onChange={(date) => dispatchContactInfo({
                                type: "SET_TRIP_DEPARTURE_DATE",
                                payload: formatDate(date)
                            })}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                        {displayDateError(!contactInfo.isFlightDepartureDateValid)}
                    </FormControl>
                </Grid>
                <br></br>

                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <TimePicker
                            disabled={contactInfo.b_updatesLocked}
                            autoOk
                            label="Time"
                            value={getTime(contactInfo.br_flightdeparturetime)}
                            onChange={(date) => {
                                dispatchContactInfo({
                                    type: "SET_TRIP_DEPARTURE_TIME",
                                    payload: moment(date).format("HH:mm")
                                })
                            }}
                        />
                        {displayTimeError(!contactInfo.isFlightDepartureTimeValid)}
                    </FormControl>
                </Grid>
                <br></br>
            </React.Fragment>
        )
    }

    const getDepartureTrainContent = () => {
        return (
            <React.Fragment key={3}>
                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <TextField
                            disabled={contactInfo.b_updatesLocked}
                            className={classes.textField}
                            id="departingTrainCarrier"
                            label="Carrier"
                            margin="normal"
                            value={contactInfo.br_departuretraincarrier}
                            onChange={(event) => dispatchContactInfo({
                                type: "SET_TRIP_DEPARTURE_TRAIN_CARRIER",
                                payload: event.target.value
                            })}
                        />
                    </FormControl>
                </Grid>
                <br></br>

                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <TextField
                            disabled={contactInfo.b_updatesLocked}
                            className={classes.textField}
                            id="departingTrainNo"
                            label="Train Number"
                            margin="normal"
                            value={contactInfo.br_departuretrainnumber}
                            error={!contactInfo.isTrainDepartureNumberValid}
                            onChange={(event) => dispatchContactInfo({
                                type: "SET_TRIP_DEPARTURE_TRAIN_NUMBER",
                                payload: event.target.value
                            })}
                        />
                    </FormControl>
                </Grid>
                <br></br>

                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <TextField
                            disabled={contactInfo.b_updatesLocked}
                            className={classes.textField}
                            id="departingTrainStation"
                            label="Station"
                            margin="normal"
                            value={contactInfo.br_departuretrainstation}
                            error={!contactInfo.isTrainDepartureStationValid}
                            onChange={(event) => dispatchContactInfo({
                                type: "SET_TRIP_DEPARTURE_TRAIN_STATION",
                                payload: event.target.value
                            })}
                        />
                    </FormControl>
                </Grid>
                <br></br>

                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <KeyboardDatePicker
                            disabled={contactInfo.b_updatesLocked}
                            className={classes.datePicker}
                            format="MM/DD/YYYY"
                            margin="normal"
                            placeholder="MM/DD/YYYY"
                            id="departingTrainDate"
                            label="Date"
                            value={parseDate(contactInfo.br_traindeparturedate)}
                            onChange={(date) => dispatchContactInfo({
                                type: "SET_TRIP_DEPARTURE_TRAIN_DATE",
                                payload: formatDate(date)
                            })}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                        {displayDateError(!contactInfo.isTrainDepartureDateValid)}
                    </FormControl>
                </Grid>
                <br></br>

                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <TimePicker
                            disabled={contactInfo.b_updatesLocked}
                            autoOk
                            label="Time"
                            value={getTime(contactInfo.br_traindeparturetime)}
                            onChange={(date) => {
                                dispatchContactInfo({
                                    type: "SET_TRIP_DEPARTURE_TRAIN_TIME",
                                    payload: moment(date).format("HH:mm")
                                })
                            }}
                        />
                        {displayTimeError(!contactInfo.isTrainDepartureTimeValid)}
                    </FormControl>
                </Grid>
                <br></br>
            </React.Fragment>
        )
    }

    const getStepContent = (label) => {
        switch (label) {
            case "Contact Info":
                return (
                    <React.Fragment>
                        {/*NAME SECTION*/}
                        <Grid container spacing={4}>
                            <Grid item xs={12} className={classes.subHeader}>
                                <Typography variant="subtitle1">
                                    Name
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true}>
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="prefix"
                                        label="Preferred salutation"
                                        margin="normal"
                                        value={contactInfo.p15_title}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_PREFIX",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true}>
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="firstName"
                                        label="First Name"
                                        margin="normal"
                                        value={contactInfo.firstname}
                                        error={!contactInfo.isFirstNameValid}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_FIRST_NAME",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="middleName"
                                        label="Middle Name(s)"
                                        margin="normal"
                                        value={contactInfo.middlename}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_MIDDLE_NAME",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="lastName"
                                        label="Last Name"
                                        margin="normal"
                                        value={contactInfo.lastname}
                                        error={!contactInfo.isLastNameValid}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_LAST_NAME",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="preferredName"
                                        label="Preferred Name"
                                        margin="normal"
                                        value={contactInfo.p15_preferredname}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_PREFERRED_NAME",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br></br><br></br>

                        {/*PHONE NUMBER SECTION*/}
                        <Grid container spacing={4}>
                            <Grid item xs={12} className={classes.subHeader}>
                                <Typography variant="subtitle1" component="h4">
                                    Phone Number
                                </Typography>
                                <Typography variant="caption" display="block" component="i" gutterBottom>
                                    Please add at least one number where we can reach you
                                    between 9 AM and 5 PM in your local time zone.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="mobilePhone"
                                        label="Mobile"
                                        margin="normal"
                                        value={contactInfo.mobilephone}
                                        error={!contactInfo.isMobilePhoneValid}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_MOBILE_PHONE",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="homePhone"
                                        label="Home"
                                        margin="normal"
                                        value={contactInfo.telephone2}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_HOME_PHONE",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="workPhone"
                                        label="Business"
                                        margin="normal"
                                        value={contactInfo.telephone1}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_WORK_PHONE",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br></br><br></br>

                        {/*HOME ADDRESS SECTION*/}
                        <Grid container spacing={4}>
                            <Grid item xs={12} className={classes.subHeader}>
                                <Typography variant="subtitle1" component="h4">
                                Home Address
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="address"
                                        label="Address"
                                        margin="normal"
                                        value={contactInfo.address1_line1}
                                        error={!contactInfo.isAddress1Line1Valid}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_ADDRESS_1",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="address2"
                                        label="Address 2"
                                        margin="normal"
                                        value={contactInfo.address1_line2}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_ADDRESS_2",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="city"
                                        label="City"
                                        margin="normal"
                                        value={contactInfo.address1_city}
                                        error={!contactInfo.isAddress1CityValid}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_CITY",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="state"
                                        label="State/Province"
                                        margin="normal"
                                        value={contactInfo.address1_stateorprovince}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_STATE",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="zip"
                                        label="Zip/Postal Code"
                                        margin="normal"
                                        value={contactInfo.address1_postalcode}
                                        error={!contactInfo.isAddress1PostalCodeValid}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_ZIP",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="country"
                                        label="Country"
                                        margin="normal"
                                        value={contactInfo.address1_country}
                                        error={!contactInfo.isAddress1CountryValid}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_COUNTRY",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="address1_name"
                                        label="Address Comment"
                                        margin="normal"
                                        value={contactInfo.address1_name}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_ADDRESS_1_NAME",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br></br><br></br>

                        {/*SHIPPING ADDRESS SECTION*/}
                        <Grid container spacing={4}>
                            <Grid item xs={12} className={classes.subHeader}>
                                <Typography variant="subtitle1" component="h4">
                                    Shipping Address
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    disabled={contactInfo.b_updatesLocked}
                                    control={
                                        <Checkbox
                                            checked={contactInfo.sameAsHomeAddress}
                                            onChange={(event) => {
                                                dispatchContactInfo({
                                                    type: "SET_SAME_AS_HOME_ADRESS",
                                                    payload: event.target.checked
                                                });

                                                // set values to be the same
                                                if (event.target.checked) {
                                                    setShippingAddressAsHomeAddress();
                                                }
                                            }}
                                            value="primary"
                                        />
                                    }
                                    label="Same as home address"
                                />
                            </Grid>
                        </Grid>
                        {displayShippingAddress()}
                        <br></br><br></br>

                        {/*EMERGENCY CONTACT SECTION*/}
                        <Grid container spacing={4}>
                            <Grid item xs={12} className={classes.subHeader}>
                                <Typography variant="subtitle1" component="h4">
                                    Emergency Contact
                                </Typography>
                                <Typography variant="caption" display="block" component="i" gutterBottom>
                                    Someone not travelling with you.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="fullName"
                                        label="Full Name"
                                        margin="normal"
                                        value={contactInfo.p15_emergency1name}
                                        error={!contactInfo.isEmergency1NameValid}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_EMERGENCY_FULL_NAME",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="relationship"
                                        label="Relationship"
                                        margin="normal"
                                        value={contactInfo.p15_emergency1relationship}
                                        error={!contactInfo.isEmergency1RelationshipValid}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_EMERGENCY_RELATIONSHIP",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="email"
                                        label="Email"
                                        margin="normal"
                                        type="email"
                                        value={contactInfo.p15_emergency1email}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_EMERGENCY_EMAIL",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="emergencyMobilePhone"
                                        label="Mobile Phone"
                                        margin="normal"
                                        value={contactInfo.p15_emergency1mobilephone}
                                        error={!contactInfo.isEmergency1MobilePhoneValid}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_EMERGENCY_MOBILE_PHONE",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="emergencyHomePhone"
                                        label="Home Phone"
                                        margin="normal"
                                        value={contactInfo.p15_emergency1homephone}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_EMERGENCY_HOME_PHONE",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="emergencyWorkPhone"
                                        label="Business Phone"
                                        margin="normal"
                                        value={contactInfo.p15_emergency1workphone}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_EMERGENCY_WORK_PHONE",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    disabled={contactInfo.b_updatesLocked}
                                    control={
                                        <Checkbox
                                            checked={contactInfo.addSecondEmergencyContact}
                                            onChange={(event) => {
                                                dispatchContactInfo({
                                                    type: "SET_ADD_SECOND_CONTACT",
                                                    payload: event.target.checked
                                                });
                                                if (!event.target.checked) {
                                                    clearSecondEmergencyContactValues();
                                                }
                                            }}
                                            value="primary"
                                        />
                                    }
                                    label="Add second emergency contact"
                                />
                                {displaySecondEmergencyContact()}

                                <Grid item xs={12}>
                                    {displayUserConfirmedContactInfoIsCorrectError()}
                                    <FormControlLabel
                                        disabled={contactInfo.b_updatesLocked}
                                        control={
                                            <Checkbox
                                            checked={contactInfo.br_confirmedvalidcontactinfo}
                                            onChange={(event) => {
                                                dispatchContactInfo({
                                                    type: "SET_USER_CONFIRMED_CONTACT_INFO",
                                                    payload: event.target.checked
                                                });
                                            }}
                                            value="primary"
                                            />
                                        }
                                        label="I agree that the contact information above is correct"
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                    </React.Fragment>
                )
            case "Traveller Profile":
                return (
                    <React.Fragment>
                        <Grid container spacing={4}>
                            <Grid item xs={12} className={classes.subHeader}>
                                <Typography variant="subtitle1" component="h4">
                                    Waiver
                                </Typography>
                                <Typography variant="caption" display="block" gutterBottom>
                                    Please electronically sign your trip Waiver below.
                                    Please note that we require all travellers to sign and date a Waiver prior to each trip.
                                    We are unable to let you join us on trips without this being completed.
                                    One signed Waiver is required for each B&R trip that you take.
                                    Upon completion, a copy of the Waiver will be e-mailed to you for your records.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    contactInfo.b_signedWaiverReceived
                                    ? <Typography
                                        variant="caption"
                                        component="i">
                                        Thank you, your waiver was received.
                                    </Typography>
                                    : <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        onClick={(e) => {e.preventDefault(); determineDocuSignUrl()}}
                                        fullWidth={true}>Review & Sign Document
                                    </Button>
                                }
                                {displayWaiverError()}
                            </Grid>
                        </Grid>
                        <br></br><br></br>

                        <Grid container spacing={4}>
                            <Grid item xs={12} className={classes.subHeader}>
                                <Typography variant="subtitle1" component="h4">
                                    Vaccination
                                </Typography>
                                <Typography variant="caption" display="block" gutterBottom>
                                    Due to international regulations and restrictions, many elements of your trip will require proof of full vaccination against COVID-19. Therefore, we require B&R travellers to be fully vaccinated at least 14 days prior to departure. This may include booster shots as indicated by regional health authorities. As your vaccination details may have a bearing on trip events and activities, please advise us of your vaccine type and the date of your most recent vaccination below.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth={true}
                                    disabled={contactInfo.b_updatesLocked}
                                    error={!contactInfo.isVaccinationStatusValid}
                                >
                                    <CustomSelect
                                        options={contactInfo.vaccinationStatusOptions}
                                        inputLabelId="inputLabelVaccinationStatusOptions"
                                        handleChange={(event) => dispatchContactInfo({
                                            type: "SET_VACCINATION_STATUS",
                                            payload: event.target.value
                                        })}
                                        labelName="Vaccination Status"
                                        value={contactInfo.br_vaccinationstatus}
                                        selectId="selectVaccinationStatus"
                                        selectLabelId="selectLabelVaccinationStatus"
                                        error={!contactInfo.isSelectedHelmetValid}
                                    />
                                    {displayVaccinationStatusError()}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="vaccinationType"
                                        label="Vaccination Type"
                                        margin="normal"
                                        value={contactInfo.br_vaccinetype}
                                        error={!contactInfo.isVaccineTypeValid}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_VACCINATION_TYPE",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true}>
                                    <KeyboardDatePicker
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.datePicker}
                                        format="MM/DD/YYYY"
                                        margin="normal"
                                        placeholder="MM/DD/YYYY"
                                        id="dateOfLastVaccination"
                                        label="Date Of Last Vaccination"
                                        value={parseDate(contactInfo.br_dateoflastvaccination)}
                                        onChange={(date) => dispatchContactInfo({
                                            type: "SET_DATE_OF_LAST_VACCINATION",
                                            payload: formatDate(date)
                                        })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                    {displayDateError(!contactInfo.isVaccinationDateValid)}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className={classes.subHeader}>
                                <Typography variant="caption" display="block" gutterBottom>
                                    For full details on our Traveller Vaccination Policy and B&R’s guiding principles for travel during COVID-19, please visit our website: <a href="https://butterfield.com/health-and-safety">
                                    https://butterfield.com/health-and-safety</a>. 
                                </Typography>
                            </Grid>
                        </Grid>
                        <br></br><br></br>

                        {/*PERSONAL DETAILS SECTION*/}
                        <Grid container spacing={4}>
                            <Grid item xs={12} className={classes.subHeader}>
                                <Typography variant="subtitle1" component="h4">
                                    Personal Details
                                </Typography>
                            </Grid>

                            {displayHeight()}
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true}>
                                    <KeyboardDatePicker
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.datePicker}
                                        format="MM/DD/YYYY"
                                        margin="normal"
                                        placeholder="MM/DD/YYYY"
                                        id="dateOfBirth"
                                        label="Date Of Birth"
                                        value={parseDate(contactInfo.birthdate)}
                                        onChange={(date) => dispatchContactInfo({
                                            type: "SET_DATE_OF_BIRTH",
                                            payload: formatDate(date)
                                        })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                    {displayDateError(!contactInfo.isBirthDateValid)}
                                </FormControl>
                            </Grid>
                            {displayBikeJersey()}
                            {displayFlipperSize()}
                            {displayShoeSize()}
                            {displayTshirtSize()}
                            {displayWeight()}
                            {displayWetSuit()}
                        </Grid>
                        <br></br><br></br>

                        {/*HEALTH DETAILS SECTION*/}
                        <Grid container spacing={4}>
                            <Grid item xs={12} className={classes.subHeader}>
                                <Typography variant="subtitle1" component="h4">
                                    Health Details
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="allergies"
                                        margin="normal"
                                        multiline={true}
                                        variant="outlined"
                                        label="Allergies"
                                        value={contactInfo.br_allergies}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_ALLERGIES",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="dietaryPreferences"
                                        margin="normal"
                                        multiline={true}
                                        variant="outlined"
                                        label="Dietary Preferences"
                                        value={contactInfo.br_dietarypreferences}
                                        error={contactInfo.showDietaryPreferencesError}
                                        helperText={contactInfo.dietaryPreferencesError}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_DIATARY_PREFERENCES",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth={true} >
                                    <TextField
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        id="medicalConditions"
                                        margin="normal"
                                        multiline={true}
                                        variant="outlined"
                                        label="Medical conditions*"
                                        value={contactInfo.br_medicalconditions}
                                        error={contactInfo.showMedicalConditionsError}
                                        helperText={contactInfo.medicalConditionsError}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_MEDICAL_CONDITIONS",
                                            payload: event.target.value
                                        })}
                                    />
                                    <Typography variant="caption" display="block" gutterBottom>
                                        *Condition or physical, mental or emotional limitations that could impair your ability to enjoy all aspects of the trip
                                        <br></br>
                                    </Typography>
                                </FormControl>

                                <br></br><br></br>
                                <Typography variant="caption" display="block" gutterBottom>
                                    We encourage you to discuss the trip and the associated activities with your
                                    doctor to ensure your are fully able to enjoy all aspects of the trip.
                                    <br></br><br></br>
                                </Typography>

                                <Typography variant="caption" display="block" gutterBottom>
                                    Providing us with information about your health and abilities will assist in supporting
                                    our on-trip safety protocols and may enable us to modify your trip, where appropriate and
                                    possible, to ensure it is suitable for your enjoyment. All personal information provided by you
                                    will be held and used in compliance with our policy governing personal information.
                                    <br></br>
                                    The policy may be reviewed here:
                                    <a
                                        target="_blank"
                                        href="https://www.butterfield.com/privacy-statement/">https://www.butterfield.com/privacy-statement/
                                    </a>.
                                    <br></br><br></br>
                                </Typography>

                                <Typography variant="caption" display="block" gutterBottom>
                                    If you would like more information about B&R's safety protocols, please be in touch.
                                </Typography>
                            </Grid>
                        </Grid>
                        <br></br><br></br>

                        {/*PASSPORT SECTION*/}
                        {displayPassport()}

                        {/*VISA SECTION*/}
                        { displayVisa() }
                        <br></br><br></br>
                    </React.Fragment>
                )
            case "Gear":
                return (
                    <React.Fragment>
                        {displayBikes()}

                        <Grid container spacing={4}>
                            <Grid item xs={12} className={classes.subHeader}>
                                <Typography variant="subtitle1" component="h4">
                                    Extras
                                </Typography>
                            </Grid>
                            {displayExtras()}
                        </Grid>
                        <br></br><br></br>
                    </React.Fragment>
                )
            case "Arrival & Departure":
                return (
                    <React.Fragment>
                        {/*ARRIVAL AND DEPARTURE*/}
                        <Grid container spacing={4}>
                            <Grid item xs={12} className={classes.subHeader}>
                                <Typography variant="subtitle1">
                                    Your trip rendezvous: {contactInfo.br_pickuplocation}
                                </Typography>
                                <Hidden smUp><br></br></Hidden>
                                <Typography variant="caption" display="block" component="i" gutterBottom>
                                    Please see below if a reservation has been made for you by B&R.
                                    If you have made your own reservation, please indicate the details.
                                    If you would like to request a reservation from B&R,
                                    please contact your Travel Advisor at 1-800-678-1147.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {displayHotelNamePriorToTrip()}
                            </Grid>
                            <Grid item xs={12}>
                                {displayPostTripPlans()}
                                {displayTravelAdvisorContactInfo()}
                            </Grid>
                        </Grid>
                        <br></br><br></br>

                        {displayHowWillYouBeArrivingAndDeparting()}
                    </React.Fragment>
                )
            case "Payment & Insurance":
                return (
                    <React.Fragment>
                        {/*PAYMENT AND INSURANCE*/}

                        {displayPaymentInformation()}
                        <br></br><br></br>

                        <Grid container spacing={4}>
                            <Grid item xs={12} className={classes.subHeader}>
                                <Typography variant="subtitle1" component="h4">
                                    Insurance
                                </Typography>
                            </Grid>

                            {displayInsuranceSection()}
                        </Grid>
                        <br></br><br></br>
                    </React.Fragment>
                )
            case "Other Information & Special Requests":
                return (
                    <React.Fragment>
                        {/*OTHER INFORMATION*/}
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <FormControl fullWidth={true} >
                                    <InputLabel htmlFor="travelerComments">Information you wish to communicate to B&R:</InputLabel>
                                    <Input
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        multiline={true}
                                        rows={3}
                                        id="travelerComments"
                                        type="text"
                                        value={contactInfo.br_travellercomments}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_TRAVELER_COMMENTS",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="caption" display="block" gutterBottom>
                                    Please let us know if you have any special requests for this trip.
                                    We will do our best to accommodate each request.
                                </Typography>
                                <FormControl fullWidth={true}>
                                    <InputLabel htmlFor="taskDescription">Description</InputLabel>
                                    <Input
                                        disabled={contactInfo.b_updatesLocked}
                                        className={classes.textField}
                                        multiline={true}
                                        rows={3}
                                        id="taskDescription"
                                        type="text"
                                        value={contactInfo.taskDescription}
                                        onChange={(event) => dispatchContactInfo({
                                            type: "SET_TASK_DESCRIPTION",
                                            payload: event.target.value
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br></br><br></br>
                    </React.Fragment>
                )
            default:
                return 'Unknown step';
        }
    };

    const getUserDetails = () => {

        return new Promise((resolve, reject) => {

            updateIsLoading(true);
            let newHeaders = {...HEADERS, cancelToken: source.token};

            // Sending concurrent requests
            axios.all([
                axios.get(`${process.env.REACT_APP_API_ENDPOINT}/v1/getAnnotationStatuses/guests/${urlParams["guestId"]}`, newHeaders),
                axios.get(`${process.env.REACT_APP_API_ENDPOINT}/v1/getContact`, newHeaders),
                axios.get(`${process.env.REACT_APP_API_ENDPOINT}/v1/getCreditCards`, newHeaders),
                axios.get(`${process.env.REACT_APP_API_ENDPOINT}/v1/getDeparture/${urlParams["tripDepartureId"]}`, newHeaders),
                axios.get(`${process.env.REACT_APP_API_ENDPOINT}/v1/getGear/guests/${urlParams["guestId"]}`, newHeaders),
                axios.get(`${process.env.REACT_APP_API_ENDPOINT}/v1/getGuest/${urlParams["guestId"]}`, newHeaders),
                axios.get(`${process.env.REACT_APP_API_ENDPOINT}/v1/getTcDetails/guests/${urlParams["guestId"]}`, newHeaders),
                axios.get(`${process.env.REACT_APP_API_ENDPOINT}/v1/getUpcomingTrips/departures/${urlParams["tripDepartureId"]}`, newHeaders),
                axios.get(`${process.env.REACT_APP_API_ENDPOINT}/v1/getPaymentInsurance/guests/${urlParams["guestId"]}`, newHeaders),
                axios.get(`${process.env.REACT_APP_API_ENDPOINT}/v1/getFlightCities`, newHeaders),
                axios.get(`${process.env.REACT_APP_API_ENDPOINT}/v1/getSizes`, newHeaders)
            ])
            .then(axios.spread((...results) => {
                updateIsLoading(false);

                /* NOTE:
                Manually adding the objects into one object since Object.assign()
                overrides the objects
                */
                let mergedResults = {};
                let finalResults = {};
                for (let x in [...results]) {
                    mergedResults[x] = results[x]
                    for (let y in results[x].data) {
                        finalResults[y] = results[x].data[y]
                    }
                }

                // Check if any of the api responses have a 222 status code
                for (let result in mergedResults) {
                    if (
                        mergedResults[result].status == 222
                        && mergedResults[result].data.sessionTimeout
                    ) {
                        return logOutUser(props)
                    }
                }

                return resolve(finalResults);
            }))
            .catch(err => {console.log(err);reject(err)});
        });
    };

    const getDirtyProps = (obj) => {

        let dirtyProps = {};

        // 'YYYY-MM-DDTHH:MM:SS.000Z' or 'YYYY/MM/DD', MM/DD/YYYY, DD/MM/YYYY
        let dateRegex = /\d+-\d+-\d+T\d+:\d+:\d+.\d+Z|\d+\/\d+\/\d+/g;

        for (let key in obj) {
            if (typeof contactInfo[key] == 'string' && contactInfo[key].match(dateRegex) != null) {
                if (moment(contactInfo[key]).format("YYYY/MM/DD") != moment(contactInfo.originalState[key]).format("YYYY/MM/DD")) {
                    dirtyProps[key] = obj[key];
                }
            } else if (obj[key] instanceof Object) {
                if (JSON.stringify(contactInfo.originalState[key]) != JSON.stringify(obj[key])) {
                    dirtyProps[key] = obj[key];
                }
            } else if (contactInfo.originalState[key] != obj[key]) {
                dirtyProps[key] = obj[key];
            }
        };

        return dirtyProps;
    }

    const getApplicableGearItems = () => {
        /*
        NOTE:
            Since the gear array is dynamic for every trip we have to check if
            the gear item exists before we proceed with any other action such as
            validating or sending changed properties to the API.
        */

        let gearObj = {};

        for (let item in contactInfo.gear) {
            let gearItem = contactInfo.gear[item];
            if (gearItem.name == "Bike") gearObj['Bike'] = true;
            if (gearItem.name == "Helmet") gearObj['Helmet'] = true;
            if (gearItem.name == "Bike Pedals") gearObj['Pedals'] = true;
            if (gearItem.name == "Gel seat cover") gearObj['Cover'] = true;
            if (gearItem.name == "Mirror") gearObj['Mirror'] = true;
            if (gearItem.name == "Golf Club Preference") gearObj["GolfClubPreference"] = true;
        }

        return gearObj;
    }

    const getApplicableContactInfoProperties = () => {

        let obj = {
            firstname: contactInfo.firstname,
            lastname: contactInfo.lastname,
            middlename: contactInfo.middlename,
            p15_preferredname: contactInfo.p15_preferredname,
            mobilephone: contactInfo.mobilephone,
            telephone1: contactInfo.telephone1,
            telephone2: contactInfo.telephone2,
            address1_line1: contactInfo.address1_line1,
            address1_line2: contactInfo.address1_line2,
            address1_city: contactInfo.address1_city,
            address1_stateorprovince: contactInfo.address1_stateorprovince,
            address1_postalcode: contactInfo.address1_postalcode,
            address1_country: contactInfo.address1_country,
            address1_name: contactInfo.address1_name,
            address2_line1: contactInfo.address2_line1,
            address2_line2: contactInfo.address2_line2,
            address2_city: contactInfo.address2_city,
            address2_country: contactInfo.address2_country,
            address2_name: contactInfo.address2_name,
            address2_postalcode: contactInfo.address2_postalcode,
            address2_stateorprovince: contactInfo.address2_stateorprovince,
            p15_emergency1name: contactInfo.p15_emergency1name,
            p15_emergency1relationship: contactInfo.p15_emergency1relationship,
            p15_emergency1workphone: contactInfo.p15_emergency1workphone,
            p15_emergency1homephone: contactInfo.p15_emergency1homephone,
            p15_emergency1mobilephone: contactInfo.p15_emergency1mobilephone,
            p15_emergency1email: contactInfo.p15_emergency1email,
            p15_emergency2name: contactInfo.p15_emergency2name,
            p15_emergency2relationship: contactInfo.p15_emergency2relationship,
            p15_emergency2workphone: contactInfo.p15_emergency2workphone,
            p15_emergency2homephone: contactInfo.p15_emergency2homephone,
            p15_emergency2mobilephone: contactInfo.p15_emergency2mobilephone,
            p15_emergency2email: contactInfo.p15_emergency2email
        };

        if (contactInfo.p15_title != "") obj["p15_title"] = contactInfo.p15_title

        return obj;
    }

    const getApplicableTravellerProfileProperties = () => {
        /*
        NOTE:
            Returns object of all the key:values for the traveler profile section
        */

        let contactObj = {
            birthdate: contactInfo.birthdate,
            br_allergies: contactInfo.br_allergies,
            br_dietarypreferences: contactInfo.br_dietarypreferences,
            br_medicalconditions: contactInfo.br_medicalconditions,
            p15_passportexpires: contactInfo.p15_passportexpires,
            p15_passportissued: contactInfo.p15_passportissued,
            p15_passportnationality: contactInfo.p15_passportnationality,
            p15_passportnumber: contactInfo.p15_passportnumber,
            p15_placeofbirth: contactInfo.p15_placeofbirth,
            p15_placeofissue: contactInfo.p15_placeofissue,
            birthdate: contactInfo.birthdate,
            p15_passportissued: contactInfo.p15_passportissued,
            p15_passportexpires: contactInfo.p15_passportexpires,
            p15_nameonpassport: contactInfo.p15_nameonpassport
        };

        if (contactInfo.br_tifweight) contactObj["br_weight"] = contactInfo.br_weight
        if (contactInfo.br_tifheightrequired) {
            contactObj["br_height_ft"] = contactInfo.br_height_ft || 0;
            contactObj["br_height_in"] = contactInfo.br_height_in || 0;
        };

        if (contactInfo.br_tifflippersize && contactInfo.br_flippersize != "select") {
             contactObj["br_flippersize"] = contactInfo.br_flippersize;
        };
        if (contactInfo.br_tifshoesize && contactInfo.br_shoesize != "select") {
             contactObj["br_shoesize"] = contactInfo.br_shoesize;
        };
        if (contactInfo.br_tiftshirtrequired && contactInfo.br_tshirtsize != "select") {
             contactObj["br_tshirtsize"] = contactInfo.br_tshirtsize;
        };
        if (contactInfo.br_jerseysizerequired && contactInfo.br_jerseysize != "select") {
             contactObj["br_jerseysize"] = contactInfo.br_jerseysize;
        };
        if (contactInfo.br_tifwetsuitsize && contactInfo.br_wetsuitsize != "select") {
             contactObj["br_wetsuitsize"] = contactInfo.br_wetsuitsize;
        };

        let guestObj = {
            br_travellingwithpassportonrecord: contactInfo.br_travellingwithpassportonrecord,
            br_vaccinetype: contactInfo.br_vaccinetype,
            br_vaccinationstatus: contactInfo.br_vaccinationstatus,
            br_dateoflastvaccination: contactInfo.br_dateoflastvaccination
        };
        if (contactInfo.br_visaflag) {
            guestObj["br_visanumber"] = contactInfo.br_visanumber;
            guestObj["br_visadateofexpiry"] = contactInfo.br_visadateofexpiry;
            guestObj["br_visavaliditydate"] = contactInfo.br_visavaliditydate;
        };

        return {contactObj, guestObj};

    };

    const getApplicableArrivalAndDepartureProperties = () => {

        let obj = {
            br_hotelnamenightpriortotrip: contactInfo.br_hotelnamenightpriortotrip,
            br_flightdateofarrival: contactInfo.br_flightdateofarrival,
            br_flightdateofdeparture: contactInfo.br_flightdateofdeparture,
            br_traindeparturedate: contactInfo.br_traindeparturedate,
            br_trainarrivaldate: contactInfo.br_trainarrivaldate
        };

        if (contactInfo.br_tifposttriptravelplans) obj["br_posttriptravelplans"] = contactInfo.br_posttriptravelplans;

        if (contactInfo.br_trainsflag) {
            obj["br_departuretraincarrier"] = contactInfo.br_departuretraincarrier;
            obj["br_departuretrainnumber"] = contactInfo.br_departuretrainnumber;
            obj["br_departuretrainstation"] = contactInfo.br_departuretrainstation;
            obj["br_traindeparturedate"] = contactInfo.br_traindeparturedate;
            obj["br_traindeparturetime"] = contactInfo.br_traindeparturetime;
            obj["br_arrivaltraincarrier"] = contactInfo.br_arrivaltraincarrier;
            obj["br_arrivaltrainnumber"] = contactInfo.br_arrivaltrainnumber;
            obj["br_arrivaltrainstation"] = contactInfo.br_arrivaltrainstation;
            obj["br_trainarrivaldate"] = contactInfo.br_trainarrivaldate;
            obj["br_trainarrivaltime"] = contactInfo.br_trainarrivaltime;
        }

        if (contactInfo.br_flightsflag) {
            obj["br_flightarrivaloncarrier"] = contactInfo.br_flightarrivaloncarrier;
            obj["br_flightarrivalflightnumber"] = contactInfo.br_flightarrivalflightnumber;
            if (contactInfo.br_flightarrivalcity != "select") obj["br_flightarrivalcity"] = contactInfo.br_flightarrivalcity;
            obj["br_flightdateofarrival"] = contactInfo.br_flightdateofarrival;
            obj["br_flightarrivaltime"] = contactInfo.br_flightarrivaltime;
            obj["br_flightdepartureoncarrier"] = contactInfo.br_flightdepartureoncarrier;
            obj["br_flightdepartureflightnumber"] = contactInfo.br_flightdepartureflightnumber;
            if (contactInfo.br_flightdeparturecity != "select") obj["br_flightdeparturecity"] = contactInfo.br_flightdeparturecity;
            obj["br_flightdateofdeparture"] = contactInfo.br_flightdateofdeparture;
            obj["br_flightdeparturetime"] = contactInfo.br_flightdeparturetime;
        }

        return obj;
    }

    const getApplicablePaymentAndInsuranceProperties = () => {

        let obj = {
            br_agreedtotermsandconditions: contactInfo.br_agreedtotermsandconditions
        };

        /*
        NOTE:
            Only add credit card/wire or cheque and authorization if the user is
            willing to authorize the payment.

        WARNING:
            In P15 we cannot have the payment method set to credit card/wire/cheque
            and the autorization set to false. Having it set will indicate the
            payment section as completed and will mislead the traveler.
        */
        if (
            contactInfo.br_authorizedfinalpayment
            && [100000000, 100000001, 100000002].includes(contactInfo.br_finalpaymentmethod)
        ) {
            // Credit Card, Wire or Cheque
            obj["br_finalpaymentmethod"] = contactInfo.br_finalpaymentmethod;
            obj["br_authorizedfinalpayment"] = contactInfo.br_authorizedfinalpayment;

            // Add selected credit card if not select
            if (contactInfo.br_finalpaymentmethod == "100000000" && contactInfo.p15_creditcardid != "select") {
                obj["p15_creditcardid"] = contactInfo.p15_creditcardid;
            };
        };


        /*
        NOTE:
            Only include insurance status and comments if there is a successful
            quote generated for the user.
        */
        if ((contactInfo.bookingID || "").trim() != "") {
            obj["br_insurancestatus"] = contactInfo.br_insurancestatus;
            obj["br_insurancecomments"] = contactInfo.br_insurancecomments;
        };

        return obj;
    }

    const getDocuSignUrl = (url, callback) => {
        updateIsLoading(true)

        axios.get(url)
        .then(result => {
            updateIsLoading(false)
            if (result.status != 200) {
                updateAlertTitle("Error")
                updateAlertMessage(result.data.error)
                updateIsAlertOpen(true)
            } else {
                return callback(result.data.url)
            }
        })
        .catch(err => {
            console.log(err);
            handleErrorMessage(err);
            throw err;
        })
    }

    // SET CONTENT
    const setShippingAddressAsHomeAddress = () => {
        dispatchContactInfo({type: "SET_SHIPPING_ADRESS_1", payload: contactInfo.address1_line1})
        dispatchContactInfo({type: "SET_SHIPPING_ADRESS_2", payload: contactInfo.address1_line2})
        dispatchContactInfo({type: "SET_SHIPPING_CITY", payload: contactInfo.address1_city})
        dispatchContactInfo({type: "SET_SHIPPING_ZIP", payload: contactInfo.address1_postalcode})
        dispatchContactInfo({type: "SET_SHIPPING_STATE", payload: contactInfo.address1_stateorprovince})
        dispatchContactInfo({type: "SET_SHIPPING_COUNTRY", payload: contactInfo.address1_country})
    };

    const setStateValues = (result) => {

        // SET ACTIVE STEP
        if (urlParams["activeStep"]) {
            let activeStep = parseInt(urlParams["activeStep"]);
            dispatchContactInfo({type: "SET_ACTIVE_STEP", payload: activeStep});
            dispatchContactInfo({type: "SET_CURRENTLY_EXPANDED", payload: activeStep});
        } else {
            // set to default and adjust url to prevent bugs
            dispatchContactInfo({type: "SET_ACTIVE_STEP", payload: 0})
            dispatchContactInfo({type: "SET_CURRENTLY_EXPANDED", payload: 0});

            let url = window.location.toString();
            url += "&activeStep=0";
            window.location = url;
        }

        if (result.wetsuitSizes) dispatchContactInfo({type: "SET_WETSUIT_SIZES", payload: result.wetsuitSizes});

        if (result.flipperSizes) dispatchContactInfo({type: "SET_FLIPPER_SIZES", payload: result.flipperSizes});

        if (result.tshirtSizes) dispatchContactInfo({type: "SET_T_SHIRT_SIZES", payload: result.tshirtSizes});

        if (result.jerseySizes) dispatchContactInfo({type: "SET_JERSEY_SIZES", payload: result.jerseySizes});

        if (result.arrivalCities) dispatchContactInfo({type: "SET_ARRIVAL_CITIES", payload: result.arrivalCities});

        if (result.departureCities) dispatchContactInfo({type: "SET_DEPARTURE_CITIES", payload: result.departureCities});

        // SET STAFF DETAILS
        if (result.staff_fullname) dispatchContactInfo({type: "SET_STAFF_FULL_NAME", payload: result.staff_fullname});
        if (result.staff_email) dispatchContactInfo({type: "SET_STAFF_EMAIL", payload: result.staff_email});
        if (result.staff_telephone) dispatchContactInfo({type: "SET_STAFF_PHONE", payload: result.staff_telephone});
        if (result.staff_photo_url) dispatchContactInfo({type: "SET_STAFF_PHOTO_URL", payload: result.staff_photo_url});

        // SET NAME VALUES
        if (result.firstname) dispatchContactInfo({type: "SET_FIRST_NAME", payload: result.firstname});
        if (result.p15_title) dispatchContactInfo({type: "SET_PREFIX", payload: result.p15_title});
        if (result.lastname) dispatchContactInfo({type: "SET_LAST_NAME", payload: result.lastname})
        if (result.middlename) dispatchContactInfo({type: "SET_MIDDLE_NAME", payload: result.middlename})
        if (result.p15_preferredname) dispatchContactInfo({type: "SET_PREFERRED_NAME", payload: result.p15_preferredname})

        // SET PHONE NUMBERS AND ADDRESS VALUES
        if (result.mobilephone) dispatchContactInfo({type: "SET_MOBILE_PHONE", payload: result.mobilephone})
        if (result.telephone2) dispatchContactInfo({type: "SET_HOME_PHONE", payload: result.telephone2})
        if (result.telephone1) dispatchContactInfo({type: "SET_WORK_PHONE", payload: result.telephone1})
        if (result.address1_line1 != null) dispatchContactInfo({type: "SET_ADDRESS_1", payload: result.address1_line1})
        if (result.address1_line2 != null) dispatchContactInfo({type: "SET_ADDRESS_2", payload: result.address1_line2})
        if (result.address1_city != null) dispatchContactInfo({type: "SET_CITY", payload: result.address1_city})
        if (result.address1_country != null) dispatchContactInfo({type: "SET_COUNTRY", payload: result.address1_country})
        if (result.address1_postalcode != null) dispatchContactInfo({type: "SET_ZIP", payload: result.address1_postalcode})
        if (result.address1_stateorprovince != null) dispatchContactInfo({type: "SET_STATE", payload: result.address1_stateorprovince})
        if (result.address1_name) dispatchContactInfo({type: "SET_ADDRESS_1_NAME", payload: result.address1_name})
        // SET SHIPPING ADDRESS VALUES
        if (result.sameAsHomeAddress) dispatchContactInfo({type: "SET_SAME_AS_HOME_ADRESS", payload: result.sameAsHomeAddress})
        if (result.address2_line1 != null) dispatchContactInfo({type: "SET_SHIPPING_ADRESS_1", payload: result.address2_line1})
        if (result.address2_line2 != null) dispatchContactInfo({type: "SET_SHIPPING_ADRESS_2", payload: result.address2_line2})
        if (result.address2_city != null) dispatchContactInfo({type: "SET_SHIPPING_CITY", payload: result.address2_city})
        if (result.address2_country != null) dispatchContactInfo({type: "SET_SHIPPING_COUNTRY", payload: result.address2_country})
        if (result.address2_postalcode != null) dispatchContactInfo({type: "SET_SHIPPING_ZIP", payload: result.address2_postalcode})
        if (result.address2_stateorprovince != null) dispatchContactInfo({type: "SET_SHIPPING_STATE", payload: result.address2_stateorprovince})
        if (result.address2_name) dispatchContactInfo({type: "SET_ADDRESS_2_NAME", payload: result.address2_name})

        // SET EMERGENCY CONTACT VALUES
        if (result.addSecondEmergencyContact) dispatchContactInfo({type: "SET_ADD_SECOND_CONTACT", payload: result.addSecondEmergencyContact})
        if (result.p15_emergency1email) dispatchContactInfo({type: "SET_EMERGENCY_EMAIL", payload: result.p15_emergency1email})
        if (result.p15_emergency1homephone) dispatchContactInfo({type: "SET_EMERGENCY_HOME_PHONE", payload: result.p15_emergency1homephone})
        if (result.p15_emergency1mobilephone) dispatchContactInfo({type: "SET_EMERGENCY_MOBILE_PHONE", payload: result.p15_emergency1mobilephone})
        if (result.p15_emergency1workphone) dispatchContactInfo({type: "SET_EMERGENCY_WORK_PHONE", payload: result.p15_emergency1workphone})
        if (result.p15_emergency1name) dispatchContactInfo({type: "SET_EMERGENCY_FULL_NAME", payload: result.p15_emergency1name})
        if (result.p15_emergency1relationship) dispatchContactInfo({type: "SET_EMERGENCY_RELATIONSHIP", payload: result.p15_emergency1relationship})

        // SET SECONDARY EMERGENCY CONTACT VALUES
        if (result.p15_emergency2email) dispatchContactInfo({type: "SET_SECONDARY_EMERGENCY_EMAIL", payload: result.p15_emergency2email})
        if (result.p15_emergency2homephone) dispatchContactInfo({type: "SET_SECONDARY_EMERGENCY_HOME_PHONE", payload: result.p15_emergency2homephone})
        if (result.p15_emergency2mobilephone) dispatchContactInfo({type: "SET_SECONDARY_EMERGENCY_MOBILE_PHONE", payload: result.p15_emergency2mobilephone})
        if (result.p15_emergency2workphone) dispatchContactInfo({type: "SET_SECONDARY_EMERGENCY_WORK_PHONE", payload: result.p15_emergency2workphone})
        if (result.p15_emergency2name) dispatchContactInfo({type: "SET_SECONDARY_EMERGENCY_FULL_NAME", payload: result.p15_emergency2name})
        if (result.p15_emergency2relationship) dispatchContactInfo({type: "SET_SECONDARY_EMERGENCY_RELATIONSHIP", payload: result.p15_emergency2relationship})
        if (result.br_confirmedvalidcontactinfo) dispatchContactInfo({type: "SET_USER_CONFIRMED_CONTACT_INFO", payload: result.br_confirmedvalidcontactinfo})

        if (result.br_vaccinetype) dispatchContactInfo({type: "SET_VACCINATION_TYPE", payload: result.br_vaccinetype})
        if (result.br_vaccinationstatus) dispatchContactInfo({type: "SET_VACCINATION_STATUS", payload: result.br_vaccinationstatus})
        if (result.br_dateoflastvaccination) dispatchContactInfo({type: "SET_DATE_OF_LAST_VACCINATION", payload: result.br_dateoflastvaccination})

        if (result.br_tifflippersize) dispatchContactInfo({type: "SET_SHOW_FLIPPER_SIZE", payload: result.br_tifflippersize})
        if (result.br_flippersize) dispatchContactInfo({type: "SET_SELECTED_FLIPPER_SIZE", payload: result.br_flippersize})

        if (result.br_tifshoesize) dispatchContactInfo({type: "SET_SHOW_SHOE_SIZE", payload: result.br_tifshoesize})
        if (result.br_shoesize) dispatchContactInfo({type: "SET_SELECTED_SHOE_SIZE", payload: result.br_shoesize})

        if (result.br_tifheightrequired) dispatchContactInfo({type: "SET_SHOW_HEIGHT", payload: result.br_tifheightrequired})

        if (parseInt(result.br_height_ft) >= 0) {
             dispatchContactInfo({type: "SET_HEIGHT_FT", payload: result.br_height_ft})
        }
        if (parseInt(result.br_height_in) >= 0) {
             dispatchContactInfo({type: "SET_HEIGHT_INCHES", payload: result.br_height_in})
        }

        if (result.br_tiftshirtrequired) dispatchContactInfo({type: "SET_SHOW_TSHIRT", payload: result.br_tiftshirtrequired})
        if (result.br_tshirtsize) dispatchContactInfo({type: "SET_SELECTED_TSHIRT_SIZE", payload: result.br_tshirtsize})

        if (result.br_jerseysizerequired) dispatchContactInfo({type: "SET_SHOW_BIKE_JERSEY", payload: result.br_jerseysizerequired})
        if (result.br_jerseysize) dispatchContactInfo({type: "SET_SELECTED_BIKE_JERSEY_SIZE", payload: result.br_jerseysize})

        if (result.br_tifweight) dispatchContactInfo({type: "SET_SHOW_WEIGHT", payload: result.br_tifweight})
        if (result.br_weight) dispatchContactInfo({type: "SET_WEIGHT", payload: result.br_weight})

        if(result.br_tifwetsuitsize) dispatchContactInfo({type: "SET_SHOW_WETSUIT", payload: result.br_tifwetsuitsize})
        if(result.br_wetsuitsize) dispatchContactInfo({type: "SET_SELECTED_WETSUIT", payload: result.br_wetsuitsize})

        if (result.birthdate) dispatchContactInfo({type: "SET_DATE_OF_BIRTH", payload: result.birthdate})
        if (result.br_allergies) dispatchContactInfo({type: "SET_ALLERGIES", payload: result.br_allergies})
        if (result.br_dietarypreferences) dispatchContactInfo({type: "SET_DIATARY_PREFERENCES", payload: result.br_dietarypreferences})
        if (result.br_medicalconditions) dispatchContactInfo({type: "SET_MEDICAL_CONDITIONS", payload: result.br_medicalconditions})

        if (result.br_tifpassportflag) dispatchContactInfo({type: "SET_SHOW_PASSPORT_SECTION", payload: result.br_tifpassportflag})
        if (result.br_passportuploadflag) dispatchContactInfo({type: "SET_UPLOAD_PASSPORT_PHOTO", payload: result.br_passportuploadflag})
        if (result.br_travellingwithpassportonrecord) dispatchContactInfo({type: "SET_IS_PASSPORT_CONFIRMATION_CHECKED", payload: result.br_travellingwithpassportonrecord})

        if (result.p15_nameonpassport) dispatchContactInfo({type: "SET_NAME_ON_PASSPORT", payload: result.p15_nameonpassport})
        if (result.p15_passportexpires) dispatchContactInfo({type: "SET_EXPIRY_DATE", payload: result.p15_passportexpires})
        if (result.p15_passportissued) dispatchContactInfo({type: "SET_ISSUE_DATE", payload: result.p15_passportissued})
        if (result.p15_passportnationality) dispatchContactInfo({type: "SET_NATIONALITY", payload: result.p15_passportnationality})
        if (result.p15_passportnumber) dispatchContactInfo({type: "SET_PASSPORT_NUMBER", payload: result.p15_passportnumber})
        if (result.p15_placeofbirth) dispatchContactInfo({type: "SET_PLACE_OF_BIRTH", payload: result.p15_placeofbirth})
        if (result.p15_placeofissue) dispatchContactInfo({type: "SET_PLACE_OF_ISSUE", payload: result.p15_placeofissue})

        if (result.br_visaflag) dispatchContactInfo({type: "SET_SHOW_VISA", payload: result.br_visaflag})
        if (result.br_visanumber) dispatchContactInfo({type: "SET_VISA_NUMBER", payload: result.br_visanumber})
        if (result.br_visadateofexpiry) dispatchContactInfo({type: "SET_VISA_EXPIRY_DATE", payload: result.br_visadateofexpiry})
        if (result.br_visavaliditydate) dispatchContactInfo({type: "SET_VISA_ISSUE_DATE", payload: result.br_visavaliditydate})

        if (result.isPassportPhotoUploaded) dispatchContactInfo({type: "SET_IS_PASSPORT_PHOTO_UPLOADED", payload: result.isPassportPhotoUploaded})
        if (result.isVisaPhotoUploaded) dispatchContactInfo({type: "SET_IS_VISA_PHOTO_UPLOADED", payload: result.isVisaPhotoUploaded})
        if (result.b_signedWaiverReceived) dispatchContactInfo({type: "SET_WAIVER_SIGNED", payload: result.b_signedWaiverReceived})
        if (result.b_updatesLocked) dispatchContactInfo({type: "SET_ARE_FIELDS_LOCKED", payload: result.b_updatesLocked})


        let sections = ["Contact Info", "Traveller Profile", "Arrival & Departure", "Other Information & Special Requests"]


        if (result.getUpcomingTrip && result.getUpcomingTrip[0].guestOwnerId) dispatchContactInfo({type: "SET_GUEST_OWNER_ID", payload: result.getUpcomingTrip[0].guestOwnerId});

        if (result.getUpcomingTrip && result.getUpcomingTrip[0]) {
            dispatchContactInfo({type: "SET_GUEST_OWNER_ID", payload: result.getUpcomingTrip[0].guestOwnerId})
            dispatchContactInfo({type: "SET_BOOKING_ID", payload: result.getUpcomingTrip[0].bookingID});
        };

        // SET PAYMENT & INSURANCE
        if (result.invoice && Object.keys(result.invoice).length > 0) {

            // make sure to include the section
            sections.splice(3, 0, "Payment & Insurance");

            if (result.br_insurancestatus != null) {
                dispatchContactInfo({type: "SET_PREFERRED_INSURANCE_OPTION", payload: result.br_insurancestatus})
            }

            if (result.br_insurancecomments) {
                dispatchContactInfo({type: "SET_INSURANCE_COMMENTS", payload: result.br_insurancecomments})
            }

            if (result.br_agreedtotermsandconditions) {
                dispatchContactInfo({type: "SET_USER_AGREED_TO_TERMS_AND_CONDITIONS_INVOICE", payload: result.br_agreedtotermsandconditions})
            }

            if (result.br_finalpaymentmethod) {
                dispatchContactInfo({type: "SET_SELECTED_PAYMENT_METHOD", payload: result.br_finalpaymentmethod})
            }

            if (result.br_authorizedfinalpayment) {
                dispatchContactInfo({type: "SET_USER_AGREED_TO_AUTHORIZE_FINAL_PAYMENT", payload: result.br_authorizedfinalpayment})

                // lock authorize payment method checkbox
                if (result.br_authorizedfinalpayment == true) {
                    dispatchContactInfo({
                        type: "SET_LOCK_AUTHORIZE_PAYMENT_CHECKBOX",
                        payload: true
                    });
                };
            };


            // Hide credit card options
            if (result.invoice.br_offercreditcardpaymentoption != undefined) {
                dispatchContactInfo({type: "SET_HIDE_CREDIT_CARD_OPTIONS", payload: result.invoice.br_offercreditcardpaymentoption})
            }

            if (
                result.invoice.br_offercreditcardpaymentoption != undefined
                && !result.invoice.br_offercreditcardpaymentoption
            ) {
                dispatchContactInfo({
                    type: "SET_FINAL_PAYMENT_OPTIONS",
                    payload: [{value: "select", name: "--Select--"},{value: "100000002", name: "Cheque"},{value: "100000001", name: "Wire"}]
                })
            }

            if (result.invoice.p15_bookingnumber) dispatchContactInfo({type: "SET_BOOKING_NUMBER", payload: result.invoice.p15_bookingnumber});
            if (result.invoice.insurancePurchaseURL) dispatchContactInfo({type: "SET_INSURANCE_PURCHASE_URL", payload: result.invoice.insurancePurchaseURL});
            if (result.invoice) dispatchContactInfo({type: "SET_INVOICE", payload: result.invoice})
            if (result.creditCards) dispatchContactInfo({type: "SET_CREDIT_CARDS", payload: result.creditCards})

            // When no payment method has previously been selected
            if (result.p15_creditcardid) {
                dispatchContactInfo({
                    type: "SET_SELECTED_CREDIT_CARD",
                    payload: result.p15_creditcardid
                })
            }
        } else if (result.br_authorizedfinalpayment == true) {
            /*
            NOTE:
                Sets the authorize payment checkbox lock to true.
                Since we cant do this in the above if statement since that
                handles any data that comes through as part of a refresh
            */
            dispatchContactInfo({
                type: "SET_LOCK_AUTHORIZE_PAYMENT_CHECKBOX",
                payload: true
            });
        }



        // SET GEAR VALUES
        if (result.gear && result.gear.length > 0) {

            // add Gear to beginning of sections
            sections.unshift("Gear")

            dispatchContactInfo({type: "SET_GEAR", payload: result.gear})
            dispatchContactInfo({type: "SET_GEAR_COPY", payload: result.gear})

            for (let x in result.gear) {
                let gearObject = result.gear[x];
                if (gearObject.name == "Bike") setBike(gearObject.selectedValue, result.gear);
                if (gearObject.name == "Bike Pedals") dispatchContactInfo({type: "SET_SELECTED_PEDALS", payload: gearObject.selectedValue});
                if (gearObject.name == "Helmet") dispatchContactInfo({type: "SET_SELECTED_HELMET", payload: gearObject.selectedValue});
                if (gearObject.name == "Gel seat cover") dispatchContactInfo({type: "SET_SELECTED_SEAT_COVER", payload: gearObject.selectedValue});
                if (gearObject.name == "Mirror") dispatchContactInfo({type: "SET_SELECTED_MIRROR", payload: gearObject.selectedValue});
            }
        }


        // UPDATE STEPS IF NECESSARY
        if (contactInfo.steps.length == 0) {

            // ADD COMPLETED STEPS
            let completedSections = new Set();
            completedSections.add("Other Information & Special Requests");

            // SETTING STATUSES
            for (let key in result.getUpcomingTrip[0].sectionStatus) {
                if (result.getUpcomingTrip[0].sectionStatus[key]) {
                    completedSections.add(key)
                }
            };

            // Set completed section and traveler comments
            if (result.br_travellercomments && result.br_travellercomments.length > 0) {
                dispatchContactInfo({type: "SET_TRAVELER_COMMENTS", payload: result.br_travellercomments})
            }

            dispatchContactInfo({
                type: "SET_COMPLETED_SECTIONS",
                payload: completedSections
            })

            dispatchContactInfo({
                type: "SET_STEPS",
                payload: sections
            })
        }

        // SET ARRIVAL & DEPARTURE VALUES
        if (result.br_pickuplocation) dispatchContactInfo({type: "SET_TRIP_PICK_UP_LOCATION", payload: result.br_pickuplocation})
        if (result.p15_startdate) dispatchContactInfo({type: "SET_TRIP_PICK_UP_DATE", payload: result.p15_startdate})
        if (result.br_pickuptime) dispatchContactInfo({type: "SET_TRIP_PICK_UP_TIME", payload: result.br_pickuptime})
        if (result.p15_enddate) dispatchContactInfo({type: "SET_TRIP_DROP_OFF_DATE", payload: result.p15_enddate})
        if (result.br_dropofftime) dispatchContactInfo({type: "SET_TRIP_DROP_OFF_TIME", payload: result.br_dropofftime})
        if (result.br_dropoffcity) dispatchContactInfo({type: "SET_TRIP_DROP_OFF_CITY", payload: result.br_dropoffcity})
        if (result.br_hotelnamenightpriortotrip) dispatchContactInfo({type: "SET_HOTEL_NAME", payload: result.br_hotelnamenightpriortotrip})
        if (result.br_posttriptravelplans) dispatchContactInfo({type: "SET_CONSUMER_POST_TRIPS", payload: result.br_posttriptravelplans})
        if (result.br_offerprepostassistance) dispatchContactInfo({type: "SET_SHOW_TRIP_PRE_POST_ASSISTANCE", payload: result.br_offerprepostassistance})
        if (result.br_tifnightbeforehotelflag) dispatchContactInfo({type: "SET_SHOW_PRETRIP_HOTEL_NAME", payload: result.br_tifnightbeforehotelflag})
        if (result.br_pudoarrivalintro) dispatchContactInfo({type: "SET_SHOW_TRIP_INTRO", payload: result.br_pudoarrivalintro})
        if (result.br_tifposttriptravelplans) dispatchContactInfo({type: "SET_SHOW_POST_TRIP_TRAVEL_PLANS", payload: result.br_tifposttriptravelplans})

        // FLIGHT ARRIVAL
        if (result.br_flightarrivaloncarrier) dispatchContactInfo({type: "SET_TRIP_ARRIVAL_CARRIER", payload: result.br_flightarrivaloncarrier})
        if (result.br_flightarrivalflightnumber) dispatchContactInfo({type: "SET_TRIP_ARRIVAL_FLIGHT_NO", payload: result.br_flightarrivalflightnumber})
        if (result.br_flightarrivalcity) dispatchContactInfo({type: "SET_TRIP_ARRIVAL_CITY", payload: result.br_flightarrivalcity})
        if (result.br_flightdateofarrival) dispatchContactInfo({type: "SET_TRIP_ARRIVAL_DATE", payload: result.br_flightdateofarrival})
        if (result.br_flightarrivaltime) dispatchContactInfo({type: "SET_TRIP_ARRIVAL_TIME", payload: result.br_flightarrivaltime})

        // FLIGHT DEPARTURE
        if (result.br_flightdepartureoncarrier) dispatchContactInfo({type: "SET_TRIP_DEPARTURE_CARRIER", payload: result.br_flightdepartureoncarrier})
        if (result.br_flightdepartureflightnumber) dispatchContactInfo({type: "SET_TRIP_DEPARTURE_FLIGHT_NO", payload: result.br_flightdepartureflightnumber})
        if (result.br_flightdeparturecity) dispatchContactInfo({type: "SET_TRIP_DEPARTURE_CITY", payload: result.br_flightdeparturecity})
        if (result.br_flightdateofdeparture) dispatchContactInfo({type: "SET_TRIP_DEPARTURE_DATE", payload: result.br_flightdateofdeparture})
        if (result.br_flightdeparturetime) dispatchContactInfo({type: "SET_TRIP_DEPARTURE_TIME", payload: result.br_flightdeparturetime})

        // TRAIN DEPARTURE
        if (result.br_departuretraincarrier) dispatchContactInfo({type: "SET_TRIP_DEPARTURE_TRAIN_CARRIER", payload: result.br_departuretraincarrier})
        if (result.br_departuretrainnumber) dispatchContactInfo({type: "SET_TRIP_DEPARTURE_TRAIN_NUMBER", payload: result.br_departuretrainnumber})
        if (result.br_departuretrainstation) dispatchContactInfo({type: "SET_TRIP_DEPARTURE_TRAIN_STATION", payload: result.br_departuretrainstation})
        if (result.br_traindeparturedate) dispatchContactInfo({type: "SET_TRIP_DEPARTURE_TRAIN_DATE", payload: result.br_traindeparturedate})
        if (result.br_traindeparturetime) dispatchContactInfo({type: "SET_TRIP_DEPARTURE_TRAIN_TIME", payload: result.br_traindeparturetime})

        // TRAIN ARRIVAL
        if (result.br_arrivaltraincarrier) dispatchContactInfo({type: "SET_TRIP_ARRIVAL_TRAIN_CARRIER", payload: result.br_arrivaltraincarrier})
        if (result.br_arrivaltrainnumber) dispatchContactInfo({type: "SET_TRIP_ARRIVAL_TRAIN_NUMBER", payload: result.br_arrivaltrainnumber})
        if (result.br_arrivaltrainstation) dispatchContactInfo({type: "SET_TRIP_ARRIVAL_TRAIN_STATION", payload: result.br_arrivaltrainstation})
        if (result.br_trainarrivaldate) dispatchContactInfo({type: "SET_TRIP_ARRIVAL_TRAIN_DATE", payload: result.br_trainarrivaldate})
        if (result.br_trainarrivaltime) dispatchContactInfo({type: "SET_TRIP_ARRIVAL_TRAIN_TIME", payload: result.br_trainarrivaltime})

        if (result.br_flightsflag) {
            dispatchContactInfo({type: "SET_SHOW_TRIP_FLIGHTS", payload: result.br_flightsflag})
            dispatchContactInfo({type: "SET_ARRIVAL_TABS", payload: {label: "Flights", icon: <FlightLandIcon />}})
            dispatchContactInfo({type: "SET_DEPARTURE_TABS", payload: {label: "Flights", icon: <FlightTakeoffIcon />}})
        }

        if (result.br_trainsflag) {
            dispatchContactInfo({type: "SET_SHOW_TRIP_TRAINS", payload: result.br_trainsflag})
            dispatchContactInfo({type: "SET_ARRIVAL_TABS", payload: {label: "Train", icon: <TrainIcon />}})
            dispatchContactInfo({type: "SET_DEPARTURE_TABS", payload: {label: "Train", icon: <TrainIcon />}})
        }
    }

    const setContact = (data) => {

        return new Promise(async(resolve, reject) => {
            axios.post(`${process.env.REACT_APP_API_ENDPOINT}/v1/setContact`, data, HEADERS)
            .then(result => {

                if (result.status == 222 && result.data.sessionTimeout) {
                    logOutUser(props)
                    return resolve();
                };

                resolve(result.data);
            })
            .catch((err) => {console.log(err);reject(err)})
        });
    };

    const setGear = (data) => {

        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_API_ENDPOINT + "/v1/setGear", data, HEADERS)
            .then(result => {

                if (result.status == 222 && result.data.sessionTimeout) {
                    logOutUser(props);
                    return resolve();
                };

                resolve(result.data)})
            .catch(err => {console.log(err);reject(err)})
        });
    };

    const setAndGetPedalOptions = (gear) => {
        /*
        NOTE:
            This proceedure manipulates an object and sets the bike pedal options'
            states. Returns the selected value because setting the state is async
            and will run into a race conditions.
        */

        // final object used to set gear
        let tempGearObj = JSON.parse(JSON.stringify(gear));

        // filter for pedals
        let bikePedalsObj = tempGearObj.filter((item) => item.name == "Bike Pedals")[0];

        // populate newBikePedalsOptionsArr
        let newBikePedalsOptionsArr = [];
        bikePedalsObj.options.forEach((option) => {
            if (option.name == "Without Toe Cages") {
                newBikePedalsOptionsArr.push(option)
                return;
            }
        })


        // Use newBikePedalsOptionsArr as new options
        let selectedPedal = null;
        for (let key in tempGearObj) {
            let item = tempGearObj[key];

            if (item.name == "Bike Pedals") {
                item.options = newBikePedalsOptionsArr;
                item.selectedValue = newBikePedalsOptionsArr[0].value
                selectedPedal = newBikePedalsOptionsArr[0].value
            }
        }

        dispatchContactInfo({type: "SET_GEAR", payload: tempGearObj})
        return selectedPedal;
    }

    const setBike = (value, gearObj) => {
        /*
        NOTE:
            Sets the selected bike and determines to show certain bike pedal options
            depending on the whether the selected bike is an ebike or not.
        */

        dispatchContactInfo({
            type: "SET_SELECTED_BIKE",
            payload: value
        });

        if (gearObj != null || gearObj != undefined) {

            let isEbike = false;
            let bikeObj = gearObj.filter((item) => item.name == "Bike")[0];

            // Get the value of isEbike
            bikeObj.options.forEach((item, i) => {
                if (item.value == value) isEbike = item.isEbike
            });

            // If it is ebike then manipulate the object and set the state
            if (isEbike) {
                setAndGetPedalOptions(gearObj)
            }
        } else {
            let isEbike = false;

            for (let item in contactInfo.gear) {
                let gearItem = contactInfo.gear[item];

                if (gearItem.name == "Bike") {
                    for (let option in gearItem.options) {
                        if (gearItem.options[option].value == value) {
                            isEbike = gearItem.options[option].isEbike
                            break;
                        }
                    }
                }
            }


            if (isEbike) {
                let selectedPedal = setAndGetPedalOptions(contactInfo.gear)
                dispatchContactInfo({type: "SET_SELECTED_PEDALS", payload: selectedPedal});

                // fire a pop up explain terms and conditions to users about ebikes

                updateAlertTitle("Note");
                updateAlertMessage(`
                    There may be a charge for riding an ebike on this trip.
                    Your Travel Coordinator will be in touch with more details
                    once you have made your selection.
                `);
                updateIsAlertOpen(true);
                updateAlertButtons([
                    {
                        id: 0,
                        name: "Ok",
                        action: () => {
                            resetState();
                        },
                        color: "primary",
                        variant: "contained",
                    }
                ]);

            } else {
                dispatchContactInfo({type: "SET_GEAR", payload: contactInfo.gearCopy})
                dispatchContactInfo({
                    type: "SET_SELECTED_PEDALS",
                    payload: contactInfo.gearCopy.filter((item) => {
                        return item.name == "Bike Pedals"
                    })[0].selectedValue
                });
            }
        }
    };

    const uploadTravelDocuments = (documentsArr, callback) => {

        let requests = [];

        for (let index in documentsArr) {
            requests.push(() => axios.post(`${process.env.REACT_APP_API_ENDPOINT}/v1/setAnnotation/guests/${urlParams["guestId"]}`, documentsArr[index], HEADERS))
        }

        updateIsLoading(true);

        axios.all(requests.map((request) => request()))
        .then(axios.spread((...results) => {

            updateIsLoading(false)

            /* NOTE:
                Manually adding the objects into one object since Object.assign()
                overrides the objects
            */
            let mergedResults = {};
            for (let x in [...results]) {
                mergedResults[x] = results[x]
            }

            // Check if any of the api responses have a 222 status code
            for (let result in mergedResults) {
                if (mergedResults[result].status == 222 && mergedResults[result].data.sessionTimeout) {
                    return logOutUser(props)
                }
            }

            return callback();
        }))
        .catch((err) => {
            console.log(err);
            handleErrorMessage(err);
            throw err;
        });
    }

    const clearSecondEmergencyContactValues = () => {
        dispatchContactInfo({type: "SET_SECONDARY_EMERGENCY_FULL_NAME", payload: ""})
        dispatchContactInfo({type: "SET_SECONDARY_EMERGENCY_RELATIONSHIP", payload: ""})
        dispatchContactInfo({type: "SET_SECONDARY_EMERGENCY_EMAIL", payload: ""})
        dispatchContactInfo({type: "SET_SECONDARY_EMERGENCY_MOBILE_PHONE", payload: ""})
        dispatchContactInfo({type: "SET_SECONDARY_EMERGENCY_HOME_PHONE", payload: ""})
        dispatchContactInfo({type: "SET_SECONDARY_EMERGENCY_WORK_PHONE", payload: ""})
    }

    const setGuest = (data) => {
        return new Promise((resolve, reject) => {

            axios.post(`${process.env.REACT_APP_API_ENDPOINT}/v1/setGuest/${urlParams["guestId"]}`, data, HEADERS)
            .then(result => {

                if (result.status == 222 && result.data.sessionTimeout) {
                    logOutUser(props);
                    return resolve();
                };

                resolve(result.data);
            })
            .catch(err => {console.log(err);reject(err)})
        });
    };

    const setTravelerProfile_SubRoutine = (contactProps, guestProps) => {

        return new Promise(async(resolve, reject) => {

            updateIsLoading(true);
            Promise.all([setGuest(guestProps), setContact(contactProps)])
            .then((results) => {
                dispatchContactInfo({
                    type: "SET_ORIGINAL_STATE",
                    payload: {...results[0], ...results[1]}
                });
                updateIsLoading(false);
                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        })
    };

    const setContactInfo_SubRoutine = (contactProps, guestProps) => {

        return new Promise(async(resolve, reject) => {

            updateIsLoading(true);
            Promise.all([setGuest(guestProps), setContact(contactProps)])
            .then((results) => {
                dispatchContactInfo({
                    type: "SET_ORIGINAL_STATE",
                    payload: {...results[0], ...results[1]}
                });
                setStateValues({...results[0], ...results[1]})
                updateIsLoading(false);
                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    };

    const handleSnackbarOpen = () => {
        dispatchContactInfo({
            type: "SET_SNACKBAR_MESSAGE",
            payload: "Special Request has been submitted"
        });
        dispatchContactInfo({type: "SET_SNACKBAR_STATUS", payload: "success"});
        dispatchContactInfo({type: "SET_SNACKBAR_IS_OPEN", payload: true});
    };

    const handleSnackbarClose = () => {

        dispatchContactInfo({type: "SET_SNACKBAR_IS_OPEN", payload: false});
    };

    const handleCreditCardSubmission = () => {

        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        if (!stripe || !elements) return;

        // validate name on card
        if ((contactInfo.nameOnCard || "") == "") {
            dispatchContactInfo({type: "SET_NAME_ON_CARD_ERROR", payload: "Name on card cannot be empty"})
            dispatchContactInfo({type: "SET_SHOW_NAME_ON_CARD_ERROR", payload: true})
            return;
        } else {
            dispatchContactInfo({type: "SET_NAME_ON_CARD_ERROR", payload: ""})
            dispatchContactInfo({type: "SET_SHOW_NAME_ON_CARD_ERROR", payload: false})
        }

        const cardElement = elements.getElement(CardElement);

        updateIsLoading(true)
        Promise.all([
            stripe.createToken(cardElement),
            stripe.createPaymentMethod({type: "card", card: cardElement}),
        ])
        .then((results) => {

            for (let result in results) {
                if ("error" in results[result]) {
                    updateIsLoading(false);
                    updateAlertTitle("Error while trying to save credit card");
                    updateAlertMessage(results[result].error.message);
                    updateIsAlertOpen(true);
                    return;
                }
            };


            return addCreditCardToStripe(results[1], (result) => {

                let cardTypes = {"Visa":1,"MasterCard":2,"American Express":3,"Discover":4}
                let p15_object = {
                    nameOnCard: contactInfo.nameOnCard,
                    cardNumber: results[0].token.card.last4,
                    cardType: (cardTypes[results[0].token.card.brand] || 5),
                    expirationMonth: results[0].token.card.exp_month.toString(),
                    expirationYear: results[0].token.card.exp_year.toString(),
                    subscriptionId: results[0].token.id,
                    accountType: 541720000,
                    merchantAccount: process.env.REACT_APP_MERCHANT_ACCOUNT,
                    token: localStorage.getItem("token"),
                    cardId: results[0].token.card.id,
                    customerId: result.data.customerId
                };

                return addCreditCardTokenToContact(p15_object, (result) => {

                    updateIsLoading(false);
                    if (result) {
                        if (result.status == 222 && result.data.sessionTimeout) {
                            return logOutUser(props);
                        };

                        if (result.status != 200) {
                            updateAlertTitle("Error");
                            updateAlertMessage(result.data.error.message);
                            updateIsAlertOpen(false);
                            return;
                        };

                        updateAlertMessage("Credit card successfully saved")
                        updateMaxWidth("xs")
                        updateAlertSuccess(true)
                        updateIsAlertOpen(true)
                        updateAlertButtons([{
                            id: 0,
                            name: "Ok",
                            action: () => {
                                updateIsAlertOpen(false)
                                updateAlertTitle("")
                                updateAlertMessage("")
                                window.location.reload();
                            },
                            color: "primary",
                            variant: "contained",
                        }])
                        updateOnExitButtonClick(() => {
                            updateIsAlertOpen(false)
                            updateAlertTitle("")
                            updateAlertMessage("")
                            window.location.reload();
                        })
                    } else {
                        updateAlertMessage("Error while trying to save the credit card")
                        updateIsAlertOpen(true)
                        updateAlertButtons([{
                            id: 0,
                            name: "Ok",
                            action: () => {
                                updateIsAlertOpen(false)
                                updateAlertTitle("")
                                updateAlertMessage("")
                                window.location.reload();
                            },
                            color: "primary",
                            variant: "contained",
                        }])
                    };
                });
            })
        });
    };

    const handleSectionActiveStatus = (index) => {
        dispatchContactInfo({
            type: "SET_CURRENTLY_EXPANDED",
            payload: index
        });

        if (contactInfo.currentlyExpanded == index) {
            updateActiveStepInUrl(contactInfo.steps.length);
        };
    };

    const addCreditCardTokenToContact = (userData, callback) => {

        axios.post(
            process.env.REACT_APP_API_ENDPOINT + "/v1/addCreditCardTokenToContact",
            userData,
            HEADERS
        )
        .then((result) => callback(result))
        .catch((err) => {console.log(err);callback(null)})
    };

    const addCreditCardToStripe = (cardObj, callback) => {

        axios.post(
            process.env.REACT_APP_API_ENDPOINT + "/v1/stripe/addCreditCard",
            {
                cardID: cardObj.paymentMethod.id,
                token: localStorage.getItem("token"),
                userDetails: {
                    name: contactInfo.firstname + " " + contactInfo.lastname,
                    line1: contactInfo.address1_line1,
                    city: contactInfo.address1_city,
                    country: contactInfo.address1_country,
                    line2: contactInfo.address1_line2,
                    postal_code: contactInfo.address1_postalcode,
                    state: contactInfo.address1_stateorprovince
                }
            },
            HEADERS
        )
        .then((result) => {
            if (result.status == 222 && result.data.sessionTimeout) {
                updateIsLoading(false);
                return logOutUser(props)
            };

            if (result.status === 222) {
                updateIsLoading(false);
                updateAlertTitle("Error while trying to save credit card")
                updateAlertMessage(result.data.error.message)
                updateIsAlertOpen(true)
                updateAlertButtons([{
                    id: 0,
                    name: "Ok",
                    action: () => {
                        updateIsAlertOpen(false)
                        updateAlertTitle("")
                        updateAlertMessage("")
                    },
                    color: "primary",
                    variant: "contained",
                }])
                return;
            };

            return callback(result);
        }).catch((err) => {
            updateIsLoading(false);
            updateAlertTitle("Error while trying to save credit card")
            updateAlertMessage(err.response.data.error.message)
            updateIsAlertOpen(true)
            updateAlertButtons([{
                id: 0,
                name: "Ok",
                action: () => {
                    updateIsAlertOpen(false)
                    updateAlertTitle("")
                    updateAlertMessage("")
                },
                color: "primary",
                variant: "contained",
            }])
        });
    };

    const createTask = () => {

        return new Promise((resolve, reject) => {

            const guestObjectTypeValue = 10047; //refers to guest in p15 schema
            const tcTaskListId = "c15cbfb2-bc4f-e811-80e3-0050569d9cb6" // TC Task List
            const userData = {
                regardingId: urlParams.guestId,
                regardingTypeCode: guestObjectTypeValue,
                description: contactInfo.taskDescription,
                subject: "Task",
                taskOwner: contactInfo.guestOwnerId, // taskowner would be guest owner
                categoryId: tcTaskListId
            };

            axios.post(process.env.REACT_APP_API_ENDPOINT + "/v1/setTask", userData, HEADERS)
            .then((result) => {
                if (result.status == 222 && result.data.sessionTimeout) {
                    return logOutUser(props)
                };

                dispatchContactInfo({type:"SET_TASK_DESCRIPTION", payload: ""})
                resolve();
            })
            .catch(err => {console.log(err);reject()})
        })
    };

    // happens when the date comes through
    const parseDate = (date) => {
        if (date == null) {
            return null
        }
        return moment.utc(date).format("MM/DD/YYYY")
    };

    // HANDLING INPUT
    const formatDate = (date) => {
        if (date == null) {
            return null
        } else {
            return moment(date).format("MM/DD/YYYY");
        }
    };

    const handleFileSelect = (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result);
            }

            reader.readAsDataURL(file)
        })
    };

    const handleErrorMessage = (err) => {
        updateAlertTitle(ERROR_TITLE)
        if (err.response.status >= 500) {
            updateAlertMessage(ERROR_MESSAGE)
        } else {
            updateAlertMessage(err.response.data.error.message)
        };
        updateIsLoading(false);
        updateIsAlertOpen(true);
    };

    const handleNext = async(label, index) => {

        if (label == "Contact Info") {

            checkForAddressMismatch(async(userClickedYes) => {
                let guestObj = {br_confirmedvalidcontactinfo: contactInfo.br_confirmedvalidcontactinfo};
                let contactObj = getApplicableContactInfoProperties();

                if (userClickedYes) {
                    contactObj["address2_line1"] = contactInfo.address1_line1;
                    contactObj["address2_line2"] = contactInfo.address1_line2;
                    contactObj["address2_city"] = contactInfo.address1_city;
                    contactObj["address2_country"] = contactInfo.address1_country;
                    contactObj["address2_postalcode"] = contactInfo.address1_postalcode;
                    contactObj["address2_stateorprovince"] = contactInfo.address1_stateorprovince;
                    contactObj["address2_name"] = contactInfo.address1_name;
                };

                let contactDirtyProps = getDirtyProps(contactObj);
                let guestDirtyProps = getDirtyProps(guestObj);

                if (Object.keys({...contactDirtyProps, ...guestDirtyProps}).length > 0) {
                    await setContactInfo_SubRoutine(contactDirtyProps, guestDirtyProps)
                    .catch((err) => {
                        console.log(err);
                        handleErrorMessage(err);
                        throw err;
                    });
                };

                toggleContactInfoSectionStatus();
            })
        }

        else if (label == "Traveller Profile") {

            let {contactObj, guestObj} = getApplicableTravellerProfileProperties();
            let contactDirtyProps = getDirtyProps(contactObj);
            let guestDirtyProps = getDirtyProps(guestObj);

            if (Object.keys({...contactDirtyProps, ...guestDirtyProps}).length > 0) {
                await setTravelerProfile_SubRoutine(contactDirtyProps, guestDirtyProps)
                .catch((err) => {
                    console.log(err);
                    handleErrorMessage(err);
                    throw err;
                });
                preUploadTravelDocs(() => validateTravelerProfile());
            } else {
                preUploadTravelDocs(() => validateTravelerProfile());
            };
        }

        else if (label == "Gear") {

            let gearItems = getApplicableGearItems();
            let gearObj = {};

            if (gearItems["Bike"]) gearObj["selectedBike"] = contactInfo.selectedBike;
            if (gearItems["Helmet"]) gearObj["selectedHelmet"] = contactInfo.selectedHelmet;
            if (gearItems["Pedals"]) gearObj["selectedPedal"] = contactInfo.selectedPedal;
            if (gearItems["Cover"]) gearObj["selectedSeatCover"] = contactInfo.selectedSeatCover;
            if (gearItems["Mirror"]) gearObj["selectedMirror"] = contactInfo.selectedMirror;
            if (gearItems["GolfClubPreference"]) gearObj["selectedGolfClubPreference"] = contactInfo.selectedGolfClubPreference;

            let dirtyProps = getDirtyProps(gearObj);

            if (Object.keys(dirtyProps).length > 0) {
                let deltaInput = []

                for (let x in Object.keys(dirtyProps)) {
                    let key = Object.keys(dirtyProps)[x];

                    deltaInput.push({
                        p15_guestsid: urlParams['guestId'],
                        p15_tripdeparturesid: urlParams["tripDepartureId"],
                        p15_vendorserviceitemreservationid_previousselection: contactInfo.originalState[key],
                        p15_vendorserviceitemreservationid: contactInfo[key]
                    })
                };

                updateIsLoading(true);
                await setGear({...deltaInput})
                .then((result) => {

                    updateIsLoading(false);

                    //  result object will be empty, but we won't need it anyway
                    dispatchContactInfo({
                        type: "SET_ORIGINAL_STATE",
                        payload: gearObj
                    });
                })
                .catch((err) => {
                    handleErrorMessage(err);
                    console.log("throwing error");
                    throw err;
                });
            };
            validateGear();
        }

        else if (label == "Arrival & Departure") {

            let guestObj = getApplicableArrivalAndDepartureProperties();
            let guestDirtyProps = getDirtyProps(guestObj);

            if (Object.keys(guestDirtyProps).length > 0) {
                updateIsLoading(true);
                setGuest(guestDirtyProps)
                .then((result) => {
                    dispatchContactInfo({type: "SET_ORIGINAL_STATE", payload: result});
                    validateArrivalAndDeparture();
                    updateIsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    handleErrorMessage(err);
                    throw err;
                });
            } else {
                validateArrivalAndDeparture();
            };
        }

        else if (label == "Payment & Insurance") {

            let obj = getApplicablePaymentAndInsuranceProperties()
            let dirtyProps = getDirtyProps(obj);

            if (Object.keys(dirtyProps).length > 0) {
                updateIsLoading(true);
                setGuest(dirtyProps)
                .then((result) => {
                    dispatchContactInfo({type: "SET_ORIGINAL_STATE", payload: result});
                    setStateValues(result);
                    updateIsLoading(false);
                    validatePaymentAndInsurance();
                })
                .catch((err) => {
                    console.log(err);
                    handleErrorMessage(err);
                    throw err;
                });
            } else {
                validatePaymentAndInsurance();
            };
        }

        else if (label == "Other Information & Special Requests")  {
            /*
            TODO:
                If this section grows then a function is needed to get the
                the applicable values for this section
            */


            let guestDirtyProps = getDirtyProps({br_travellercomments: contactInfo.br_travellercomments});
            let taskDescriptionLength = contactInfo.taskDescription.trim().length;

            if (Object.keys(guestDirtyProps).length > 0 || taskDescriptionLength > 0) {
                updateIsLoading(true);

                // check if both request need to be made
                if (Object.keys(guestDirtyProps).length >= 1 && taskDescriptionLength > 0) {

                    await Promise.all([setGuest(guestDirtyProps), createTask()])
                    .then((results) => {
                        handleSnackbarOpen();
                        dispatchContactInfo({type: "SET_ORIGINAL_STATE", payload: results[0]});
                        setStateValues(results[0]);
                    })
                    .catch((err) => {
                        console.log(err);
                        handleErrorMessage(err);
                        throw err;
                    });

                // check if set traveller comment api call needs to be made
                } else if (Object.keys(guestDirtyProps).length >= 1) {
                    await setGuest(guestDirtyProps)
                    .then((result) => {
                        dispatchContactInfo({type: "SET_ORIGINAL_STATE", payload: result});
                        setStateValues(result);
                    })
                    .catch((err) => {
                        console.log(err);
                        handleErrorMessage(err);
                        throw err;
                    });

                // check if create task api call needs to be made
                } else if (taskDescriptionLength > 0) {
                    await createTask()
                    .then(() => handleSnackbarOpen())
                    .catch((err) => {
                        console.log(err);
                        handleErrorMessage(err);
                        throw err;
                    });
                };
            };

            updateIsLoading(false);
            validateOtherInformationAndSpecialRequests();
        };
    };

    const addSectionToCompletedSections = (sectionName) => {

        let newCompletedSections = new Set(contactInfo.completedSections);
        newCompletedSections.add(sectionName)

        dispatchContactInfo({
            type: "SET_COMPLETED_SECTIONS",
            payload: newCompletedSections
        })
    };

    const removeSectionFromCompletedSections = (sectionName) => {

        let newCompletedSections = new Set(contactInfo.completedSections);
        newCompletedSections.delete(sectionName)

        dispatchContactInfo({
            type: "SET_COMPLETED_SECTIONS",
            payload: newCompletedSections
        })
    }

    const buildTabArray = (getFlightContent, getTrainContent) => {
        let arr = [];

        if (contactInfo.br_flightsflag) arr.push(getFlightContent());
        if (contactInfo.br_trainsflag) arr.push(getTrainContent());

        return arr;
    }

    const buildGearExtras = (gearItem) => {
        if (gearItem.name == "Helmet") {
            return (
                <FormControl
                    fullWidth={true}
                    disabled={contactInfo.b_updatesLocked}
                    error={!contactInfo.isSelectedHelmetValid}
                >
                    <CustomSelect
                        options={gearItem.options}
                        inputLabelId="inputLabelHelmet"
                        handleChange={(event) => dispatchContactInfo({
                            type: "SET_SELECTED_HELMET",
                            payload: event.target.value
                        })}
                        labelName="Helmet"
                        value={contactInfo.selectedHelmet}
                        selectId="selectHelmet"
                        selectLabelId="selectLabelHelmet"
                        error={!contactInfo.isSelectedHelmetValid}
                    />
                    {displayHelmetError()}
                </FormControl>
            )
        } else if (gearItem.name == "Bike Pedals") {
            return (
                <FormControl
                    fullWidth={true}
                    error={!contactInfo.isSelectedPedalValid}
                    disabled={contactInfo.b_updatesLocked}
                >
                    <CustomSelect
                        options={gearItem.options}
                        inputLabelId="inputLabelBikePedals"
                        handleChange={(event) => dispatchContactInfo({
                            type: "SET_SELECTED_PEDALS",
                            payload: event.target.value
                        })}
                        labelName="Bike Pedals"
                        value={contactInfo.selectedPedal}
                        selectId="selectBikePedals"
                        selectLabelId="selectLabelBikePedals"
                        error={!contactInfo.isSelectedPedalValid}
                    />
                    {displayPedalError()}
                </FormControl>
            )
        } else if (gearItem.name == "Gel seat cover") {
            return (
                <FormControl
                    fullWidth={true}
                    error={!contactInfo.isSelectedSeatCoverValid}
                    disabled={contactInfo.b_updatesLocked}
                >
                    <CustomSelect
                        options={gearItem.options}
                        inputLabelId="inputLabelSeatCover"
                        handleChange={(event) => dispatchContactInfo({
                            type: "SET_SELECTED_SEAT_COVER",
                            payload: event.target.value
                        })}
                        labelName="Seat Cover"
                        value={contactInfo.selectedSeatCover}
                        selectId="selectSeatCover"
                        selectLabelId="selectLabelSeatCover"
                        error={!contactInfo.isSelectedSeatCoverValid}
                    />
                    {displaySeatCoverError()}
                </FormControl>
            )
        } else if (gearItem.name == "Mirror") {
            return (
                <FormControl
                    fullWidth={true}
                    error={!contactInfo.isSelectedMirrorValid}
                    disabled={contactInfo.b_updatesLocked}
                >
                    <CustomSelect
                        options={gearItem.options}
                        inputLabelId="inputLabelMirror"
                        handleChange={(event) => dispatchContactInfo({
                            type: "SET_SELECTED_MIRROR",
                            payload: event.target.value
                        })}
                        labelName="Mirror"
                        value={contactInfo.selectedMirror}
                        selectId="selectMirror"
                        selectLabelId="selectLabelMirror"
                        error={!contactInfo.isSelectedMirrorValid}
                    />
                    {displayMirrorError()}
                </FormControl>
            )
        } else if (gearItem.name == "Golf Club Preference") {
            return (
                <FormControl
                    fullWidth={true}
                    error={!contactInfo.isSelectedGolfClubPreferenceValid}
                    disabled={contactInfo.b_updatesLocked}
                >
                    <CustomSelect
                        options={gearItem.options}
                        inputLabelId="inputLabelGolfClubPreference"
                        handleChange={(event) => dispatchContactInfo({
                            type: "SET_SELECTED_GOLF_CLUB_PREFERENCE",
                            payload: event.target.value
                        })}
                        labelName="Mirror"
                        value={contactInfo.selectedGolfClubPreference}
                        selectId="selectGolfClubPreference"
                        selectLabelId="selectLabelGolfClubPreference"
                        error={!contactInfo.isSelectedGolfClubPreferenceValid}
                    />
                    {displayGolfClubPreferenceError()}
                </FormControl>
            )
        }
    }

    const preUploadTravelDocs = async(callback) => {

        updateIsLoading(true)
        let passport = document.getElementById("passport");
        let visa = document.getElementById("visa");
        let documents = [];

        if (passport && passport.files.length > 0) {
            await handleFileSelect(passport.files[0])
            .then(base64 => {
                documents.push({
                    attachmentType: "PASSPORT",
                    attachmentBody: base64.split(",")[1],
                    attachmentDescription: "Passport",
                    attachmentFileName: passport.files[0].name,
                    attachmentMimeType: passport.files[0].type
                })
            })
        }

        if (visa && visa.files.length > 0) {
            await handleFileSelect(visa.files[0]).then(base64 => {
                documents.push({
                    attachmentType: "VISA",
                    attachmentBody: base64.split(",")[1],
                    attachmentDescription: "Visa",
                    attachmentFileName: visa.files[0].name,
                    attachmentMimeType: visa.files[0].type
                })
            });
        }

        if (documents.length > 0) {
            uploadTravelDocuments(documents, () => {
                updateIsLoading(false);

                // set state to reflect error message
                if (passport.files.length > 0) {
                    dispatchContactInfo({
                        type: "SET_IS_PASSPORT_PHOTO_UPLOADED",
                        payload: true
                    });
                };

                if (visa.files.length > 0) {
                    dispatchContactInfo({
                        type: "SET_IS_VISA_PHOTO_UPLOADED",
                        payload: true
                    });
                };

                return callback()
            })
        } else {
            updateIsLoading(false)
            return callback()
        }
    }

    // MISC
    const updateActiveStepInUrl = (activeStep) => {
        let url = window.location.toString();
        url = url.replace(/activeStep=[0-9]/, "activeStep=" + activeStep);
        window.location = url;
    };

    const toggleContactInfoSectionStatus = () => {

        if (areContactDetailsValid_v2()) {
            addSectionToCompletedSections("Contact Info");
            if (isFormComplete()) {
                displayFormCompletionMessage();
            };
        } else {
            if (contactInfo.completedSections.has("Contact Info")){
                removeSectionFromCompletedSections("Contact Info");
            };
        };

        incrementActiveStepAndCurrentlyExpanded();
    };

    const processProperties = (result) => {

        let clonedResults = JSON.parse(JSON.stringify(result));

        /* NOTE:
            Property's not from API and needs to be present in the original state
            since it's one of the properties that is being checked for dirty props.
        */
        if (result.gear) {
            for (let gear in result.gear) {
                let gearItem = result.gear[gear];

                if (gearItem.name == "Bike") clonedResults["selectedBike"] = gearItem.selectedValue
                if (gearItem.name == "Bike Pedals") clonedResults["selectedPedal"] = gearItem.selectedValue
                if (gearItem.name == "Gel seat cover") clonedResults["selectedSeatCover"] = gearItem.selectedValue
                if (gearItem.name == "Helmet") clonedResults["selectedHelmet"] = gearItem.selectedValue
                if (gearItem.name == "Mirror") clonedResults["selectedMirror"] = gearItem.selectedValue
            }
        }

        return clonedResults;
    }

    const openUrl = (url) => {
        window.open(url, "_blank")
    }

    const incrementActiveStepAndCurrentlyExpanded = () => {

        const nextStep = contactInfo.activeStep + 1;
        updateActiveStepInUrl(nextStep);

        // avoid race condition
        setTimeout(() => dispatchContactInfo({type: "SET_ACTIVE_STEP", payload: nextStep}), 0)
        setTimeout(() => dispatchContactInfo({type: "SET_CURRENTLY_EXPANDED", payload: nextStep}), 0)
        window.scrollTo(0, 0);
    }

    const getTime = (time) => {

        /*
        NOTE:
            Input can vary. Currently accounting for null as a first time value
            but when time is changed using the onChange event then time needs
            to be turned into a moment object.
            In that case, we would get a formatted time string.
            For example: "14:00"
        */
        if (time) {
            let momentObj = moment();
            let splitTime = time.split(/:/);
            return momentObj
            .hours(parseInt(splitTime[0]))
            .minutes(parseInt(splitTime[1]))
            .seconds(0);
        };

        return time;
    };

    const determineDocuSignUrl = () => {

        if (!contactInfo.b_signedWaiverReceived) {
            let docuSignUrlGeneraterUrl =
                process.env.REACT_APP_DOCUSIGN_ENDPOINT
                + "signerName="
                + encodeURIComponent(contactInfo.firstname + " " + contactInfo.lastname)
                + "&guestGUID="
                + encodeURIComponent(urlParams["guestId"])
                + "&token="
                + encodeURIComponent(localStorage.getItem("token"))
                + "&returnURLMyBR="
                + encodeURIComponent(
                    document.location.origin
                    + "/#/userform?guestId="
                    + urlParams["guestId"]
                    + "&tripDepartureId="
                    + urlParams["tripDepartureId"]
                    + "&tripName="
                    + urlParams["tripName"]
                    + "&startDate="
                    + urlParams["startDate"]
                    + "&endDate="
                    + urlParams["endDate"]
                    + "&activeStep="
                    + contactInfo.activeStep
                )

            // generate URL
            getDocuSignUrl(docuSignUrlGeneraterUrl, (url) => {
                window.open(url, "_self")
            })
        }
    }

    const checkForAddressMismatch = (callback) => {
        /*
        NOTE:
            If the values between shipping and home address are different
            than ask user if they want to make the change.
            If user decides to make their shipping address the same as home address
            then set the same values otherwise keep it as is.
        */

        if (contactInfo.sameAsHomeAddress && (
            contactInfo.address1_line1 != contactInfo.address2_line1
            || contactInfo.address1_line2 != contactInfo.address2_line2
            || contactInfo.address1_city != contactInfo.address2_city
            || contactInfo.address1_postalcode != contactInfo.address2_postalcode
            || contactInfo.address1_stateorprovince != contactInfo.address2_stateorprovince
            || contactInfo.address1_country != contactInfo.address2_country
            || contactInfo.address1_name != contactInfo.address2_name
        )) {
            updateAlertTitle("Address mismatch")
            updateAlertMessage("Your home address has been changed, would you like to set your shipping address as your home address?")
            updateAlertButtons([
                {
                    id: 0,
                    name: "Yes",
                    action: () => {
                        updateIsAlertOpen(false)
                        updateAlertTitle("")
                        updateAlertMessage("")
                        callback(true);
                    },
                    color: "primary",
                    variant: "contained",
                },
                {
                    id: 1,
                    name: "No",
                    action: () => {
                        updateIsAlertOpen(false)
                        updateAlertTitle("")
                        updateAlertMessage("")
                        callback(false);
                    },
                    variant: "contained",
                }
            ])
            updateIsAlertOpen(true)
        } else {
            callback();
        }
    }

    const setSpeedDialOpenStatus = (value) => {
        dispatchContactInfo({type: "SET_SPEED_DIAL_OPEN_STATUS", payload: value})
    }

    const buildActions = () => {
        let arr = [];

        if ((contactInfo.staff_email || "") != "") {
            arr.push({
                icon: <MailOutlineIcon />,
                name: 'E-mail ' + contactInfo.staff_fullname,
                callback: () => window.open("mailTo:" + contactInfo.staff_email)
            });
        };

        if ((contactInfo.staff_telephone || "") != "") {
            arr.push({
                icon: <PhoneIcon />,
                name: 'Call ' + contactInfo.staff_fullname,
                callback: () => window.open("tel:" + contactInfo.staff_telephone)
            });
        }

        return arr;
    }

    let addTimeToExpiryTime = () => {
        expiryTimer = moment().add(5, "minutes");
    };

    // MOUNTED HOOK
    useEffect(() => {

        getUserDetails()
        .then((result) => {

            window.scrollTo(0,0)

            let processedProperties = processProperties(result)
            let mergedResults = Object.assign(result, processedProperties)

            // Set original state
            dispatchContactInfo({type: "SET_ORIGINAL_STATE", payload: mergedResults});
            setStateValues(mergedResults);
            displayInvoiceNote(mergedResults);
        })
        .catch((err) => {
            console.log(err);
            if (!axios.isCancel(err)) {
                handleErrorMessage(err);
                throw err;
            }
        });

        return () => {
            source.cancel();
            updateIsLoading(false);
        };
    }, []);

    // setting timer once data is mounted
    useEffect(() => {
        addTimeToExpiryTime();
        window.addEventListener('scroll', addTimeToExpiryTime);

        // set interval to check the status of the timer
        let timerSetInterval = setInterval(() => {
            if (expiryTimer < moment()) {
                updateIsAlertOpen(false);
                props.history.push("/trips");
            };
        }, 10000);

        return () => {
            window.removeEventListener('scroll', addTimeToExpiryTime)
            clearInterval(timerSetInterval);
        };
    }, []);

    return (
        <div className={classes.root}
            onKeyDown={() => {addTimeToExpiryTime()}}
            onMouseOver={() => {addTimeToExpiryTime()}}
        >
            <br></br>
            <Typography variant="h4" component="h4">
                { urlParams["tripName"]}
            </Typography>
            <Typography variant="h5" component="h4">
                { urlParams["startDate"]} - { urlParams["endDate"]}
            </Typography>
            <br></br>

            <RouterLink to="/trips" className={classes.link}>
                <Button
                    variant="outlined"
                    size="small"
                    startIcon={<ArrowBackIcon />}
                >Back To Upcoming Trips</Button>
            </RouterLink>
            <br></br>
            <br></br>

            {displaySpeedDial()}

            <SnackBar
                toShowCloseButton={false}
                status={contactInfo.snackbarStatus}
                message={contactInfo.snackbarMessage}
                isOpen={contactInfo.snackbarIsOpen}
                handleClose={() => handleSnackbarClose()}
            />

            <Stepper
                nonLinear
                activeStep={contactInfo.activeStep}
                orientation="vertical"
            >
                {contactInfo.steps.map((label, index) => (
                    <Step
                        onClick={() => handleSectionActiveStatus(index)}
                        key={label}
                        expanded={contactInfo.currentlyExpanded == index ? true : false}
                    >
                        <StepButton
                            completed={contactInfo.completedSections.has(label)}
                            onClick={() => {
                                updateActiveStepInUrl(index);
                                dispatchContactInfo({
                                    type: "SET_ACTIVE_STEP",
                                    payload: index
                                });
                                window.scrollTo(0, 0);
                            }}
                        >{label}</StepButton>

                        /*
                        NOTE:
                            e.stopPropagation() is used to prevent any clicks
                            from bubbling up to the parent component and as a
                            result causing the section to collapse.
                        */
                        <StepContent onClick={(e) => e.stopPropagation()}>
                            <Container maxWidth="md">
                                {getStepContent(label)}

                                <div className={classes.actionsContainer}>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="flex-end"
                                    >
                                        <Button
                                            disabled={contactInfo.b_updatesLocked}
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            onClick={(event) => {handleNext(label, index)}}
                                            className={classes.button}>Save
                                        </Button>
                                    </Grid>
                                </div>
                            </Container>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
}

export default UserForm;
