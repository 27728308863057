import React, { useContext, useEffect, useReducer } from "react";
import SHA512 from "crypto-js/sha512";
import axios from "axios";
import { HEADERS } from "../config";
import { Link } from "react-router-dom";
import { ERROR_MESSAGE, ERROR_TITLE } from "../constants.js";

//MATERIAL UI
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Custom Components
import ContactInformation from "../presentational/ContactInformation";
import CustomSelect from "../presentational/CustomSelect";

import { makeStyles } from '@material-ui/core/styles';

// CONTEXT
import { AlertContext } from "../context/AlertContext";
import { LoadingWheelContext } from "../context/LoadingWheelContext";

// REDUCER
import { signUpReducer } from "../reducers/SignUpReducer.js";

const useStyles = makeStyles(theme =>({
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    link: {
        textDecoration: "none",
        marginRight: theme.spacing(1),
        '&:visited': {
            color: "inherit"
        },
    },
}))


function Signup (props) {

    let initialState = {
        email: "",
        password: "",
        confirmedPassword: "",
        securityQuestion: "",
        securityAnswer: "",
        agreedToPrivacyPolicy: false,
        agreedToTerms: false,

        emailError: "",
        passwordError: "",
        confirmedPasswordError: "",
        securityQuestionError: "",
        securityAnswerError: "",

        showEmailError: false,
        showPasswordError: false,
        showConfirmedPasswordError: false,
        showSecurityQuestionError: false,
        showSecurityAnswerError: false,

        securityQuestions: [
            {
                name: "--Select--",
                value: "select"
            },
            {
                name: "What town were you born in?",
                value: "What town were you born in?"
            },
            {
                name: "What is your mother's maiden name?",
                value: "What is your mother's maiden name?"
            },
            {
                name: "What is the name of your first pet?",
                value: "What is the name of your first pet?"
            },
            {
                name: "What place did you first visit on an airplane?",
                value: "What place did you first visit on an airplane?"
            },
            {
                name: "What was the make of your first car?",
                value: "What was the make of your first car?"
            },
            {
                name: "What elementary school did you attend?",
                value: "What elementary school did you attend?"
            },
            {
                name: "When you were young, what did you want to be when you grew up?",
                value: "When you were young, what did you want to be when you grew up?"
            },
            {
                name: "Who was your childhood hero?",
                value: "Who was your childhood hero?"
            },
        ]
    }

    const [signUpInfo, dispatchSignUpInfo] = useReducer(signUpReducer, initialState);

    let { updateIsLoading } = useContext(LoadingWheelContext);
    let {
        updateAlertTitle, updateIsAlertOpen, updateAlertMessage,
        updateAlertSuccess, updateAlertButtons, updateOnExitButtonClick
    } = useContext(AlertContext);

    let { history } = props;
    let classes = useStyles();


    let handleSubmit = () => {

        if (isUserInputValid()) {

            updateIsLoading(true);

            let closeFunc = () => history.push("/");
            var userData = JSON.stringify({
                userEmail: signUpInfo.email,
                userPassword: SHA512(signUpInfo.password).toString().toUpperCase(),
                securityQuestion: signUpInfo.securityQuestion,
                securityAnswer: signUpInfo.securityAnswer.trim(),
                agreedToTerms: signUpInfo.agreedToTerms,
                agreedToPrivacyPolicy: signUpInfo.agreedToPrivacyPolicy
            });

            axios.post(process.env.REACT_APP_API_ENDPOINT + "/v1/createAccount", userData, HEADERS)
            .then((result) => {
                if (result.status !== 200) {
                    updateIsLoading(false);
                    updateAlertMessage(result.response.data.error.message);
                    updateOnExitButtonClick(closeFunc);
                    updateIsAlertOpen(true);
                } else {

                    // when creating an account is successful
                    if (result.data.message) {
                        updateAlertTitle("Success")
                        updateAlertSuccess(true);
                        updateAlertMessage(result.data.message)
                    } else {
                        // when account already exists or not activated yet
                        updateAlertTitle("Success")
                        updateAlertMessage(result.data.error.message);
                    }
                    updateIsLoading(false);
                    updateIsAlertOpen(true);
                    updateAlertButtons([
                        {
                            id: 0,
                            name: "Ok",
                            action: () => {
                                updateIsAlertOpen(false)
                                closeFunc();
                            },
                            color: "primary",
                            variant: "contained",
                        }
                    ]);
                    updateOnExitButtonClick(closeFunc);
                }
            })
            .catch((err) => {
                updateIsLoading(false)
                updateAlertTitle(ERROR_TITLE)
                updateOnExitButtonClick(closeFunc);
                if (err.response.status >= 500) {
                    updateAlertMessage(ERROR_MESSAGE)
                } else {
                    updateAlertMessage(err.response.data.error.message)
                };
                updateIsAlertOpen(true)
            })
        }
    }

    let isUserInputValid = () => {
        var isValid = true;

        // Validate email
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(signUpInfo.email)) {
            dispatchSignUpInfo({type: "SET_EMAIL_ERROR", payload: "Invalid email address"})
            dispatchSignUpInfo({type: "SET_SHOW_EMAIL_ERROR", payload: true})
            isValid = false;
        } else {
            dispatchSignUpInfo({type: "SET_EMAIL_ERROR", payload: ""})
            dispatchSignUpInfo({type: "SET_SHOW_EMAIL_ERROR", payload: false})
        }

        // Validate password
        if (signUpInfo.password.length < 8) {
            dispatchSignUpInfo({type: "SET_PASSWORD_ERROR", payload: "Password must be at least 8 characters long"})
            dispatchSignUpInfo({type: "SET_SHOW_PASSWORD_ERROR", payload: true})
            isValid = false;
        } else {
            dispatchSignUpInfo({type: "SET_PASSWORD_ERROR", payload: ""})
            dispatchSignUpInfo({type: "SET_SHOW_PASSWORD_ERROR", payload: false})
        }

        // Validate confirm password
        if (signUpInfo.password.trim() !== "" && signUpInfo.confirmedPassword !== signUpInfo.password) {
            dispatchSignUpInfo({type: "SET_SHOW_CONFIRMED_PASSWORD_ERROR", payload: true})
            dispatchSignUpInfo({type: "SET_CONFIRMED_PASSWORD_ERROR", payload: "Confirm Password does not match password"})
            isValid = false;
        } else if ((signUpInfo.confirmedPassword || "") === "") {
            dispatchSignUpInfo({type: "SET_SHOW_CONFIRMED_PASSWORD_ERROR", payload: true})
            dispatchSignUpInfo({type: "SET_CONFIRMED_PASSWORD_ERROR", payload: "Confirm Password cannot be empty"})
            isValid = false;
        } else {
            dispatchSignUpInfo({type: "SET_SHOW_CONFIRMED_PASSWORD_ERROR", payload: false})
            dispatchSignUpInfo({type: "SET_CONFIRMED_PASSWORD_ERROR", payload: ""})
        }

        // Validate security question
        if ((signUpInfo.securityQuestion || "") === "") {
            dispatchSignUpInfo({type: "SET_SECURITY_QUESTION_ERROR", payload: "Security Question cannot be empty"})
            dispatchSignUpInfo({type: "SET_SHOW_SECURITY_QUESTION_ERROR", payload: true})
            isValid = false;
        } else {
            dispatchSignUpInfo({type: "SET_SECURITY_QUESTION_ERROR", payload: ""})
            dispatchSignUpInfo({type: "SET_SHOW_SECURITY_QUESTION_ERROR", payload: false})
        }

        // Validate security answer
        if ((signUpInfo.securityAnswer || "") === "") {
            dispatchSignUpInfo({type: "SET_SHOW_SECURITY_ANSWER_ERROR", payload: true})
            dispatchSignUpInfo({type: "SET_SECURITY_ANSWER", payload: "Security Answer cannot be empty"})
            isValid = false;
        } else {
            dispatchSignUpInfo({type: "SET_SECURITY_ANSWER", payload: ""})
            dispatchSignUpInfo({type: "SET_SHOW_SECURITY_ANSWER_ERROR", payload: false})
        }

        return isValid;
    };

    const openUrl = (url) => {
        window.open(url, "_blank")
    };

    useEffect(() => {
        if (localStorage.getItem("token")) history.push("/home");
    }, []);

    return (
        <div>
            <CssBaseline>
            <Card className={classes.card}>
                <CardContent>
                    <br></br>
                    <Typography variant="h5" component="h6">
                        MyB&R: Create Account
                    </Typography>
                    <br></br>
                    <Typography component="i">
                        Please fill out the details below to create your MyB&R account.
                    </Typography>
                    <br></br>

                    {/*FORM*/}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth={true}>
                                <TextField
                                    error={signUpInfo.showEmailError}
                                    id="email"
                                    label="Email"
                                    type="email"
                                    helperText={signUpInfo.emailError}
                                    className={classes.textField}
                                    margin="normal"
                                    onChange={(event) => dispatchSignUpInfo({
                                        type: "SET_EMAIL",
                                        payload: event.target.value
                                    })}
                                />
                            </FormControl>
                        </Grid>

                        {/* Password */}
                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl fullWidth={true}>
                                <TextField
                                    error={signUpInfo.showPasswordError}
                                    id="password"
                                    label="Password"
                                    helperText={signUpInfo.passwordError}
                                    className={classes.textField}
                                    margin="normal"
                                    type="password"
                                    name="password"
                                    onChange={(event) => dispatchSignUpInfo({
                                        type: "SET_PASSWORD",
                                        payload: event.target.value
                                    })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl fullWidth={true}>
                                <TextField
                                    error={signUpInfo.showConfirmedPasswordError}
                                    id="confirmedPassword"
                                    label="Confirm Password"
                                    helperText={signUpInfo.confirmedPasswordError}
                                    className={classes.textField}
                                    margin="normal"
                                    type="password"
                                    onChange={(event) => dispatchSignUpInfo({
                                        type: "SET_CONFIRMED_PASSWORD",
                                        payload: event.target.value
                                    })}
                                />
                            </FormControl>
                        </Grid>

                        {/* Security Question/Answer*/}
                        <Grid item xs={12} sm={6} md={6} style={{marginTop: "1rem"}}>
                            <FormControl fullWidth={true}>
                                <CustomSelect
                                    options={signUpInfo.securityQuestions}
                                    inputLabelId="inputLabelFlipperSize"
                                    handleChange={(event) => dispatchSignUpInfo({
                                        type: "SET_SECURITY_QUESTION",
                                        payload: event.target.value
                                    })}
                                    labelName="Security Question"
                                    value={signUpInfo.securityQuestion}
                                    selectId="selectSecurityQuestion"
                                    selectLabelId="selectLabelSecurityQuestion"
                                    error={signUpInfo.showSecurityQuestionError}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl fullWidth={true}>
                                <TextField
                                    error={signUpInfo.showSecurityAnswerError}
                                    id="securityAnswer"
                                    label="Security Answer"
                                    helperText={signUpInfo.securityAnswerError}
                                    className={classes.textField}
                                    margin="normal"
                                    onChange={(event) => dispatchSignUpInfo({
                                        type: "SET_SECURITY_ANSWER",
                                        payload: event.target.value
                                    })}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                labelPlacement="left"
                                control={
                                    <Checkbox
                                        onChange={(e) => dispatchSignUpInfo({type: "SET_AGREED_TO_PRIVACY_POLICY", payload: e.target.checked})}
                                        checked={signUpInfo.agreedToPrivacyPolicy}
                                    />
                                }
                                label={
                                    <a
                                        style={{textDecoration: "underline", cursor: "pointer"}}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            openUrl("https://www.butterfield.com/privacy-statement/")}
                                        }
                                    >I accept the Privacy Statement
                                    </a>
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                labelPlacement="left"
                                control={
                                    <Checkbox
                                        onChange={(e) => dispatchSignUpInfo({type: "SET_AGREED_TO_TERMS_AND_CONDITIONS", payload: e.target.checked})}
                                        checked={signUpInfo.agreedToTerms}
                                    />
                                }
                                label={
                                    <a
                                        style={{textDecoration: "underline", cursor: "pointer"}}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            openUrl("https://www.butterfield.com/terms-conditions/")
                                        }}
                                    >I accept the Terms and Conditions
                                    </a>
                                }
                            />
                        </Grid>
                    </Grid>
                </CardContent>

                <CardActions style={{padding: 10}}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="flex-end"
                    >
                        <Grid item>
                            <Link to="/" className={classes.link}>
                                <Button
                                    variant="outlined"
                                    color="primary">Back
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={(signUpInfo.agreedToTerms && signUpInfo.agreedToPrivacyPolicy) ? false : true}
                                variant="contained"
                                color="primary"
                                style={{marginRight: 10}}
                                onClick={handleSubmit}>Create Account
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
                <br></br>
            </Card>

            <br></br>
            <br></br>
            <br></br>
            <ContactInformation/>
            <br></br>

            </CssBaseline>
        </div>
    )
}

export default Signup;
