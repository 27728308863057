import React from "react";

// MATERIAL UI
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import rotatingEarth from "../assets/rotating-earth.gif";

function LoadingWheel(props) {

    return (
        <div>
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={props.open}
                fullWidth={true}
                maxWidth={"xs"}
            >
                <img style={{"overflow": "hidden", "maxHeight": "230px"}} src={rotatingEarth}/>
            </Dialog>
        </div>
    )
}



export default LoadingWheel;
