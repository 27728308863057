export function tripsReducer (state, action) {
    switch(action.type) {
        case "SET_UPCOMING_TRIPS":
            return {...state, upcomingTrips: action.payload}
        case "SET_PAST_TRIPS":
            return {...state, pastTrips: action.payload}
        case "SET_CURRENT_TRIPS":
            return {...state, currentTrips: action.payload}
        case "SET_AGREED_TO_PRIVACY_POLICY":
            return { ...state, agreedToPrivacyPolicy: action.payload}
    }
}
