import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

const useStyles = makeStyles(theme => ({
  root: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
    position: "fixed",
    right: theme.spacing(15),
    bottom: theme.spacing(10),
    transform: "translateX(-50%)",
    zIndex: 1,
  },
  speedDial: {
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));


export default function SpeedDialFloatingButton(props) {

  const classes = useStyles();

  const {isOpen, handleOpen, direction, actions, speedDialIcon, isHidden} = props;

  return (
    <div className={classes.root} id="speedDial">
        <SpeedDial
          ariaLabel="SpeedDial"
          className={classes.speedDial}
          hidden={isHidden}
          icon={speedDialIcon}
          onClose={() => handleOpen(false)}
          onOpen={() => handleOpen(true)}
          open={isOpen}
          direction={direction}
        >
          {actions.map(action => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => action.callback()}
            />
          ))}
        </SpeedDial>
    </div>
  );
}
